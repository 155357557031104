import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import Editor from "../../../components/Editor";

import Dropdown from "../../../components/Dropdown";


const BrandCategories = [
    "Apparel & Accessories",
    "Auto & Vehicles",
    "Beauty & Personal Care",
    "Business & Industrial",
    "Computers & Electronics",
    "Finance",
    "Food & Drink",
    "Health",
    "Home & Garden",
    "Real Estate",
    "Shopping",
    "Sports & Fitness",
    "Travel & Tourism",
    "Arts & Entertainment",
    "Books & Literature",
    "Education",
    "Family & Parenting",
    "Hobbies & Leisure",
    "Jobs & Careers",
    "Law & Government",
    "News",
    "Science",
    "Pets & Animals",
    "Religion & Spirituality",
    "Technology & Computing",
    "Online Communities",
    "Gambling",
    "Military & Defense",
    "Agriculture",
    "Aerospace",
    "Environment",
    "Astrology & Esoteric",
    "Philanthropy",
    "Social Issues",
    "Volunteering & Charity",
    "Weddings",
    "Adult Content",
    "Other"
]


const NameAndDescription = (props) => {

    const { className, LANGUAGE } = props;
    const { brandName, setBrandName, brandDescription, setBrandDescription } = props;
    const { brandCategory, setBrandCategory } = props;


    return (
        <Card
            className={cn(styles.card, className)}
            title="Create New Brand"
            classTitle="title-green"
            head={
                <Link
                    className={cn("button-stroke button-small", styles.button)}
                    to={`/${LANGUAGE}/brands`}
                >
                    <Icon name="arrow-left" size="24" />
                    <span>Back</span>
                </Link>
            }
        >
            <div className={styles.description}>

                <div className={styles.group + ` mb-1`}>
                    <TextInput
                        className={`${styles.field} mb-8`}
                        label="Brand name"
                        name="title"
                        type="text"
                        placeholder="Starbucks"
                        tooltip="Maximum 100 characters. No HTML or emoji allowed"
                        required
                        onChange={(e) => setBrandName(e.target.value)}
                        value={brandName}
                    />
                    <div className="w-full md:w-[calc(50%-12px)] mb-8" style={{ marginLeft: 6, marginRight: 6 }}>
                        <Dropdown
                            className={`w-full`}
                            label="Brand Category"
                            tooltip="Select the category for your brand from the dropdown"
                            value={brandCategory}
                            setValue={setBrandCategory}
                            options={BrandCategories}
                        />
                    </div>

                </div>
                <Editor
                    state={brandDescription}
                    onChange={setBrandDescription}
                    classEditor={styles.editor}
                    label="Brand Description"
                    tooltip="Tell us about your brand."
                    placeholder="International brand for coffee-shop franchise"
                />
            </div>
        </Card>
    );
};

export default NameAndDescription;
