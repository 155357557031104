const TRANSLATER = {

     lang: 'es',

     //Seo
     seo_title: 'Servicio y alquiler de artículos para bebés',
     seo_description: 'Alquiler de sillas de coche, cochecitos, cunas y muchos otros productos. Nuestros proveedores entregan donde y cuando quieras. Viaja ligero y seguro con tus hijos.',
     seo_city_title: "Alquila artículos para bebés en {{city}}, entrega y recogida",
     seo_city_description: "Alquile artículos para bebés para sus vacaciones en {{city}}. Reserve cochecitos, sillas de auto, cunas y muchos más artículos para bebés para sus vacaciones en {{city}}. Servicio de entrega y recogida.",
     seo_category_title: "Alquilar bebé {{categoría}} en {{ciudad}}, limpio y seguro",
     seo_category_description: "Alquila bebé {{category}} para tus vacaciones en {{city}}. {{category}} limpio, seguro y garantizado en {{city}}. Entrega {{category}} en el aeropuerto o en tu alojamiento en {{ ciudad}}.",
     seo_store_title: "{{store_name}} - artículos para niños en {{city}}",
     seo_store_description: "{{store_name}} - artículos para niños en {{city}}, productos limpios, seguros y garantizados",
     seo_product_description: "Alquiler {{category}} {{marca}} {{modello}} en {{city}} - bocads",

     //Header
     wd_login: 'Iniciar sesión',
     wd_signup: 'Registrarse',
     wd_logout: 'Cerrar sesión',
     wd_howitworks: 'Cómo funciona',
     wd_provider_area: 'Área de proveedores',
     wd_customer_area: 'Área de clientes',
     wd_list_gear: 'Lista tu equipo',
     wd_blog: 'Blog',
     wd_when: 'Cuando',
     wd_where: 'Dónde',
     wd_start: 'Empezar',
     wd_end: 'Fin',
     wd_checkin: 'Comprobando',
     wd_checkout: 'Pagar',
     wd_search: 'Buscar',
     wd_arriving: 'Llegando',
     wd_leaving: 'Saliendo',
     wd_from: 'de',
     wd_to: 'a',
     wd_and: 'y',
     wd_of: 'de',

     //Footer
     wd_learnmore: 'Más información',
     wd_aboutus: 'Acerca de nosotros',
     wd_trust: 'Confianza y Seguridad',
     wd_download: 'Descargar',
     wd_where_we_are: 'Dónde estamos',
     wd_other_cities: 'Otras ciudades',
     wd_support: 'Soporte',
     wd_help_center: 'Centro de ayuda',
     wd_provider_resources: 'Recursos del proveedor',
     wd_terms: 'Términos y Condiciones',
     wd_privacy: 'Política de Privacidad',
     wd_cancellation: 'Términos de cancelación',
     wd_socialmedia: 'Redes Sociales',
     wd_faq: 'Preguntas frecuentes',

     //Words
     wd_locations: 'Ubicaciones',
     wd_provider: 'Proveedor',
     wd_providers: 'Proveedores',
     wd_trips_taken: 'Viajes realizados',
     wd_cities_covered: 'Ciudades cubiertas',
     wd_countries: 'Países',
     wd_time: 'Tiempo',
     wd_click_here: 'Haga clic aquí',
     wd_gear_type: 'Tipo de engranaje',
     wd_child_age: ' Edad del niño ',
     wd_searching_providers: 'Buscando proveedores',
     wd_searching_products: 'Buscando productos',
     wd_all_cities: 'Todas las ciudades',
     wd_points_of_interest: 'Puntos de interés',
     wd_rental_dates: 'fechas de alquiler',
     wd_rentals: 'alquiler',
     wd_total_earned: 'total ganado',
     wd_total_paid: 'total pagado',
     wd_additional_info: 'Información adicional',
     wd_order_total: 'total del pedido',
     wd_provider_place: 'Lugar del proveedor',
     wd_airports: 'aeropuertos',
     wd_train_stations: 'estaciones de tren',
     wd_ports: 'puertos',
     wd_available_providers: 'proveedores disponibles',
     wd_other_providers: 'otros proveedores',

     //Buscar
     wd_destination: 'Destino',
     wd_rental_period: 'Período de alquiler',
     wd_pickup_address: 'Dirección de recogida',
     wd_dropoff_address: 'Dirección de Entrega',
     wd_address: 'Dirección',
     wd_address_street_landmark: 'Calle o punto de referencia',
     wd_address_landmark_airport: 'Calle, punto de referencia o aeropuerto',
     wd_address_city_postal: 'Calle, Ciudad, Código postal',
     wd_address_airport_station: 'Aeropuertos, Monumentos o Estaciones',
     wd_flexible: 'Soy flexible ',
     wd_pickup: 'recogida',
     wd_dropoff: 'Dejar',

     //Común
     wd_email: 'Correo electrónico',
     wd_password: 'Contraseña',
     wd_confirm_password: 'Confirmar contraseña',
     wd_full_name: 'Nombre completo',
     wd_first_name: 'Nombre de pila',
     wd_last_name: 'Apellido',
     wd_first_last: 'Nombre y apellido',
     wd_login_google: 'Iniciar sesión con Google',
     wd_signup_google: 'Registrarse con Google',
     wd_case_sensitive: 'Sensible a mayúsculas y minúsculas',
     wd_recover_password: 'Recuperación de contraseña',
     wd_contact_details: 'Detalles de contacto',
     wd_create_account: 'Crear cuenta',

     wd_brand: 'marca',
     wd_day: 'día',
     wd_days: 'días',
     wd_unit: 'unidad',
     wd_units: 'unidades',
     wd_price: 'precio',
     wd_location: 'ubicación',
     wd_category: 'categoría',
     wd_categories: 'categorías',
     wd_age: 'edad',
     wd_quantity: 'cantidad',
     wd_products: 'productos',
     wd_product: 'producto',
     wd_availability: 'disponibilidad',
     wd_more_articles: 'más artículos',

     wd_store_name: 'nombre de la tienda',
     wd_store_number: 'número de tienda',
     wd_store_currency: 'moneda de la tienda',
     wd_store_status: 'estado de la tienda',
     wd_store_about: 'Acerca de tu tienda',
     wd_store_placeholder: 'Genera confianza con tu cliente contándole un poco sobre ti',
     wd_store_availability: 'disponibilidad en tienda',
     wd_phone_number: 'Número de teléfono',
     wd_delivery_point: 'Punto de entrega',
     wd_product_name: 'Nombre del producto',
     wd_product_about: 'Descripción del producto',
     wd_daily_price: 'Precio diario',
     wd_weekly_price: 'Precio semanal',
     wd_monthly_price: 'Precio mensual',
     wd_add_product: 'Agregar producto',
     wd_all_categories: 'Todas las categorías',
     wd_new_product: 'Nuevo Producto',
     wd_edit_product: 'Editar Producto',
     wd_published: 'Publicado',
     wd_unpublished: 'Inédito',

     wd_all_orders: 'todos los pedidos',
     wd_pending_approval: 'pendiente de aprobación',
     wd_upcoming: 'próximo',
     wd_rental_ending: 'fin de alquiler',
     wd_currently_renting: 'actualmente alquilado',
     wd_rental_completed: 'alquiler completado',
     wd_canceled_rejected: 'cancelado y rechazado',

     wd_pending: 'pendiente',
     wd_accepted: 'aceptado',
     wd_rejected: 'rechazado',
     wd_canceled: 'cancelado',
     wd_delivering: 'entregando',
     wd_delivered: 'entregado',
     wd_completed: 'completado',
     wd_refunded: 'reembolsado',
     wd_received: 'recibido',

     wd_message_customer: 'Enviar mensaje al cliente',

     //Resultados
     wd_available_self: 'Auto-recogida y devolución disponible GRATIS',
     wd_available_pick_for: 'Recogida y devolución disponibles para',
     wd_available_pick_from: 'Recogida y devolución disponibles desde',

     //Ciudad
     wd_your_trip_to: 'Tu viaje a',
     wd_rental_period_in: 'Período de alquiler en',
     wd_available_providers_in: 'proveedores disponibles en',
     wd_available_baby_gear: 'equipo de bebé disponible',
     wd_choose_gear_from: 'Elige tu ropa de bebé de',
     wd_products_in: 'productos en',

     //Proveedor
     wd_response_rate: 'Tasa de respuesta',
     wd_response_within: 'Responde en 1 hora',
     wd_orders_accepted: 'Pedidos aceptados',
     wd_delivery_airport: 'Entrega en aeropuertos',
     wd_delivery_station: 'Entrega en estaciones',
     wd_delivery_port: 'Entrega en puertos',
     wd_delivery_zones: 'zonas de entrega',
     wd_top_clean_reviews: 'Principales reseñas limpias',
     wd_weekly_discounts: 'Descuentos semanales',


     //Producto
     wd_description: 'descripción',
     wd_read_less: 'leer menos',
     wd_read_more: 'leer más',
     wd_convenient_delivery: 'Entrega conveniente',
     wd_quality_product: 'Producto de calidad',
     wd_cleanliness_standards: 'Normas de limpieza',
     wd_premium_support: 'Soporte Premium',

     //Menú
     wd_myorders: 'mis ordenes',
     wd_orders: 'pedidos',
     wd_reviews: 'revisiones',
     wd_notifications: 'notificaciones',
     wd_wallet: 'billetera',
     wd_favorites: 'favoritos',
     wd_chat: 'chatear',
     wd_contact: 'contactar con soporte',
     wd_settings: 'configuraciones',

     wd_dashboard: 'tablero',
     wd_myproducts: 'mis productos',
     wd_earnings: 'ganancias',

     //Carro
     wd_your_cart: 'tu carrito',
     wd_cart_empty: 'Tu carrito está vacío',
     wd_subtotal: 'subtotal',
     wd_total: 'totales',
     wd_discount: 'descuento',
     wd_coupon_discount: 'descuento de cupón',
     wd_weekly_discount: 'descuento semanal',
     wd_monthly_discount: 'descuento mensual',
     wd_more_products_provider: 'Más productos del proveedor',
     wd_consumable: 'Consumible',

     //Verificar
     wd_your_order: 'Tu pedido',
     wd_order_details: 'Detalles del pedido',
     wd_customer_details: 'Datos del cliente',
     wd_delivery_return: 'Entrega y Devolución',
     wd_payment: 'Pago',
     wd_delivery_summary: 'Resumen de entrega',
     wd_delivery_pickup_provider: 'Entrega y recogida por proveedor',
     wd_self_pickup: 'Auto-recogida y devolución gratis',
     wd_self_pickup_tool: 'Ver lugar de recogida aproximado',
     wd_card_number: 'Número de tarjeta',
     wd_expiry_month: 'Mes de caducidad',
     wd_expiry_year: 'Año de caducidad',
     wd_security_code: 'Código de seguridad',

     add_payment_method: 'Agregar un método de pago',
     add_new_card: 'Agregar nueva tarjeta',
     add_another_card: 'Agregar otra tarjeta',

     order_success: {
          t: 'Su solicitud de pedido ha sido recibida con éxito.',
          s: 'Por favor revise su correo electrónico, nos pondremos en contacto en las próximas 48 horas sobre el estado de su pedido.',
          c: 'estará aceptando o rechazando su solicitud de pedidow.',
          a: 'Reserva Exitosa',
          b: 'Reservó con éxito su pedido y aquí está su confirmación',
     },

     //Panel
     wd_your_earnings: 'Tus ganancias',
     wd_orders_count: 'Tienes {{TOTAL}} pedidos nuevos',
     wd_product_rentals_count: 'Este producto tiene {{TOTAL}} alquileres',
     wd_all_time: 'Todo el tiempo',
     wd_today: 'Hoy',
     wd_yesterday: 'Ayer',
     wd_last_days: 'Últimos {{TOTAL}} días',
     wd_last_week: 'La semana pasada',
     wd_last_month: 'El mes pasado',
     wd_last_year: 'El año pasado',
     wd_quick_actions: 'Acciones rápidas',
     wd_new_messages: 'Mensajes nuevos',

     //Bandeja de entrada
     wd_choose_conversation: 'Elige una conversación',
     wd_no_conversations: 'Sin conversaciones',
     wd_type_here: 'Escriba aquí',

     //Ajustes
     wd_store_settings: 'configuración de la tienda',
     wd_profile: 'perfil',
     wd_delivery_pickup: 'entrega y recogida',
     wd_schedules: 'horarios',
     wd_payout: 'pago',

     //Botones
     btn_start_now: 'Empezar ahora',
     btn_more_info: 'Más información',
     btn_become_provider: 'Conviértete en proveedor',
     btn_book_now: 'Reservar ahora',
     btn_provider_area: 'Ir al área de proveedores',
     btn_apply_now: 'Aplicar ahora',
     btn_rent_gear: 'Alquiler de artículos para bebés',
     btn_submit: 'Enviar',
     btn_next: 'Siguiente',
     btn_back: 'Atrás',
     btn_skip: 'Saltar',
     btn_cancel: 'Cancelar',
     btn_delete: 'Eliminar',
     btn_upload: 'Subir',
     btn_refresh: 'Actualizar',
     btn_decline: 'Rechazar',
     btn_remove: 'Eliminar',
     btn_apply: 'Aplicar',
     btn_continue: 'Continuar',
     btn_select: 'Seleccionar',
     btn_save: 'Guardar',

     btn_add_to_cart: 'Añadir al carrito',
     btn_edit_profile: 'Editar perfil',
     btn_save_changes: 'Guardar cambios',
     btn_upload_picture: 'Subir imagen',
     btn_use_picture: 'Usar esta imagen',
     btn_add_delivery_area: 'Añadir zona de entrega',
     btn_add_new_product: 'Añadir nuevo producto',
     btn_publish_store: 'Publicar tienda',
     btn_save_product: 'Guardar producto',
     btn_delete_product: 'Eliminar producto',
     btn_clear_calendar: 'Borrar calendario',
     btn_contact_customer: 'Contactar con el cliente',
     btn_contact_provider: 'Contactar con el proveedor',
     btn_change_password: 'Cambiar la contraseña',

     btn_accepted_order: 'Aceptar pedido',
     btn_delivering_order: 'Entregando pedido',
     btn_delivered_order: 'Pedido entregado',
     btn_completed_order: 'Pedido completado',
     btn_leave_review: 'Dejar un comentario',
     btn_check_review: 'Revisar la reseña',

     btn_continue_shopping: 'Seguir comprando',
     btn_finish_booking: 'Finalizar reserva',
     btn_view_products: 'Ver {{TOTAL}} productos',
     btn_view_all: 'Ver todo',

     btn_show_map: 'Mostrar mapa',
     btn_hide_map: 'Ocultar mapa',

     btn_place_order: 'Mandar el pedido',
     btn_manage_order: 'Gestionar pedido',



     //Preguntas
     ask_which_city: '¿A qué ciudad vas?',
     ask_ready_book: '¿Listo para reservar?',

     ask_where_delivery: '¿Dónde debemos entregar el equipo de bebé?',
     ask_where_return: '¿Dónde deberíamos recoger el equipo de bebé?',
     ask_coupon: '¿Tienes un cupón?',

     ask_sure: '¿Está seguro?',
     ask_sure_accept: '¿Estás seguro de que quieres aceptar esta orden?',
     ask_sure_reject: '¿Estás seguro de que quieres rechazar esta orden?',
     ask_sure_cancel: '¿Está seguro de que desea cancelar este pedido?',

     ask_about_order: '¿Tienes alguna pregunta sobre esta orden?',
     ask_why_reject: '¿Por qué rechazas esta orden?',
     ask_why_cancel: '¿Por qué cancelas este pedido?',
     ask_help: 'Hola ! ¿Le puedo ayudar en algo?',


     //Respuestas
     yes_accept: 'Sí, acepto',
     yes_reject: 'Sí rechazar',
     yes_cancel: 'Sí cancelar',

     //Información sobre herramientas
     tool_login: 'Le crearemos una cuenta para que pueda gestionar sus pedidos y ponerse en contacto con el proveedor a su conveniencia. ¿Ya tienes una cuenta?',
     tool_signup: 'Inicie sesión en su cuenta para gestionar sus pedidos y ponerse en contacto con el proveedor a su conveniencia. ¿No tienes una cuenta?',

     tool_search_city: 'Buscar un ejemplo de ciudad: Londres',
     tool_enter_city: 'Ingrese el nombre de una ciudad',
     tool_your_email: 'Su dirección de correo electrónico',
     tool_your_name: 'Tu nombre',

     tool_select_rental_dates: 'Seleccione las fechas de alquiler para obtener el equipo de bebé',
     tool_select_delivery: 'Si no está seguro, use la ciudad por ahora',
     tool_select_checkin: 'Seleccione el día para obtener el equipo de bebé',
     tool_select_checkout: 'Selecciona el día para devolver la equipación de bebé',
     tool_select_age: 'Seleccione la edad de su hijo',
     tool_select_category: 'Seleccione la categoría de equipo que le gustaría alquilar',
     tool_select_availability: 'Seleccione las fechas en las que no estará disponible para el servicio de alquiler',
     tool_select_pickup: 'Seleccione el lugar de recogida',
     tool_select_dropoff: 'Seleccione la ubicación de entrega',
     tool_select_from_dropdown: 'Elija su preferencia de entrega de las opciones a continuación',

     tool_enter_email: 'Ingrese una dirección de correo electrónico válida',
     tool_enter_checkin: 'Por favor, introduzca una fecha de entrada válida',
     tool_enter_checkout: 'Ingrese una fecha de pago válida',
     tool_enter_pickup: 'Ingrese un lugar de recogida válido',
     tool_enter_dropoff: 'Ingrese un lugar de entrega válido',

     tool_store_address: 'Ingrese la dirección de su tienda ',
     tool_store_closed_dates: 'Tienda cerrada en las siguientes fechas',
     tool_delivery_locations: 'Lugares de entrega y recogida',

     tool_enter_store_address: "Los clientes pueden recoger el equipo por sí mismos en su dirección sin servicio de entrega.",
     tool_enter_delivery_locations: "Los aeropuertos y los puntos de referencia populares son los lugares más solicitados por los clientes.",

     tool_choose_gear_from: 'Elija su equipo de {{TOTAL}} proveedores disponibles',
     tool_choose_gear_other: 'Estos {{TOTAL}} proveedores no ofrecen entregas en su ubicación, pero entregan cerca o están disponibles para auto-recogida gratuita.',
     tool_add_delivery_area: 'Cuantas más ubicaciones añadas, más pedidos podrás recibir.',
     tool_product_availability: 'Estas son las fechas en las que se alquila este producto',

     tool_dnd_images: 'Sube las fotos de tus productos',
     
     tool_once_deleted: 'Una vez eliminado, no podrá recuperar esto',
     tool_once_proceed: 'Una vez eliminado, se eliminará su {{TYPE}}. ¿Quiere continuar?',

     tool_tell_reason_reject: 'Dígale al cliente una razón por la que no se acepta este pedido',
     tool_tell_reason_cancel: 'Díganos un motivo por el cual se cancelará este pedido',

     tool_where_delivery: 'Ingrese la dirección donde le gustaría recoger el equipo o seleccione una de las opciones de entrega disponibles.',
     tool_where_return: 'Ingrese la dirección donde le gustaría dejar el equipo o seleccione una de las opciones de devolución disponibles.',

     tool_insert_coupon: 'Inserte el código de cupón y haga clic en Aplicar',
     tool_free_cancellation: 'Cancelación gratuita hasta 48 horas.',

     tool_view_delivery_map: 'Puedes ver dónde {{store_name}} ofrece entrega directa en el mapa.',
     tool_free_self_pickup: 'Recójalo gratis en la ubicación del proveedor.',
     tool_interested_other: 'Otros accesorios para bebés que te pueden interesar.',
     tool_additional_info: 'Escribe más información sobre horarios y direcciones de entrega y recogida, número de vuelo, etc.',

     tool_payment_hold: 'No se le cobrará hasta que se acepte el pedido. Si no se acepta el pedido, se liberará la retención. Cuando se acepta su solicitud de pedido, las cancelaciones son totalmente reembolsables, excepto la tarifa de bocads, hasta 48 horas antes del primer día de alquiler.',
     tool_payment_finishing: 'Al finalizar el pedido aceptas',
     tool_payment_cancel: 'Cancelar reserva antes',
     tool_payment_refund: 'y recibirá un reembolso completo (excepto la tarifa de bocads).',
     tool_select_card_payment: 'Seleccione la tarjeta para el alquiler',


     //Alertas
     alert_recovery_success: 'Le hemos enviado un correo electrónico ✉️ con las instrucciones de recuperación',
     alert_store_updated: {
          t: 'Tienda actualizada',
          s: 'Tu tienda ha sido actualizada con éxito'
     },
     alert_availability: {
          reset_t: 'La disponibilidad se restablece',
          reset_s: 'La disponibilidad de su tienda se ha restablecido con éxito',
          save_t: 'La disponibilidad está guardada',
          save_s: 'La disponibilidad de su tienda se ha guardado correctamente'
     },
     alert_order: {
          accepted_t: 'Pedido aceptado',
          accepted_s: 'Su pedido ha sido aceptado con éxito',
          rejected_t: 'Pedido rechazado',
          rejected_s: 'Su pedido ha sido rechazado con éxito',
          canceled_t: 'Pedido cancelado',
          canceled_s: 'Su pedido ha sido cancelado con éxito'
     },
     alert_cart: {
          added_t: 'Carrito actualizado',
          added_s: 'Producto agregado al carrito con éxito',
          consumable_t: 'Añadir artículo de alquiler primero',
          consumable_s: 'Este producto solo se puede pedir con un artículo de alquiler. Agregue un equipo para bebés de este proveedor a su carrito antes de agregar artículos consumibles.',
          multiple_t: 'Múltiples proveedores',
          multiple_s: 'Hay artículos de un proveedor diferente en su carrito. ¿Le gustaría vaciar su carrito para agregar este artículo?',
          multiple_c: 'Añadir artículo',
     },
     alert_expired: {
          checkout_t: 'Pago caducado',
          checkout_s: 'Esta compra ha caducado. Añade nuevos productos a tu carrito.',
     },

     //Errrs
     error_invalid_email: 'Ha insertado una dirección de correo electrónico no válida. Inténtalo de nuevo',
     error_invalid_credentials: 'Credenciales no válidas. Email y contraseña incorrectas. Inténtalo de nuevo',
     error_invalid_fullname: 'Debes ingresar tu nombre completo con al menos 6 caracteres.',
     error_invalid_password: 'Debe introducir una contraseña de al menos 6 caracteres.',
     error_invalid_confirmation: 'La confirmación de la contraseña no coincide con la contraseña ingresada.',

     error_already_exists: 'La cuenta ya existe para este correo electrónico.',

     error_missing_password: 'No ingresaste tu contraseña',
     error_missing_fullname: 'No ingresaste tu nombre completo',
     error_missing_phone: 'No ingresaste tu número de teléfono',

     error_invalid_address: 'Debe ingresar una dirección válida con número de casa, calle y ciudad.',
     error_invalid_store_settings: 'Debe completar la lista de verificación para acceder a la configuración de la tienda',
     error_invalid_card: 'La tarjeta insertada no es válida. Inténtalo de nuevo',

     error_not_authorized: {
          t: 'No autorizado',
          s: 'No estás autorizado para visitar esta página'
     },

     //Cliente de landing
     cust_intro: {
          a: 'Alquiler de artículos para bebés',
          b: 'está a solo un clic de distancia',
          p: 'Encuentra todo lo que hará más fácil viajar con tus pequeños. ¡En cualquier momento, en cualquier lugar, con nuestros proveedores locales!'
     },
     cust_options: [
          {
               t: 'Donde y cuando necesites',
               i: '/assets/img/landing/time.svg'
          },
          {
               t: 'Muchas opciones de entrega, incluidos aeropuertos',
               i: '/assets/img/landing/delivery.svg'
          },
          {
               t: 'Productos seguros y limpios',
               i: '/assets/img/landing/clean.svg'
          },
          {
               t: 'Atención al cliente de primera',
               i: '/assets/img/landing/support.svg'
          }
     ],
     cust_how: {
          t: 'Cómo funciona',
          a: 'Elige el equipo de bebé que necesitas',
          b: 'Recoger o recibir el equipo de bebé del proveedor',
          c: 'Equipo de regreso al final de su viaje'
     },
     cust_reviews: {
          t: 'Más de 3.000 clientes satisfechos',
          o: [
               {
                    i: 'https://user-images.trustpilot.com/6242c03147517c0013ce5370/73x73.png',
                    n: 'Claudio Aruanno',
                    a: '¡Solo 1 palabra PERFECTO!',
                    b: 'Buscábamos una cuna para nuestro hijo y dimos con esta empresa. Nos ayudaron mucho y el contacto es muy bueno al igual que el servicio! No habíamos reservado un colchón y tenían uno extra para nosotros. Súper agradable y dulce. Gracias por esto. ¡Mi hijo durmió maravillosamente, porque la calidad fue excelente!',
                    c: '¡Somos de los Países Bajos y definitivamente lo recomendamos para la próxima vez!'
               },
               {
                    i: '/assets/img/avatar.svg',
                    n: 'Cynthia González',
                    a: 'Alquiler de cochecitos',
                    b: 'Tuve una excelente experiencia desde el comienzo de mi reserva hasta el final. Se comunicaron conmigo unos días antes del viaje para organizar la reunión, el día en que nos conocimos ella llegó a tiempo y la comunicación fue rápida y fácil. La carriola estaba en excelentes condiciones. Al devolver el cochecito, fueron muy flexibles en el horario y lugar para recogerlo. Estoy muy contento de haber elegido esta empresa, definitivamente la recomendaré.',
                    c: ''
               },
               {
                    i: '/assets/img/avatar.svg',
                    n: 'Satu Malmi',
                    a: '',
                    b: 'Muy buena calidad con los bienes alquilados (cochecito y un asiento de bebé para coche). La entrega y la devolución también se realizaron sin problemas y de manera flexible. Recomendado para otras familias que quieren asegurarse de que sus bebés viajen seguros y no quieren volar sus propias cosas al extranjero 🙂',
                    c: ''
               }
          ]
     },
     cust_cities: 'Ciudades principales disponibles',
     cust_clean: {
          a: 'limpio',
          b: '& seguro',
          p: 'La limpieza y la seguridad han sido nuestras principales prioridades desde el principio. Cuidamos hasta el más mínimo detalle para que tengas una experiencia de alquiler perfecta. Los productos que no cumplen con nuestros estándares de higiene y seguridad se eliminan de nuestro sitio y no se les permite volver a publicarlos.'
     },
     cust_travel: {
          a: 'Viaje sin',
          b: 'bolsas pesadas',
          p: 'Tenga unas vacaciones sin estrés, en las que disfrutará de su viaje y creará recuerdos con su familia sin tener que cargar con muchas cosas. Puedes encontrar la equipación para bebé que necesitas donde y cuando quieras. Con bocads, tu asistente de viaje favorito, ya no tendrás que preocuparte por tus maletas pesadas.'
     },
     cust_parents: {
          a: 'Compartir de ',
          b: 'padres a padres',
          p: 'La mayoría de nuestros proveedores son padres como usted, y los puntos que le importan a su pequeño también son importantes para nuestros proveedores. Por eso sabemos que encontrará todo lo que necesita para tener una experiencia de viaje sin estrés con su pequeño. ¡Nos complace encontrar soluciones para usted con nuestra amplia gama de productos, a partir de precios de alquiler asequibles!'
     },
     cust_app: {
          a: 'Todo en un solo lugar',
          b: 'con la aplicación bocads.',
          o: [
               'Consigue todo lo que necesitas en un solo lugar.',
               'Manténgase en contacto con nuestros proveedores.',
               'Gestiona tu orden de alquiler.',
               'Conéctese con nuestro equipo de soporte rápidamente.',
               'Aproveche las promociones exclusivas solo para la aplicación.'
          ]
     },
     cust_faq: [
          {
               t: '¿Cómo funciona el servicio de alquiler de bocads?',
               p: 'Después de seleccionar el equipo de bebé para alquilar y enviar su pedido, se notifica al Proveedor. Pueden aceptar o rechazar su pedido de alquiler. Si es aceptado, recibirá una notificación y se le cobrará. El Proveedor coordinará la entrega o recogida con usted. Una vez que se devuelve el artículo, mediante recogida o entrega, su reserva está completa.'
          },
          {
               t: '¿Cuándo me acusan?',
               p: 'Se realizará el cargo en su tarjeta de crédito una vez que el Proveedor apruebe la reserva. Cuando envía su pedido de alquiler, se coloca una retención en su cuenta, pero se libera automáticamente si se rechaza.'
          },
          {
               t: '¿Puedo cancelar y obtener un reembolso?',
               p: 'Puede cancelar su pedido en cualquier momento hasta 48 horas antes de su período de reserva para obtener un reembolso completo menos las tarifas de servicio. Después de esto, ya no es elegible para un reembolso.'
          },
          {
               t: '¿Puedo modificar mi orden de alquiler?',
               p: 'Sí, su pedido puede modificarse en cualquier momento y necesitará la aprobación de su proveedor para aceptar los cambios.'
          },
          {
               t: '¿Está desinfectado el equipo del bebé?',
               p: 'Recomendamos que el equipo de bebé se limpie y desinfecte inmediatamente después del final de la reserva. Los artículos para bebés deben limpiarse con productos seguros que eliminen las bacterias y los virus.'
          },

     ],

     //Proveedor de destino
     pro_intro: {
          a: 'Conviértase en un proveedor de bocads',
          b: 'Lanza tu propia tienda de alquiler de artículos para bebés'
     },
     pro_start: {
          a: 'Comienza a alquilar en minutos:',
          b: 'Nombre de la tienda',
          c: 'Ubicación y entrega',
          d: 'Productos'
     },
     pro_how: {
          t: '¿Cómo empiezo?',
          p: 'Convertirse en un proveedor de bocads y lanzar su tienda de alquiler de artículos para bebés es fácil. ¡Solo 3 pasos para comenzar! ¡Crea tu tienda y añade tus primeros productos para empezar a recibir pedidos! Te ayudaremos en el camino.'
     },
     pro_earning: {
          t: 'Gana hasta 1.500€/mes',
          p: '¿Está buscando una oportunidad para aumentar sus ingresos mensuales? ¿Tiene un alijo de artículos para bebés que no está usando? ¡Ahora puedes ganar hasta 1500 € al mes alquilando tu ropa de bebé a familias viajeras! Esta es una excelente manera de ganar dinero extra mientras ayuda a las familias a viajar con niños pequeños. Ya sea un cochecito, un asiento para el automóvil o una cuna, siempre se necesita equipo para bebés cuando las familias están fuera de casa.',
     },
     pro_reviews: {
          i: '/assets/img/avatar.svg',
          n: 'Vincenzo',
          a: 'Proveedor bocads',
          b: 'Soy Vincenzo, padre de dos hermosas niñas, soy un apasionado de los viajes y aún recuerdo los proyectos y programas con mi esposa cuando el primer hijo estaba por llegar, respecto a la necesidad de cambiar de auto para tener más espacio para guardar el equipaje. de la familia en crecimiento! ¡Cuando cumplimos 4 casi entramos en crisis! Me casé con el proyecto bocads, creyendo que es una gran manera de no privarte del placer de un viaje largo, sin plantearte el problema de llevar todo ese equipo.'
     },
     pro_options: [
          {
               t: 'Establece tu propio horario',
               i: '/assets/img/landing/schedule.svg'
          },
          {
               t: 'Empieza a ganar ahora',
               i: '/assets/img/landing/earn.svg'
          },
          {
               t: 'Conoce gente nueva',
               i: '/assets/img/landing/community.svg'
          },
          {
               t: 'Apoyo de bocads',
               i: '/assets/img/premium-support.svg'
          }
     ],
     pro_faq: [
          {
               t: '¿Qué significa ser un proveedor de bocads?',
               p: 'Desde cochecitos y sillas de coche hasta parques y tronas, puede proporcionar a las familias todo lo que necesitan para que su viaje sea más cómodo y agradable. Al eliminar la necesidad de que las familias traigan su propio equipo, también puede ayudarlos a ahorrar dinero en las tarifas de equipaje y reducir el estrés de viajar con un bebé. Con su servicio confiable y conveniente, las familias pueden concentrarse en crear recuerdos durante sus viajes sin preocuparse por la logística del equipo para bebés.'
          },
          {
               t: '¿Hay que pagar para empezar en bocads?',
               p: 'Comenzar tu aventura como Proveedor de bocads es completamente gratis. Una vez que reciba un pedido, deduciremos una pequeña tarifa del total, que se utilizará para administrar Babobo y encontrar clientes para brindarle soporte.'
          },
          {
               t: '¿Necesito comprar equipo para empezar?',
               p: '¡Para comenzar, use su equipo de bebé existente para comenzar como proveedor en bocads! De hecho, te recomendamos que si quieres conseguir más artículos de bebé para alquilar que los compres de segunda mano. Nuestro único requisito para su equipo de bebé es que esté en buenas condiciones y limpio.'
          },
          {
               t: '¿Dónde entrego el equipo de bebé?',
               p: 'La mayoría de nuestros proveedores entregan artículos para bebés en sus respectivas ciudades. Puede elegir lugares de interés cercanos y centros de transporte como estaciones de tren y aeropuertos. ¡También puede dar a los clientes la opción de recoger el equipo de bebé directamente de usted!'
          },
          {
               t: '¿Cuánto equipo de bebé necesito para empezar?',
               p: 'Solo necesitas un artículo para comenzar como proveedor de bocads. Recomendamos comenzar con un cochecito, una cuna o un asiento para el automóvil, ya que son nuestros artículos de alquiler más populares. Una vez que su negocio despegue, también puede considerar agregar otros artículos, como tronas, parques y portabebés, a su inventario. Al ofrecer una gama más amplia de productos, puede satisfacer las necesidades de una base de clientes más grande y aumentar sus posibilidades de atraer pedidos más grandes.'
          },
          {
               t: '¿Cuánto puedo ganar como proveedor de bocads?',
               p: 'Puedes ganar hasta 1500 € al mes como proveedor de bocads. La cantidad que gane dependerá de dónde se encuentre y esté dispuesto a entregar, así como también de cuántos artículos tenga para alquilar. Los que más ganan siempre tienen carriolas, cunas y asientos de automóvil para alquilar.'
          },
          {
               t: '¿Cómo empiezo?',
               p: 'Para comenzar, simplemente complete nuestra solicitud de 3 pasos. Esto incluye configurar su tienda en línea y enumerar sus primeros productos. Una vez completado, puede publicar su tienda y comenzar a aceptar pedidos. ¡Es tan fácil como parece!'
          }
     ],

     //Iniciar sesión/Registrarse/Recuperación
     auth_login: {
          a: 'Iniciar sesión',
          b: '¿No tienes una cuenta? ',
          c: '¿Olvidaste tu contraseña?'
     },
     auth_signup: {
          a: 'Registrarse',
          b: '¿Ya tienes una cuenta? ',
          c: {
               a: 'Al hacer clic en suscribirse, acepta nuestro',
               b: 'Términos y Condiciones',
               c: 'y el nuestro',
               d: 'Política de Privacidad',
          }
     },
     auth_recovery: {
          a: '¿Olvidaste tu contraseña?',
          b: 'Ingrese su correo electrónico a continuación y le enviaremos instrucciones para restablecer su contraseña.',
     },

     //Acerca de
     about_page: {
          a: "bocads es un servicio de alquiler de artículos para bebés que ofrece una variedad de artículos para bebés a través de locales para familias que viajan en más de 250 ciudades.",
          b: 'bocads fue iniciado en 2018 por Duygu Sefa, fundador y proveedor de bocads. Como madre de dos hijos, Duygu pensó que debía haber una manera más fácil que llevar todo el equipo necesario para bebés mientras viajaba con niños.',
          c: 'Después de numerosos viajes con mi pequeña, me di cuenta de que viajar con los más pequeños puede volverse muy estresante, así que me pregunté ¿por qué no ayudar a las familias a viajar libres de todo equipo?',
          d: 'Con una comprensión completa de las dificultades de las familias que viajan, en 2019 se lanzó el mercado de alquiler de artículos para bebés de bocads. Desde el primer día, las familias han seguido confiando y haciendo de bocads un indispensable durante sus viajes.',
          e: 'Hoy, bocads es una gran familia no solo de familias que viajan en busca de artículos para bebés, sino también de proveedores de todo el mundo que alquilan artículos para bebés. Los proveedores de bocads son locales que quieren hacer que los viajes de las familias sean una aventura aún más extraordinaria y obtener ingresos mensuales adicionales mientras lo hacen.'
     },

     //Cómo funciona
     how_page: {
          t: 'Viajar con pequeños es más fácil que nunca.',
          a: {
               t: 'Elige el equipo de bebé que necesitas',
               a: {
                    t: 'Elige tu ubicación',
                    s: 'Elige tu destino para recoger tu pedido seleccionando la ciudad que visitarás.'
               },
               b: {
                    t: 'Selecciona tus fechas',
                    s: 'Agregue las fechas de su viaje en función de su viaje. No dude en ponerse en contacto con el proveedor si tiene preguntas sobre la disponibilidad.'
               },
               c: {
                    t: 'Elige el equipo de bebé',
                    s: 'Puedes encontrar sillas de coche de primeras marcas, cualquier tipo de cochecito, cunas portátiles.'
               },
          },
          b: {
               t: 'Recibe el equipo de bebé del Proveedor donde lo necesites.',
               s: '¡Tenemos muchas opciones de entrega!',
               a: {
                    t: 'Directamente a los aeropuertos.'
               },
               b: {
                    t: 'Directamente a hoteles y alquileres en el centro de las ciudades.'
               },
               c: {
                    t: 'Reunión en puntos de interés.',
                    s: 'como estaciones centrales de trenes y autobuses o puntos de referencia en la ciudad. O bien, recoja el equipo de forma gratuita en el lugar del Proveedor.'
               },
               d: {
                    a: 'Es gratis para recoger desde el lugar del proveedor.',
                    b: 'Para otros lugares de entrega, los proveedores suelen cobrar tarifas de entrega. Verá los gastos de envío cuando realice el pago.',
                    c: 'Una vez que reserve su alquiler, el Proveedor aceptará o rechazará el pedido dentro de las 24 horas. Recibirás un correo electrónico con la confirmación. Se colocará una retención en su cuenta hasta que se acepte el pedido, y luego se le cobrará.',
                    d: 'bocads solo cobra cuando su pedido es aceptado por el Proveedor.'
               }

          },
          c: {
               t: 'Devuelva el equipo de bebé al final de su viaje',
               s: 'Dependiendo de la opción elegida, reúnase con el proveedor de bocads en el lugar de recogida a tiempo o espere a que recoja el equipo para bebés. Asegúrese de devolver los productos en las mismas condiciones. ¡Y eso es!'
          },
          d: {
               a: 'Donde quiera que vayas',
               b: '¡El equipo de bebé te encontrará allí!',
               c: 'La salud y la seguridad son nuestra prioridad',
               d: 'Opciones flexibles de recogida y devolución',
               e: 'Soporte siempre que lo necesite'
          }

     },

     //Confianza y Seguridad
     trust_page: {
          a: {
               t: 'Confianza y seguridad de bocads',
               s: 'La confianza y la seguridad han sido una prioridad desde el principio hasta ahora. Prestamos atención a los detalles más pequeños para que tenga una experiencia de alquiler perfecta.'
          },
          b: {
               t: 'Confianza:',
               a: 'Tenemos más de 2.000 clientes satisfechos.',
               b: '¡No temas un cambio de planes de viaje, puedes cancelar tu pedido con un reembolso completo hasta 48 horas antes del día de tu alquiler!',
               c: 'Si no está satisfecho con el equipo de bebé que alquila, lo reemplazaremos con una alternativa dentro de las 24 horas. Si no podemos encontrar una opción alternativa, ¡le devolveremos su dinero!'
          },
          c: {
               t: 'Seguridad e Higiene:',
               s: 'Nuestros proveedores deben cumplir con nuestros estándares de limpieza de seguridad e higiene. Los proveedores limpian a fondo el equipo de alquiler para bebés después de cada alquiler. Todos los proveedores de bocads y sus productos pasan por un proceso de aprobación. Y sus comentarios son muy valiosos para nosotros. Tomamos sus revisiones en consideración y tomamos las medidas necesarias, cuando sea necesario.'
          },
          d: {
               t: 'Protección de reserva:',
               a: 'Su información personal no será vendida o distribuida de ninguna manera. Su privacidad es importante para nosotros. Le solicitamos que proporcione su nombre completo, dirección de correo electrónico, número de teléfono e información de pago a través de nuestro sitio.',
               b: "Hacemos todo lo posible para garantizar que tenga una experiencia de reserva impecable, pero si un proveedor de bocads necesita cancelar en el último minuto, haremos todo lo posible para ayudarlo a encontrar un reemplazo."
          },
          e: {
               t: '¡Estoy deseando conocerte!',
               s: 'Si necesita algo durante su experiencia de alquiler, no dude en ponerse en contacto con nuestro equipo de expertos. Puede comunicarse con nosotros en cualquier momento a través del chat en vivo Revisión del nuevo sitio web 11 en nuestro sitio web o aplicación, o envíe un correo electrónico a info@bocads.com y estaremos encantados de ayudarlo.'
          }

     },

     //Descargar
     download_page: {
          t: 'Descargar la aplicación',
          s: 'Gestiona tu alquiler fácilmente con la aplicación bocads. ¡Descárguelo y comience a usarlo ahora!',
          a: 'Todo en un solo lugar con la aplicación bocads.',
          b: 'Desarrollado con sus necesidades en mente.'
     },

     //Ubicaciones
     locations_page: {
          t: 'Alquiler de artículos para bebés en',
          s: 'Alquiler de sillas de coche, cochecitos, cunas y muchos otros productos. Nuestros proveedores entregan donde y cuando quieras. Viaja ligero y seguro con tus hijos.',
     },

     //Flujo de clientes
     cust_flow: {
          a: {
               t: '¿Cuándo le gustaría alquilar?',
               s: 'Seleccione las fechas de alquiler (recogida y devolución) en'
          },
          b: {
               t: 'Recoger y dejar',
               s: '¿Dónde quieres que te entreguen? Recoger y dejar en'
          },
          c: {
               t: '¿Qué equipo necesitas?',
               s: 'Seleccione las categorías de equipo y la edad de su hijo'
          }
     },

     //Flujo de proveedor
     pro_flow: {
          a: {
               t: 'Información básica sobre tu tienda.',
               s: 'Su número de contacto solo se compartirá con el cliente cuando acepte su solicitud de pedido.'
          },
          b: {
               t: 'Ubicación y entrega.',
               s: 'Esto ayudará a los clientes a seleccionar su tienda mejor para su ubicación'
          },
          c: {
               t: '¡Impresionante! Su primer producto está listo.',
               s: 'Agregue más productos aquí para configurar rápidamente la tienda.'
          },
     },
     pro_check: {
          t: 'Gracias por crear su perfil de proveedor. Siga estos pasos a continuación para comenzar a alquilar.',
          a: 'Sube tu foto de perfil',
          b: 'Establece tus tarifas de entrega y recogida',
          c: 'Establece la dirección de tu tienda ',
          d: 'Subir productos',
          e: 'Añadir información de contacto',
          z: 'Tu perfil está siendo verificado. Le enviaremos un correo electrónico dentro de las 24 horas.'
     },

     wd_list_months: [
          'enero',
          'febrero',
          'marzo',
          'abril',
          'mayo',
          'junio',
          'julio',
          'agosto',
          'septiembre',
          'Octubre',
          'noviembre',
          'Diciembre'
     ],

     wd_how_guide: {
          a: 'Reserve su alquiler de artículos para bebés',
          b: 'Recibe artículos de bebé de tu proveedor donde quieras',
          c: 'Disfruta tu viaje con tu pequeño',
          d: 'Devuelva el equipo del bebé al final de su viaje',
          cta: 'Entendido'
     },

     wd_list_schedules: {
          t: 'Horas de recogida y entrega',
          s: 'Consulta tu disponibilidad semanal para servicio de entrega y retiro a domicilio.',
          home: 'Recogida en domicilio',
          service: 'Servicio de entrega',
          a: 'lunes',
          b: 'martes',
          c: 'miércoles',
          d: 'jueves',
          e: 'viernes',
          f: 'sábado',
          g: 'domingo'
     },

     wd_list_payout: {
          t: 'Opciones de pago',
          s: 'Agregue su información de pago para recibir las ganancias de sus pedidos.',
          a: 'Nombre del banco',
          b: 'Número de seguro social',
          c: 'País',
          d: 'Tipo',
          e: 'Transferencia bancaria',
          f: 'Número de cuenta',
          g: 'Número de ruta',
          connected: 'Conectado',
          disconnect: 'Desconectar'
     },

     wd_list_earnings: {
          earn_baby: '¿Gana y gasta BabyCoins?',
          ref_code: 'Tu código de referencia',
          recent: 'Transacciones recientes',
          ask_this: '¿Qué es esto?',
          learn_how: 'Aprender cómo'
     },

     wd_analytics: 'Análisis',
     wd_pending_rate: 'Tasa pendiente',
     wd_acceptance_rate: 'Tasa de aceptación',
     wd_rejection_rate: 'Tasa de rechazo',
     wd_score: 'Puntuación',
     wd_performance: 'Rendimiento',
     wd_total_customers: 'Total de Clientes',
     wd_total_products: 'Productos Totales',
     wd_total_days: 'Total de días',
     wd_aov: 'Valor medio del pedido',
     wd_total_earnings: 'Ganancias totales',
     wd_total_to_earn: 'Total a ganar',
     wd_total_lost: 'Totalmente perdido',

     wd_list_orders: [
          'Para este pedido, se le cobrará {{TOTAL}} si cancela.',
          'Para este pedido, se le cobrará {{TOTAL}} si cancela.',
          'No se le cobrará este pedido si lo cancela.',
          'Si su pedido ha sido aceptado por el proveedor:',
          'Los pedidos que se cancelan antes de las últimas 48 horas del primer día de alquiler se reembolsan excepto la tarifa de bocads',
          'Los pedidos que se cancelan dentro de las últimas 48 horas antes del primer día de alquiler no son reembolsables',
          'Las cancelaciones no pueden ser reembolsadas si el primer día de alquiler es dentro de las 48 horas.'
     ]


};

export default TRANSLATER;
