const TRANSLATER = {

  lang: 'fr',

  //Seo
  seo_title: 'Location et service de matériel pour bébé',
  seo_description: 'Louez des sièges auto, des poussettes, des lits bébé et bien d\'autres produits. Nos prestataires vous livrent où et quand vous le souhaitez. Voyagez léger et en toute sécurité avec vos enfants.',
  seo_city_title: "Louez du matériel pour bébé à {{ville}}, livraison et ramassage",
  seo_city_description: "Louez du matériel pour bébé pour vos vacances à {{ville}}. Réservez des poussettes, des sièges auto, des berceaux et bien d'autres équipements pour bébé pour vos vacances à {{ville}}. Service de livraison et de ramassage.",
  seo_category_title: "Louez bébé {{category}} à {{city}}, propre et sûr",
  seo_category_description: "Louez bébé {{category}} pour vos vacances à {{city}}. {{category}} propre, sûr et garanti à {{city}}. Livraison {{category}} à l'aéroport ou à votre logement à {{ ville}}.",
  seo_store_title: "{{store_name}} - articles pour enfants à {{city}}",
  seo_store_description: "{{store_name}} - articles pour enfants à {{city}}, produits propres, sûrs et garantis",
  seo_product_description: "Louez {{category}} {{marca}} {{modello}} à {{city}} - bocads",

  //Entête
  wd_login: 'Connexion',
  wd_signup: 'S\'inscrire',
  wd_logout: 'Déconnecter',
  wd_howitworks: 'Comment ça marche',
  wd_provider_area: 'Espace fournisseur',
  wd_customer_area: 'Espace client',
  wd_list_gear: 'Listez votre équipement',
  wd_blog: 'Blog',
  wd_when: 'Quand',
  wd_where: 'Où',
  wd_start: 'Démarrer',
  wd_end: 'Fin',
  wd_checkin: 'Enregistrement',
  wd_checkout: 'Commander',
  wd_search: 'Rechercher',
  wd_arriving: 'Arriver',
  wd_leaving: 'Partir',
  wd_from: 'de',
  wd_to: 'à',
  wd_and: 'et',
  wd_of: 'de',

  //Bas de page
  wd_learnmore: 'En savoir plus',
  wd_aboutus: 'À propos de nous',
  wd_trust: "Confiance & Sécurité",
  wd_download: 'Télécharger',
  wd_where_we_are: 'Où sommes-nous',
  wd_other_cities: 'Autres villes',
  wd_support: 'Soutenir',
  wd_help_center: 'Centre d\'aide',
  wd_provider_resources: 'Ressources du fournisseur',
  wd_terms: 'Conditions générales',
  wd_privacy: 'Politique de confidentialité',
  wd_cancellation: 'Conditions d\'annulation',
  wd_socialmedia: "Médias sociaux",
  wd_faq: 'Foire aux questions',

  //Mots
  wd_locations: 'Lieux',
  wd_provider: 'Fournisseur',
  wd_providers: 'Fournisseurs',
  wd_trips_taken: 'Voyages effectués',
  wd_cities_covered: 'Villes couvertes',
  wd_countries: 'Pays',
  wd_time: 'Temps',
  wd_click_here: 'Cliquez ici',
  wd_gear_type: 'Type d\'engrenage',
  wd_child_age: ' Âge de l\' enfant ',
  wd_searching_providers: 'Recherche de prestataires',
  wd_searching_products: 'Chercher des produits',
  wd_all_cities: 'Toutes les villes',
  wd_points_of_interest: 'Points d\'intérêt',
  wd_rental_dates: 'dates de location',
  wd_rentals: 'locations',
  wd_total_earned: 'total gagné',
  wd_total_paid: 'total payé',
  wd_additional_info: 'Informations complémentaires',
  wd_order_total: 'total de la commande',
  wd_provider_place: 'Lieu du fournisseur',
  wd_airports: 'aéroports',
  wd_train_stations: 'gares',
  wd_ports: 'ports',
  wd_available_providers: 'fournisseurs disponibles',
  wd_other_providers: 'autres fournisseurs',

  //Recherche
  wd_destination: 'Destination',
  wd_rental_period: 'Période de location',
  wd_pickup_address: 'Adresse de ramassage',
  wd_dropoff_address: 'Adresse de dépôt',
  wd_address: 'Adresse',
  wd_address_street_landmark: 'Rue ou point de repère',
  wd_address_landmark_airport: "Rue, point de repère ou aéroport",
  wd_address_city_postal: 'Rue, Ville, Code postal',
  wd_address_airport_station: 'Aéroports, Monuments ou Gares',
  wd_flexible: 'Je suis flexible',
  wd_pickup: 'Ramassage',
  wd_dropoff: 'Dépôt',

  //Commun
  wd_email: 'E-mail',
  wd_password: 'Mot de passe',
  wd_confirm_password: 'Confirmer le mot de passe',
  wd_full_name: 'Nom complet',
  wd_first_name: 'Prénom',
  wd_last_name: 'Nom de famille',
  wd_first_last: 'Prénom & Nom',
  wd_login_google: 'Se connecter avec Google',
  wd_signup_google: 'Inscrivez-vous avec Google',
  wd_case_sensitive: 'sensible à la casse',
  wd_recover_password: 'Récupération de mot de passe',
  wd_contact_details: 'Coordonnées',
  wd_create_account: 'Créer un compte',

  wd_brand: 'marque',
  wd_day: 'jour',
  wd_days: 'jours',
  wd_unit: 'unité',
  wd_units: 'unités',
  wd_price: 'prix',
  wd_location: 'emplacement',
  wd_category: 'catégorie',
  wd_categories: 'catégories',
  wd_age: 'âge',
  wd_quantity: 'quantité',
  wd_products: 'produits',
  wd_product: 'produit',
  wd_availability: 'disponibilité',
  wd_more_articles: 'plus d\'articles',

  wd_store_name: 'nom du magasin',
  wd_store_number: 'numéro de magasin',
  wd_store_currency: 'devise de stockage',
  wd_store_status: 'état du magasin',
  wd_store_about: 'À propos de votre magasin',
  wd_store_placeholder: 'Construire la confiance avec votre client en parlant un peu de vous',
  wd_store_availability: 'disponibilité en magasin',
  wd_phone_number: 'Numéro de téléphone',
  wd_delivery_point: 'Point de livraison',
  wd_product_name: 'Nom du produit',
  wd_product_about: 'Description du produit',
  wd_daily_price: 'Prix journalier',
  wd_weekly_price: 'Prix à la semaine',
  wd_monthly_price: 'Prix mensuel',
  wd_add_product: 'Ajouter un produit',
  wd_all_categories: 'Toutes catégories',
  wd_new_product: 'Nouveau produit',
  wd_edit_product: 'Modifier le produit',
  wd_published: 'publié',
  wd_unpublished: 'Inédit',

  wd_all_orders: 'toutes les commandes',
  wd_pending_approval: 'en attente d\'approbation',
  wd_upcoming: 'à venir',
  wd_rental_ending: 'fin de location',
  wd_currently_renting: 'actuellement en location',
  wd_rental_completed: 'location terminée',
  wd_canceled_rejected: 'annulé et rejeté',

  wd_pending: 'en attente',
  wd_accepted: 'accepté',
  wd_rejected: 'rejeté',
  wd_canceled: 'annulé',
  wd_delivering: 'en livraison',
  wd_delivered: 'livré',
  wd_completed: 'terminé',
  wd_refunded: 'remboursé',
  wd_received: 'reçu',

  wd_message_customer: 'Envoyer un message au client',

  //Résultats
  wd_available_self: 'Prise en charge et dépôt en libre-service disponibles GRATUITEMENT',
  wd_available_pick_for: 'Prise en charge et retour disponibles pour',
  wd_available_pick_from: 'Prise en charge et retour disponibles à partir de',

  //Ville
  wd_your_trip_to: 'Votre voyage à',
  wd_rental_period_in: 'Période de location dans',
  wd_available_providers_in: 'fournisseurs disponibles dans',
  wd_available_baby_gear: 'matériel bébé disponible',
  wd_choose_gear_from: 'Choisissez votre équipement bébé parmi',
  wd_products_in: 'produits dans',

  //Fournisseur
  wd_response_rate: 'Taux de réponse',
  wd_response_within: 'Répond en moins d\'une heure',
  wd_orders_accepted: 'Commandes acceptées',
  wd_delivery_airport: 'Livre aux aéroports',
  wd_delivery_station: 'Livre en gare',
  wd_delivery_port: 'Livre aux ports',
  wd_delivery_zones: 'zones de livraison',
  wd_top_clean_reviews: 'Meilleurs avis positifs',
  wd_weekly_discounts: 'Remises hebdomadaires',


  //Produit
  wd_description: 'description',
  wd_read_less: 'lire moins',
  wd_read_more: 'en savoir plus',
  wd_convenient_delivery: "Livraison pratique",
  wd_quality_product: 'Produit de qualité',
  wd_cleanliness_standards: 'Normes de propreté',
  wd_premium_support: 'Assistance Premium',

  //Menu
  wd_myorders: 'mes commandes',
  wd_orders: 'commandes',
  wd_reviews: 'critiques',
  wd_notifications: 'notifications',
  wd_wallet: 'portefeuille',
  wd_favorites: 'favoris',
  wd_chat: 'discuter',
  wd_contact: 'contacter l\'assistance',
  wd_settings: 'paramètres',

  wd_dashboard: 'tableau de bord',
  wd_myproducts: 'mes produits',
  wd_earnings: 'gains',

  //Chariot
  wd_your_cart: 'votre panier',
  wd_cart_empty: 'Votre panier est vide',
  wd_subtotal: 'sous-total',
  wd_total: 'totale',
  wd_discount: 'remise',
  wd_coupon_discount: 'bon de réduction',
  wd_weekly_discount: 'remise hebdomadaire',
  wd_monthly_discount: 'remise mensuelle',
  wd_more_products_provider: 'Plus de produits du fournisseur',
  wd_consumable: 'Consommable',

  //Vérifier
  wd_your_order: 'Votre commande',
  wd_order_details: 'Détails de la commande',
  wd_customer_details: 'Coordonnées client',
  wd_delivery_return: 'Livraison & Retour',
  wd_payment: 'Paiement',
  wd_delivery_summary: 'Récapitulatif de livraison',
  wd_delivery_pickup_provider: 'Livraison & Retrait par prestataire',
  wd_self_pickup: 'Auto-ramassage et retour gratuits',
  wd_self_pickup_tool: "Afficher le lieu de prise en charge approximatif",
  wd_card_number: 'Numéro de carte',
  wd_expiry_month: 'Mois d\'expiration',
  wd_expiry_year: 'Année d\'expiration',
  wd_security_code: 'Code de sécurité',

  add_payment_method: 'Ajouter un mode de paiement',
  add_new_card: 'Ajouter une nouvelle carte',
  add_another_card: 'Ajouter une autre carte',

  order_success: {
    t: 'Votre demande de commande a bien été reçue.',
    s: "Veuillez vérifier votre e-mail, nous vous contacterons dans les prochaines 48 heures au sujet de l'état de votre commande.",
    c: 'acceptera ou refusera votre demande de commande.',
    a: 'Réservation réussie',
    b: 'Vous avez réservé avec succès votre commande et voici votre confirmation',
  },

  //Tableau de bord
  wd_your_earnings: 'Vos gains',
  wd_orders_count: 'Vous avez {{TOTAL}} nouvelles commandes',
  wd_product_rentals_count: 'Ce produit a {{TOTAL}} locations',
  wd_all_time: "Tous les temps",
  wd_today: 'Aujourd\'hui',
  wd_yesterday: 'Hier',
  wd_last_days: '{{TOTAL}} derniers jours',
  wd_last_week: 'La semaine dernière',
  wd_last_month: 'Le mois dernier',
  wd_last_year: 'L\'année dernière',
  wd_quick_actions: 'Actions rapides',
  wd_new_messages: 'Nouveaux messages',

  //Boîte de réception
  wd_choose_conversation: 'Choisir une conversation',
  wd_no_conversations: 'Pas de conversation',
  wd_type_here: 'Tapez ici',

  //Paramètres
  wd_store_settings: 'paramètres du magasin',
  wd_profile: 'profil',
  wd_delivery_pickup: 'livraison & ramassage',
  wd_schedules: 'horaires',
  wd_payout: 'paiement',

  //Boutons
  btn_start_now: 'Commencez maintenant',
  btn_more_info: 'Plus d\'informations',
  btn_become_provider: 'Devenir fournisseur',
  btn_book_now: 'Réservez maintenant',
  btn_provider_area: 'Aller à l\'espace fournisseur',
  btn_apply_now: 'Appliquer maintenant',
  btn_rent_gear: 'Louer du matériel pour bébé',
  btn_submit: 'Soumettre',
  btn_next: 'Suivant',
  btn_back: 'Retour',
  btn_skip: 'Passer',
  btn_cancel: 'Annuler',
  btn_delete: 'Supprimer',
  btn_upload: 'Télécharger',
  btn_refresh: 'Rafraîchir',
  btn_decline: 'Déclin',
  btn_remove: 'Supprimer',
  btn_apply: 'Appliquer',
  btn_continue: 'Continuer',
  btn_select: 'Sélectionner',
  btn_save: 'Sauvegarder',

  btn_add_to_cart: 'Ajouter au panier',
  btn_edit_profile: 'Modifier le profil',
  btn_save_changes: 'Enregistrer les modifications',
  btn_upload_picture: 'Télécharger une image',
  btn_use_picture: 'Utiliser cette image',
  btn_add_delivery_area: 'Ajouter une zone de livraison',
  btn_add_new_product: 'Ajouter un nouveau produit',
  btn_publish_store: 'Magasin de publication',
  btn_save_product: 'Enregistrer le produit',
  btn_delete_product: 'Supprimer le produit',
  btn_clear_calendar: 'Effacer le calendrier',
  btn_contact_customer: 'Contacter le client',
  btn_contact_provider: 'Contacter le fournisseur',
  btn_change_password: 'Changer le mot de passe',

  btn_accepted_order: 'Accepter la commande',
  btn_delivering_order: 'Livraison de la commande',
  btn_delivered_order: 'Commande livrée',
  btn_completed_order: 'Commande terminée',
  btn_leave_review: 'Laisser un avis',
  btn_check_review: 'Vérifiez l\'avis',

  btn_continue_shopping: 'Continuer vos achats',
  btn_finish_booking: 'Terminer la réservation',
  btn_view_products: 'Afficher {{TOTAL}} produits',
  btn_view_all: 'Tout afficher',

  btn_show_map: 'Afficher la carte',
  btn_hide_map: 'Masquer la carte',

  btn_place_order: 'Envoyer la commande',
  btn_manage_order: 'Gérer la commande',



  //Des questions
  ask_which_city: 'Dans quelle ville allez-vous?',
  ask_ready_book: 'Prêt à réserver ?',

  ask_where_delivery: 'Où devrions-nous livrer l\'équipement pour bébé?',
  ask_where_return: 'Où devrions-nous ramasser l\'équipement pour bébé?',
  ask_coupon: 'Avez-vous un coupon?',

  ask_sure: 'Es-tu sûr?',
  ask_sure_accept: 'Êtes-vous sûr de vouloir accepter cette commande ?',
  ask_sure_reject: 'Êtes-vous sûr de vouloir rejeter cet ordre ?',
  ask_sure_cancel: 'Êtes-vous sûr de vouloir annuler cette commande?',

  ask_about_order: 'Avez-vous des questions sur cette commande ?',
  ask_why_reject: ' Pourquoi refusez-vous cet ordre ?',
  ask_why_cancel: 'Pourquoi annulez-vous cette commande ?',
  ask_help: 'Bonjour ! comment puis-je t\'aider?',


  //Réponses
  yes_accept: 'Oui accepter',
  yes_reject: 'Oui rejeter',
  yes_cancel: 'Oui annuler',

  //Info-bulles
  tool_login: 'Nous créerons un compte pour vous afin que vous puissiez gérer vos commandes et contacter le fournisseur à votre convenance. Vous avez déjà un compte?',
  tool_signup: 'Connectez-vous à votre compte pour gérer vos commandes et contacter le prestataire à votre convenance. Vous n\'avez pas de compte?',

  tool_search_city: 'Rechercher un exemple de ville : Londres',
  tool_enter_city: 'Entrez un nom de ville',
  tool_your_email: 'Votre adresse e-mail',
  tool_your_name: 'Votre nom',

  tool_select_rental_dates: 'Sélectionnez les dates de location pour obtenir l\'équipement bébé',
  tool_select_delivery: 'Si vous n\'êtes pas sûr, utilisez simplement la ville pour l\'instant',
  tool_select_checkin: 'Sélectionnez le jour pour obtenir l\'équipement bébé',
  tool_select_checkout: 'Sélectionnez le jour pour retourner l\'équipement bébé',
  tool_select_age: 'Sélectionnez l\'âge de votre enfant',
  tool_select_category: 'Sélectionnez la catégorie de matériel que vous souhaitez louer',
  tool_select_availability: 'Sélectionnez les dates auxquelles vous ne serez pas disponible pour le service de location',
  tool_select_pickup: 'Sélectionner le lieu de prise en charge',
  tool_select_dropoff: 'Sélectionner le lieu de dépôt',
  tool_select_from_dropdown: 'Choisissez votre préférence de livraison parmi les options ci-dessous',

  tool_enter_email: 'Veuillez saisir une adresse e-mail valide',
  tool_enter_checkin: 'Veuillez entrer une date d\'arrivée valide',
  tool_enter_checkout: 'Veuillez entrer une date de paiement valide',
  tool_enter_pickup: 'Veuillez saisir un lieu de prise en charge valide',
  tool_enter_dropoff: 'Veuillez entrer un lieu de dépôt valide',

  tool_store_address: 'Entrez l\'adresse de votre magasin',
  tool_store_closed_dates: 'Magasin fermé aux dates suivantes',
  tool_delivery_locations: 'Lieux de livraison et de ramassage',

  tool_enter_store_address: "Les clients peuvent récupérer eux-mêmes l'équipement à votre adresse sans service de livraison.",
  tool_enter_delivery_locations: "Les aéroports et les monuments populaires sont les endroits les plus demandés par les clients.",

  tool_choose_gear_from: 'Choisissez votre équipement parmi {{TOTAL}} fournisseurs disponibles',
  tool_choose_gear_other: 'Ces {{TOTAL}} fournisseurs n\'offrent pas de livraison à votre emplacement, mais ils livrent à proximité ou sont disponibles pour un ramassage gratuit.',
  tool_add_delivery_area: "Plus vous ajoutez d'emplacements, plus vous pouvez recevoir de commandes.",
  tool_product_availability: 'Voici les dates où ce produit est loué',

  tool_dnd_images: 'Téléchargez vos photos de produits',

  tool_once_deleted: 'Une fois supprimé, vous ne pourrez plus le récupérer',
  tool_once_proceed: 'Une fois supprimé, votre {{TYPE}} sera supprimé. Voulez vous procéder?',

  tool_tell_reason_reject: 'Dites au client une raison pour laquelle cette commande n\'est pas acceptée',
  tool_tell_reason_cancel: 'Dites-nous une raison pour laquelle cette commande sera annulée',

  tool_where_delivery: 'Insérez l\'adresse où vous souhaitez récupérer l\'équipement ou sélectionnez l\'une des options de livraison disponibles.',
  tool_where_return: 'Insérez l\'adresse où vous souhaitez déposer le matériel ou sélectionnez l\'une des options de retour disponibles.',

  tool_insert_coupon: 'Veuillez insérer le code promo et cliquez sur appliquer',
  tool_free_cancellation: 'Annulation gratuite jusqu\'à 48 heures.',

  tool_view_delivery_map: 'Vous pouvez voir où {{store_name}} propose la livraison directe sur la carte.',
  tool_free_self_pickup: 'Récupérez-le gratuitement chez le fournisseur . ',
  tool_interested_other: 'Autres équipements pour bébés qui pourraient vous intéresser.',
  tool_additional_info: 'Écrivez plus d\'informations sur les heures et adresses de livraison et de collecte, le numéro de vol, etc.',

  tool_payment_hold: 'Vous ne serez pas débité tant que la commande n\'aura pas été acceptée. Si la commande n\'est pas acceptée, la retenue sera annulée. Lorsque votre demande de commande est acceptée, les annulations sont entièrement remboursables à l\'exception des frais bocads jusqu\'à 48 heures avant le premier jour de location.',
  tool_payment_finishing: 'En terminant la commande, vous acceptez',
  tool_payment_cancel: 'Annuler la réservation avant',
  tool_payment_refund: 'et vous recevrez un remboursement complet (à l\'exception des frais bocads).',
  tool_select_card_payment: 'Sélectionnez la carte pour la location',


  //Alertes
  alert_recovery_success: 'Nous vous avons envoyé un email ✉️ avec les instructions de récupération',
  alert_store_updated: {
    t: 'Magasin mis à jour',
    s: 'Votre magasin a été mis à jour avec succès'
  },
  alert_availability: {
    reset_t: 'La disponibilité est réinitialisée',
    reset_s: 'La disponibilité de votre magasin a été réinitialisée avec succès',
    save_t: 'La disponibilité est enregistrée',
    save_s: 'La disponibilité de votre magasin a été enregistrée avec succès'
  },
  alert_order: {
    accepted_t: 'Commande acceptée',
    accepted_s: 'Votre commande a été acceptée avec succès',
    rejected_t: 'Commande refusée',
    rejected_s: 'Votre commande a été rejetée avec succès',
    canceled_t: 'Commande annulée',
    canceled_s: 'Votre commande a été annulée avec succès'
  },
  alert_cart: {
    added_t: 'Panier mis à jour',
    added_s: 'Produit ajouté au panier avec succès',
    consumable_t: 'Ajouter d\'abord un article de location',
    consumable_s: 'Ce produit ne peut être commandé qu\'avec un article de location. Veuillez ajouter un équipement pour bébé de ce fournisseur à votre panier avant d\'ajouter des articles consommables.',
    multiple_t: 'Plusieurs fournisseurs',
    multiple_s: 'Il y a des articles d\'un autre fournisseur dans votre panier. Souhaitez-vous vider votre panier pour ajouter cet article ?',
    multiple_c: 'Ajouter un élément',
  },
  alert_expired: {
    checkout_t: 'Commande expirée',
    checkout_s: 'Ce paiement est expiré. Ajoutez de nouveaux produits à votre panier.',
  },

  //Errors
  error_invalid_email: 'Vous avez inséré une adresse e-mail invalide. Veuillez réessayer',
  error_invalid_credentials: 'Les informations d\'identification invalides. L\'e-mail et le mot de passe sont incorrects. Veuillez réessayer ',
  error_invalid_fullname: 'Vous devez entrer votre nom complet avec au moins 6 caractères.',
  error_invalid_password: 'Vous devez entrer un mot de passe d\'au moins 6 caractères.',
  error_invalid_confirmation: 'La confirmation du mot de passe ne correspond pas au mot de passe saisi.',

  error_already_exists: 'Le compte existe déjà pour cet e-mail.',

  error_missing_password: 'Vous n\'avez pas saisi votre mot de passe',
  error_missing_fullname: 'Vous n\'avez pas entré votre nom complet',
  error_missing_phone: 'Vous n\'avez pas entré votre numéro de téléphone',

  error_invalid_address: 'Vous devez entrer une adresse valide avec numéro de maison, rue et ville.',
  error_invalid_store_settings: 'Vous devez remplir la checklist pour accéder aux paramètres de la boutique',
  error_invalid_card: 'La carte insérée n\'est pas valide. Veuillez réessayer',

  error_not_authorized: {
    t: 'Non autorisé',
    s: 'Vous n \' êtes pas autorisé à visiter cette page'
  },

  // Client de destination
  cust_intro: {
    a: 'Location matériel bébé',
    b: 'est à portée de clic',
    p: 'Trouvez tout ce qui facilitera les voyages avec vos petits. N\'importe quand, n\'importe où, avec nos fournisseurs locaux !'
  },
  cust_options: [
    {
      t: "Où et quand vous en avez besoin",
      i: '/assets/img/landing/time.svg'
    },
    {
      t: "Beaucoup d'options de livraison, y compris les aéroports",
      i: '/assets/img/landing/delivery.svg'
    },
    {
      t: 'Produits sûrs et propres',
      i: '/assets/img/landing/clean.svg'
    },
    {
      t: 'Assistance client premium',
      i: '/assets/img/landing/support.svg'
    }
  ],
  cust_how: {
    t: 'Comment ça marche',
    a: "Choisissez l'équipement pour bébé dont vous avez besoin",
    b: 'Récupérer ou recevoir l\'équipement pour bébé du fournisseur',
    c: 'Restituer le matériel à la fin de votre voyage'
  },
  cust_reviews: {
    t: 'Plus de 3,000 clients satisfaits',
    o: [
      {
        i: 'https://user-images.trustpilot.com/6242c03147517c0013ce5370/73x73.png',
        n: 'Claudio Aruanno',
        a: 'Juste 1 mot PARFAIT!',
        b: 'Nous cherchions un lit bébé pour notre fils et sommes tombés sur cette entreprise. Ils nous ont beaucoup aidé et le contact est très bon ainsi que le service ! Nous n\'avions pas réservé de matelas et ils en avaient un supplémentaire pour nous. Super sympa et doux. Merci pour ça. Mon fils a merveilleusement dormi, car la qualité était super !',
        c: 'Nous venons des Pays-Bas et nous le recommandons vivement pour la prochaine fois!'
      },
      {
        i: '/assets/img/avatar.svg',
        n: 'Cynthia Gonzalez',
        a: 'Location de poussette.',
        b: 'J\'ai eu une excellente expérience du début à la fin de ma réservation. Ils m\'ont contacté quelques jours avant le voyage pour organiser la rencontre, le jour où nous nous sommes rencontrés, elle était à l\'heure et la communication a été rapide et facile. La poussette était en excellent état. Au retour de la poussette, ils étaient très flexibles quant à l\'heure et au lieu de la récupérer. Je suis très content d\'avoir choisi cette entreprise, je la recommanderai sans hésiter.',
        c: ''
      },
      {
        i: '/assets/img/avatar.svg',
        n: 'Satu Malmi',
        a: '',
        b: 'Très bonne qualité avec les biens loués (poussette et siège bébé pour voiture). La livraison et le retour se sont également déroulés de manière fluide et flexible. Recommandé pour les autres familles souhaitant s\'assurer que leurs bébés voyagent en toute sécurité et ne souhaitant pas transporter leurs propres affaires à l\'étranger 🙂',
        c: ''
      }
    ]
  },
  cust_cities: 'Grandes villes disponibles',
  cust_clean: {
    a: 'Propre',
    b: '& Sûr',
    p: ' La propreté et la sécurité ont été nos principales priorités depuis le début. Nous prêtons attention au moindre détail pour que vous ayez une expérience de location parfaite. Les produits qui s\'avèrent non conformes à nos normes d\'hygiène et de sécurité sont supprimés de notre site et ne sont plus autorisés à être publiés à nouveau.'
  },
  cust_travel: {
    a: 'Voyage sans',
    b: 'sacs lourds',
    p: 'Passez des vacances sans stress, où vous pourrez profiter de votre voyage et créer des souvenirs avec votre famille sans trimballer beaucoup de choses. Vous pouvez trouver l\'équipement pour bébé dont vous avez besoin où et quand vous le souhaitez. Avec bocads, votre assistant de voyage préféré, vous n\'avez plus à vous soucier de vos sacs lourds.'
  },
  cust_parents: {
    a: 'Partage de ',
    b: 'parent à parent',
    p: 'La plupart de nos fournisseurs sont des parents comme vous, et les points qui vous tiennent à cœur pour votre tout-petit sont également importants pour nos fournisseurs. C\'est pourquoi nous savons que vous trouverez tout ce dont vous avez besoin pour vivre une expérience de voyage sans stress avec votre tout-petit. Nous sommes heureux de trouver des solutions pour vous avec notre large gamme de produits, à partir de prix de location abordables!'
  },
  cust_app: {
    a: "Tout au même endroit",
    b: 'avec l\'application bocads.',
    o: [
      'Obtenez tout ce dont vous avez besoin au même endroit.',
      'Restez en contact avec nos fournisseurs.',
      'Gérer votre commande de location.',
      "Connectez-vous rapidement à notre équipe d'assistance.",
      "Profitez de promotions exclusives sur les applications uniquement."
    ]
  },
  cust_faq: [
    {
      t: 'Comment fonctionne le service de location bocads ?',
      p: 'Après avoir sélectionné l\'équipement pour bébé à louer et soumis votre commande, le Prestataire en est informé. Ils peuvent accepter ou refuser votre commande de location. En cas d\'acceptation, vous recevrez une notification et serez facturé. Le fournisseur coordonnera la livraison ou le ramassage avec vous. Une fois l\'article retourné, par ramassage ou dépôt, votre réservation est terminée.'
    },
    {
      t: 'Quand suis-je débité?',
      p: 'Votre carte de crédit sera débitée une fois la réservation approuvée par le Prestataire. Lorsque vous soumettez votre commande de location, une retenue est placée sur votre compte mais automatiquement annulée si elle est rejetée.'
    },
    {
      t: 'Puis-je annuler et obtenir un remboursement ?',
      p: 'Vous pouvez annuler votre commande à tout moment jusqu\'à 48 heures avant votre période de réservation pour obtenir un remboursement complet moins les frais de service. Après cela, vous n\'êtes plus éligible à un remboursement.'
    },
    {
      t: 'Puis-je modifier ma commande de location ?',
      p: "Oui, votre commande peut être modifiée à tout moment et vous aurez besoin de l'approbation de votre fournisseur pour accepter les modifications."
    },
    {
      t: 'Est-ce que l\'équipement pour bébé est désinfecté?',
      p: 'Nous recommandons que le matériel pour bébé soit nettoyé et désinfecté immédiatement après la fin d\'une réservation. L\'équipement pour bébé doit être nettoyé avec des produits sûrs qui tuent les bactéries et les virus.'
    },

  ],

  //Fournisseur d'atterrissage
  pro_intro: {
    a: 'Devenir Prestataire bocads',
    b: "Lancez votre propre magasin de location de matériel pour bébé"
  },
  pro_start: {
    a: 'Commencez à louer en quelques minutes:',
    b: 'Nom du magasin',
    c: 'Localisation & livraison',
    d: 'Des produits'
  },
  pro_how: {
    t: 'Comment puis-je commencer?',
    p: 'Devenir fournisseur bocads et lancer votre magasin de location de matériel pour bébé est facile. Seulement 3 étapes pour commencer ! Créez votre boutique et ajoutez vos premiers produits pour commencer à recevoir des commandes ! Nous vous aiderons tout au long du chemin.'
  },
  pro_earning: {
    t: 'Gagnez jusqu\'à 1 500 €/mois',
    p: 'Vous cherchez une opportunité d\'augmenter vos revenus mensuels ? Avez-vous une réserve d\'équipement pour bébé que vous n\'utilisez pas? Vous pouvez désormais gagner jusqu\'à 1 500 € par mois en louant votre équipement bébé à des familles itinérantes ! C\'est un excellent moyen de gagner de l\'argent supplémentaire tout en aidant les familles à voyager avec de jeunes enfants. Qu\'il s\'agisse d\'une poussette, d\'un siège auto ou d\'un berceau, l\'équipement pour bébé est toujours nécessaire lorsque les familles ne sont pas à la maison.',
  },
  pro_reviews: {
    i: '/assets/img/avatar.svg',
    n: 'Vincenzo',
    a: 'Fournisseur bocads',
    b: 'Je suis Vincenzo, père de deux belles filles, je suis passionné de voyages et je me souviens encore des projets et des programmes avec ma femme lorsque le premier enfant était sur le point d\'arriver, concernant la nécessité de changer de voiture pour avoir plus d\'espace pour ranger les bagages de la famille qui s\'agrandit ! Quand nous sommes devenus 4, nous avons failli entrer en crise ! J\'ai épousé le projet bocads, estimant que c\'est un excellent moyen de ne pas se priver du plaisir d\'un long voyage, sans se poser le problème de transporter tout ce matériel.'
  },
  pro_options: [
    {
      t: 'Définissez votre propre horaire',
      i: '/assets/img/landing/schedule.svg'
    },
    {
      t: 'Commencez à gagner maintenant',
      i: '/assets/img/landing/earn.svg'
    },
    {
      t: 'Rencontrer de nouvelles personnes',
      i: '/assets/img/landing/community.svg'
    },
    {
      t: 'Soutien de bocads',
      i: '/assets/img/premium-support.svg'
    }
  ],
  pro_faq: [
    {
      t: 'Qu\'est-ce que cela signifie d\'être un fournisseur bocads?',
      p: 'Des poussettes et sièges d\'auto aux parcs et chaises hautes, vous pouvez fournir aux familles tout ce dont elles ont besoin pour rendre leur voyage plus confortable et agréable. En éliminant la nécessité pour les familles d\'apporter leur propre équipement, vous pouvez également les aider à économiser de l\'argent sur les frais de bagages et à réduire le stress lié au voyage avec un bébé. Grâce à votre service fiable et pratique, les familles peuvent se concentrer sur la création de souvenirs pendant leurs voyages sans se soucier de la logistique de l\'équipement pour bébé.'
    },
    {
      t: 'Y a-t-il des frais pour démarrer sur bocads?',
      p: 'Commencer votre aventure en tant que fournisseur bocads est entièrement gratuit. Une fois que vous avez reçu une commande, nous déduirons une somme modique du total, qui servira à faire fonctionner Babobo et à trouver des clients afin de vous aider.'
    },
    {
      t: 'Dois-je acheter du matériel pour commencer?',
      p: 'Pour commencer, utilisez votre équipement bébé existant pour commencer en tant que Prestataire sur bocads ! En fait, nous vous recommandons, si vous souhaitez louer plus de matériel pour bébé, de l\'acheter d\'occasion. Notre seule exigence pour votre équipement pour bébé est qu\'il soit en bon état et propre.'
    },
    {
      t: 'Où dois-je livrer l\'équipement pour bébé?',
      p: 'La plupart de nos fournisseurs livrent des équipements pour bébés dans leurs villes respectives. Vous pouvez choisir des sites célèbres à proximité et des centres de transport comme les gares et les aéroports. Vous pouvez également donner aux clients la possibilité de récupérer l\'équipement pour bébé directement chez vous!'
    },
    {
      t: 'De combien d\'équipement pour bébé ai-je besoin pour commencer?',
      p: 'Vous n\'avez besoin que d\'un seul article pour démarrer en tant que fournisseur bocads. Nous vous recommandons de commencer par une poussette, un berceau ou un siège d\'auto, car ce sont nos articles de location les plus populaires. Une fois que votre entreprise décolle, vous pouvez également envisager d\'ajouter d\'autres articles, tels que des chaises hautes, des parcs et des porte-bébés, à votre inventaire. En offrant une plus large gamme de produits, vous pouvez répondre aux besoins d\'une clientèle plus large et augmenter vos chances d\'attirer des commandes plus importantes.'
    },
    {
      t: 'Combien puis-je gagner en tant que fournisseur bocads?',
      p: 'Vous pouvez gagner jusqu\'à 1500€ par mois en tant que fournisseur bocads. Le montant que vous gagnerez dépendra de l\'endroit où vous vous trouvez et de votre volonté de livrer, ainsi que du nombre d\'articles que vous avez à louer. Nos meilleurs salariés ont toujours des poussettes, des berceaux et des sièges d\'auto à louer.'
    },
    {
      t: 'Comment puis-je commencer?',
      p: 'Pour commencer, il vous suffit de remplir notre demande en 3 étapes. Cela comprend la configuration de votre boutique en ligne et la liste de vos premiers produits. Une fois terminé, vous pouvez publier votre boutique et commencer à accepter des commandes. C\'est aussi simple que ça en a l\'air !'
    }
  ],

  //Connexion / Inscription / Récupération
  auth_login: {
    a: 'Connexion',
    b: 'Vous n\'avez pas de compte? ',
    c: 'Mot de passe oublié?'
  },
  auth_signup: {
    a: 'S\'inscrire',
    b: 'Vous avez déjà un compte? ',
    c: {
      a: 'En cliquant sur s\'abonner, vous acceptez notre',
      b: 'Conditions générales',
      c: 'et le nôtre',
      d: 'Politique de confidentialité',
    }
  },
  auth_recovery: {
    a: 'Mot de passe oublié?',
    b: 'Entrez votre e-mail ci-dessous et nous vous enverrons des instructions pour réinitialiser votre mot de passe.',
  },

  //À propos
  about_page: {
    a: "bocads est un service de location d'équipements pour bébés proposant une variété d'articles pour bébés par l'intermédiaire de locaux pour les familles en voyage dans plus de 250villes.",
    b: 'bocads a été lancé en 2018 par Duygu Sefa, fondateur et fournisseur de bocads. En tant que mère de deux enfants, Duygu pensait qu\'il devait y avoir un moyen plus simple que de transporter tout le matériel nécessaire pour bébé lors d\'un voyage avec des enfants.',
    c: 'Après de nombreux voyages avec ma petite fille, j\'ai réalisé que voyager avec les plus petits peut devenir très stressant, alors je me suis demandé pourquoi ne pas aider les familles à voyager libres de tout matériel ?',
    d: 'Avec une compréhension complète des problèmes auxquels les familles voyageuses pourraient être confrontées, en 2019, le marché de location de matériel pour bébé bocads a été lancé. Depuis le premier jour, les familles ont continué à faire confiance et à rendre bocads indispensable lors de leurs déplacements.',
    e: 'Aujourd\'hui, bocads est une grande famille non seulement de familles itinérantes à la recherche d\'équipements pour bébés, mais également de prestataires du monde entier louant des équipements pour bébés. Les fournisseurs de bocads sont des locaux qui veulent faire du voyage des familles une aventure encore plus extraordinaire et gagner un revenu mensuel supplémentaire tout en le faisant.'
  },

  //Comment ça fonctionne
  how_page: {
    t: "Voyagez avec des petits est plus facile que jamais.",
    a: {
      t: "Choisissez l'équipement pour bébé dont vous avez besoin",
      a: {
        t: 'Choisissez votre emplacement',
        s: 'Choisissez votre destination pour récupérer votre commande en sélectionnant la ville que vous visiterez.'
      },
      b: {
        t: 'Sélectionnez vos dates',
        s: 'Ajoutez vos dates de voyage en fonction de votre voyage. N\'hésitez pas à contacter le fournisseur si vous avez des questions sur la disponibilité.'
      },
      c: {
        t: 'Choisissez l\'équipement pour bébé',
        s: "Vous pouvez trouver des sièges d'auto de marque haut de gamme, tout type de poussette, des berceaux portables."
      },
    },
    b: {
      t: 'Recevez l\'équipement pour bébé du fournisseur où vous en avez besoin.',
      s: 'Nous avons beaucoup d\'options de livraison!',
      a: {
        t: 'Directement aux aéroports.'
      },
      b: {
        t: 'Directement vers les hôtels et les locations dans les centres-villes.'
      },
      c: {
        t: 'Rendez-vous aux points d\'intérêt.',
        s: 'comme les gares ferroviaires et routières centrales ou les points de repère de la ville. Ou récupérez l\'équipement gratuitement chez le fournisseur . '
      },
      d: {
        a: 'Le retrait est gratuit chez le fournisseur . ',
        b: 'Pour les autres lieux de livraison, les fournisseurs facturent généralement des frais de livraison. Vous verrez les frais de livraison lors du paiement.',
        c: 'Une fois que vous avez réservé votre location, le fournisseur acceptera ou refusera la commande dans les 24 heures. Vous recevrez un email avec la confirmation. Une retenue sera placée sur votre compte jusqu\'à ce que la commande soit acceptée, puis vous serez débité.',
        d: 'bocads ne facture que lorsque votre commande est acceptée par le Prestataire.'
      }

    },
    c: {
      t: 'Restituer le matériel bébé à la fin de votre voyage',
      s: 'Selon l\'option choisie, rencontrez le fournisseur bocads au lieu de prise en charge à l\'heure ou attendez qu\'il récupère l\'équipement pour bébé. Assurez-vous de retourner les produits dans les mêmes conditions. Et c\'est tout!'
    },
    d: {
      a: 'Où que vous alliez,',
      b: 'l\'équipement pour bébé vous y rencontrera!',
      c: "La santé et la sécurité sont notre priorité",
      d: "Options de ramassage et de retour flexibles",
      e: "Une assistance chaque fois que vous en avez besoin"
    }

  },

  //Confiance & Sécurité
  trust_page: {
    a: {
      t: 'bocads Confiance & Sécurité',
      s: 'La confiance et la sécurité ont été une priorité depuis le début jusqu\'à maintenant. Nous prêtons attention aux moindres détails pour que vous ayez une expérience de location parfaite.'
    },
    b: {
      t: 'Confiance :',
      a: "Nous avons plus de 2 000 clients satisfaits.",
      b: 'Ne craignez pas un changement de plans de voyage, vous pouvez annuler votre commande avec un remboursement complet jusqu\'à 48 heures avant votre jour de location !',
      c: 'Si vous n\'êtes pas satisfait de l\'équipement pour bébé que vous louez, nous le remplacerons par une alternative dans les 24 heures. Si nous ne trouvons pas d\'autre option, nous vous rembourserons!'
    },
    c: {
      t: 'Sécurité & Hygiène:',
      s: 'Nos fournisseurs doivent respecter nos normes de sécurité et d\'hygiène en matière de nettoyage. Le matériel de location pour bébé est soigneusement nettoyé par les Prestataires après chaque location. Tous les Fournisseurs bocads et leurs produits passent par un processus d\'approbation. Et vos avis sont très précieux pour nous. Nous prenons vos avis en considération et prenons les mesures nécessaires, le cas échéant.'
    },
    d: {
      t: 'Protection des réservations:',
      a: 'Vos informations personnelles ne seront en aucun cas vendues ou distribuées. Votre vie privée est importante pour nous. Nous vous demandons de fournir votre nom complet, votre adresse e-mail, votre numéro de téléphone et vos informations de paiement via notre site.',
      b: "Nous faisons de notre mieux pour vous garantir une expérience de réservation sans faille, mais si un fournisseur bocads doit annuler à la dernière minute, nous ferons de notre mieux pour vous aider à trouver un remplaçant."
    },
    e: {
      t: 'Hâte de te rencontrer!',
      s: 'Si vous avez besoin de quoi que ce soit tout au long de votre expérience de location, n\'hésitez pas à contacter notre équipe d\'experts. Vous pouvez nous joindre à tout moment via le chat en direct New Website review 11 sur notre site Web ou notre application, ou par e-mail à info@bocads.com et nous serions heureux de vous aider.'
    }

  },

  //Télécharger
  download_page: {
    t: 'Télécharger l\'application',
    s: 'Gérez votre location facilement avec l\'application bocads. Téléchargez et commencez à l\'utiliser maintenant !',
    a: 'Tout en un seul endroit avec l\'application bocads.',
    b: 'Développé avec vos besoins à l\'esprit.'
  },

  //Emplacements
  locations_page: {
    t: 'Louez du matériel pour bébé',
    s: 'Louez des sièges d\'auto, des poussettes, des lits et bien d\'autres produits. Nos prestataires vous livrent où et quand vous le souhaitez. Voyagez léger et en sécurité avec vos enfants.',
  },

  //Flux client
  cust_flow: {
    a: {
      t: 'Quand souhaitez-vous louer?',
      s: 'Veuillez sélectionner les dates de location (prise en charge et retour) dans'
    },
    b: {
      t: 'Prendre et déposer',
      s: 'Où voulez-vous être livré - ramassage et dépôt'
    },
    c: {
      t: 'De quel équipement avez-vous besoin?',
      s: 'Sélectionnez les catégories d\'équipement et l\'âge de votre enfant'
    }
  },

  // Flux du fournisseur
  pro_flow: {
    a: {
      t: 'Informations de base sur votre magasin.',
      s: "Votre numéro de contact ne sera partagé avec le client que lorsque vous accepterez sa demande de commande."
    },
    b: {
      t: 'Emplacement et livraison.',
      s: "Cela aidera les clients à choisir votre magasin le mieux adapté à leur emplacement"
    },
    c: {
      t: 'Génial! Votre premier produit est disponible.',
      s: 'Ajouter plus de produits ici pour une configuration rapide du magasin.'
    },
  },
  pro_check: {
    t: 'Merci d\'avoir créé votre profil de fournisseur. Veuillez suivre les étapes ci-dessous pour commencer à louer.',
    a: 'Téléchargez votre photo de profil',
    b: 'Définissez vos tarifs de livraison et de ramassage',
    c: 'Définir l\'adresse de votre magasin ',
    d: 'Télécharger des produits',
    e: 'Ajouter des informations de contact',
    z: 'Votre profil est en cours de vérification. Nous vous enverrons un e-mail dans les 24 heures'
  },

  wd_list_months: [
    'Janvier',
    'Février',
    'mars',
    'Avril',
    'mai',
    'juin',
    'juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
  ],

  wd_how_guide: {
    a: 'Réservez votre location de matériel bébé',
    b: 'Recevez le matériel bébé de votre prestataire où vous voulez',
    c: 'Bonne balade avec ton petit',
    d: 'Restituer le matériel bébé à la fin de votre voyage',
    cta: "Je l'ai"
  },

  wd_list_schedules: {
    t: 'Horaires de ramassage et de livraison',
    s: 'Vérifiez votre disponibilité hebdomadaire pour le service de livraison et le ramassage à domicile.',
    home: 'Retrait à domicile',
    service: 'Service de livraison',
    un: 'lundi',
    b: 'mardi',
    c: 'mercredi',
    d: 'jeudi',
    e: 'vendredi',
    f: 'samedi',
    g: 'dimanche'
  },

  wd_list_payout: {
    t: 'Options de paiement',
    s: 'Ajoutez vos informations de paiement pour recevoir les revenus de vos commandes.',
    a: 'Nom de la banque',
    b: 'Numéro de sécurité sociale',
    c: 'Pays',
    d: 'Type',
    e: 'Virement bancaire',
    f: 'Numéro de compte',
    g: 'Numéro d\'acheminement',
    connected: 'Connecté',
    disconnect: 'Déconnecter'

  },

  wd_list_earnings: {
    earn_baby: 'Gagnez et dépensez des BabyCoins?',
    ref_code: 'Votre code de parrainage',
    recent: 'Transactions récentes',
    ask_this: 'Qu\'est-ce que c\'est ?',
    learn_how: 'Apprenez comment'
  },

  wd_analytics: 'Analytique',
  wd_pending_rate: 'Taux en attente',
  wd_acceptance_rate: 'Taux d\'acceptation',
  wd_rejection_rate: 'Taux de rejet',
  wd_score: 'Score',
  wd_performance: 'Performances',
  wd_total_customers: 'Nombre total de clients',
  wd_total_products: 'Total des produits',
  wd_total_days: 'Nombre total de jours',
  wd_aov: 'Valeur moyenne de la commande',
  wd_total_earnings: 'Gains totaux',
  wd_total_to_earn: 'Total à gagner',
  wd_total_lost: 'Total perdu',

  wd_list_orders: [
    'Pour cette commande, vous serez facturé {{TOTAL}} si vous annulez.',
    'Pour cette commande, vous serez facturé {{TOTAL}} si vous annulez.',
    'Pour cette commande, vous ne serez pas débité si vous annulez.',
    'Si votre commande a été acceptée par le fournisseur :',
    'Les commandes annulées avant les 48 dernières heures du premier jour de location sont remboursées à l\'exception des frais bocads',
    'Les commandes annulées dans les 48 heures précédant le premier jour de location ne sont pas remboursables',
    'Les annulations ne peuvent pas être remboursées si le premier jour de la location est dans les 48 heures.'
  ]



};

export default TRANSLATER