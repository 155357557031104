import React from "react";
import cn from "classnames";
import styles from "./Profile.module.sass";
import Icon from "../../../components/Icon";
import { Link } from "react-router-dom";

const socials = [
  {
    title: "twitter",
    url: "https://twitter.com/bocads",
  },
  {
    title: "instagram",
    url: "https://www.instagram.com/bocadsnet/",
  },
  {
    title: "pinterest",
    url: "https://www.pinterest.com/bocadsm/",
  },
];

const Profile = (props) => {

  const {LANGUAGE} = props;

  return (
    <div className={styles.profile}>
      <div className={styles.details}>
        <div className={styles.avatar}>
          <img src="/images/content/avatar.jpg" alt="Avatar" />
          <button className={styles.add}>
            <Icon name="add" />
          </button>
        </div>
        <div className={styles.wrap}>
          <div className={cn("h4", styles.man)}>Bocads</div>
          <div className={styles.info}>
            Make your business life easier
          </div>
        </div>
      </div>
      <div className={styles.contacts}>
        <div className={styles.socials}>
          {socials.map((x, index) => (
            <a
              className={styles.social}
              href={x.url}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
            >
              <Icon name={x.title} size="24" />
            </a>
          ))}
        </div>
        <Link to={`/${LANGUAGE}/projects/add`} className={cn("button", styles.button)}>Create Project</Link>
      </div>
    </div>
  );
};

export default Profile;
