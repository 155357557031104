import cn from "classnames";
import styles from "./ProductFiles.module.sass";
import Card from "../../../components/Card";
import File from "../../../components/File";

const ProductFiles = ({ className, brandLogo, setUploadLogo }) => {
    return (
        <Card className={cn(styles.card, className)}
            // title="Product files"
            // classTitle="title-blue"
        >
            <div className={``}>
                <File
                    className={styles.field}
                    title="Click or drop image"
                    label="Brand Logo"
                    tooltip="Maximum size 1024x1024. Max 1023kb"
                    onClick={()=> setUploadLogo(true)}
                    file={brandLogo}
                />
                {/* <File
                    className={styles.field}
                    title="Click or drop image"
                    label="Brand Icon"
                    tooltip="Maximum size 1024x1024. Max 1024kb"
                /> */}
            </div>
        </Card>
    );
};

export default ProductFiles;
