import React, { useState } from "react";
import styles from "./BrandsAdd.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Modal from "../../components/Modal";
import Schedule from "../../components/Schedule";
import NameAndDescription from "./NameAndDescription";
import ProductFiles from "./ProductFiles";
import Panel from "./Panel";

import PickerImage from "../General/PickerImage";
import ColorPalette from "./ColorPallette";


const BrandsAdd = (props) => {
    const [visiblePreview, setVisiblePreview] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);

    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());

    //Brand Creation
    const [brandName, setBrandName] = useState('');
    const [brandDescription, setBrandDescription] = useState('');
    const [brandCategory, setBrandCategory] = useState('Food & Drink');
    const [brandLogo, setBrandLogo] = useState(null);
    const [brandIcon, setBrandIcon] = useState(null);
    const [primaryColor, setPrimaryColor] = useState('');
    const [secondaryColor, setSecondaryColor] = useState('');
    const [aboutColor, setAboutColor] = useState('');

    const [isUploadLogo, setUploadLogo] = useState(false);

    const onChangeColor = (color, destination) => {
        if(destination === 'primary_color'){
            setPrimaryColor(color.hex)
        }
        else if(destination === 'secondary_color'){
            setSecondaryColor(color.hex);
        }
        else if(destination === 'about_color'){
            setAboutColor(color.hex);
        }
    };


    // Function to handle form submission
    const onSelectImage = (logo) => {
        setBrandLogo(logo.src);
    };


    const sharedProps = {
        ...props,
        brandName,
        brandCategory,
        brandDescription,
        brandLogo,
        brandIcon,
        primaryColor,
        secondaryColor,

        setBrandName,
        setBrandDescription,
        setBrandLogo,
        setBrandCategory,
        setBrandIcon,
        setPrimaryColor,
        setSecondaryColor,

        onSelectImage,
        onChangeColor,

        isUploadLogo,
        setUploadLogo,
        
    }

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>
                    <NameAndDescription {...sharedProps} className={styles.card} />
                </div>
                <div className={styles.col}>
                    <ProductFiles {...sharedProps} className={styles.card} />
                </div>
                <ColorPalette {...sharedProps} />
            </div>
            <Panel
                {...sharedProps}
                setVisiblePreview={setVisiblePreview}
                setVisibleSchedule={setVisibleModal}
            />
            <TooltipGlodal />
            <Modal
                visible={visibleModal}
                onClose={() => setVisibleModal(false)}
            >
                <Schedule
                    startDate={startDate}
                    setStartDate={setStartDate}
                    startTime={startTime}
                    setStartTime={setStartTime}
                />
            </Modal>

            {isUploadLogo && (
                 <PickerImage {...props}
                    onClose={() => setUploadLogo(false)}
                    onSelectImage={onSelectImage}
                    TYPE={"logo"}
                />
            )}
        </>
    );
};

export default BrandsAdd;
