import React from "react";
import styles from "./BrandItem.module.sass";
import cn from "classnames";
import { Link } from "react-router-dom";

const BrandItem = ({ className, item }) => {
 
  return (
    <div className={cn(styles.follower, className)}>
      <div className={styles.details}>
        <div className={styles.avatar}>
          <img src={item.icon} alt="Avatar" />
        </div>
        <div className={styles.wrap}>
          <div className={styles.man}>{item.man}</div>
          <div className={styles.list}>
            <div className={styles.counter}>
              <span>{item.projects}</span> projects
            </div>
            <div className={styles.counter}>
              <span>{item.teammates}</span> teammates
            </div>
          </div>
          <div className={styles.btns}>
            <button className={cn("button-stroke", styles.button)}>
              Open
            </button>

            <Link
              className={cn("button", styles.button)}
              to="/message-center"
            >
              Invite 
            </Link>
           
          </div>
        </div>
      </div>
      <div className={styles.gallery}>
        {item?.gallery?.map((x, index) => (
          <div className={styles.preview} key={index}>
            <img srcSet={`${x.image2x} 2x`} src={x.image} alt="Product" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrandItem;
