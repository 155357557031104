import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./ProfileInformation.module.sass";
import Item from "../Item";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import Editor from "../../../components/Editor";

import {postApiPrivate} from "../../../api";

const ProfileInformation = (props) => {

  const { USER, setFlowStep, className } = props;

  const [isImage, setImage] = useState(false);
  const [isImagePhotos, setImagePhotos] = useState(false);

  const [NAME, setName] = useState('');
  const [EMAIL, setEmail] = useState('');
  const [ABOUT, setAbout] = useState('');
  const [INSTAGRAM, setInstagram] = useState('');

  //Profile
  const [userPicture, setUserPicture] = useState(null);
  const [userBased, setUserBased] = useState(null);

  const [PHOTOS, setPhotos] = useState([]);
  const [LOCATION, setLocation] = useState(null);

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);

  useEffect(() => {

      if (USER?.name) {

          var first_name = USER.first_name;
          var last_name = USER.last_name;
          var about = USER.about;
          var instagram = USER.instagram;
          var photos = USER.photos || [];

          var final_email = USER.email || '';
          var final_location = USER.location || '';
          var final_name = first_name + ' ' + last_name;
          var final_about = `${about ? about.replace(/\\n/gi, '\n') : ''}`;

          setName(final_name);
          setEmail(final_email);
          setAbout(final_about);
          setLocation(final_location);

          setInstagram(instagram);
          setPhotos(photos);

          setLoading(false);
      }

      // alert("HERE 2 ===> "+JSON.stringify(USER, null, 2));

  }, [USER])

  useEffect(() => {

      if (USER?.img) {
          setUserPicture(USER.img);
      }

  }, [USER]);

  useEffect(() => {

      if (isImage || isImagePhotos) {
          document.body.style.overflow = 'hidden';
      }
      else {
          document.body.style.overflow = 'auto';
      }

  }, [isImage, isImagePhotos])

  // const onFetchLocation = () => {

  //     setLoading(true);

  //     if ("geolocation" in navigator) {

  //         navigator.geolocation.getCurrentPosition(
  //             async (position) => {
  //                 const latitude = position.coords.latitude;
  //                 const longitude = position.coords.longitude;

  //                 try {
  //                     const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;

  //                     const response = await fetch(url);
  //                     const data = await response.json();

  //                     const dataAddress = data.address || {}
  //                     const city = dataAddress.city;
  //                     const country = dataAddress.country;

  //                     console.log("HERE ===> ", data);

  //                     setLocation(`${city}, ${country}`);
  //                     setLoading(false);
  //                 } catch (error) {
  //                     console.error("Error fetching location data:", error);
  //                     setLoading(false);
  //                 }
  //             },
  //             (error) => {
  //                 console.error("Error getting geolocation:", error);
  //                 setLoading(false);
  //             }
  //         );
  //     } else {
  //         console.log("Geolocation is not available in this browser.");
  //         setLoading(false);
  //     }
  // }

  // const onSaveSettings = async () => {

  //     const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

  //     let FORM = {
  //         first_name: FIRST,
  //         last_name: LAST,
  //         about: ABOUT,
  //         instagram: INSTAGRAM,
  //         photos: PHOTOS,
  //         based: userBased,
  //         location: LOCATION,

  //         needSitting,
  //         needTraining,
  //         needWalking,

  //         interests: USER.interests,
  //         setup: true

  //     }

  //     console.log("FORM TO SAVE ===> ", FORM);

  //     // return swal('Form', JSON.stringify(FORM, null, 2), 'success');

  //     setSaving(true);

  //     try {

  //         const DATA_RESPONSE = await postApiPrivate('/users/settings', FORM, JWT);

  //         if (DATA_RESPONSE.error) {
  //             console.log("ERROR FROM API SAVING STORE", DATA_RESPONSE.error);
  //             setSaving(false);
  //             return
  //         }

  //         console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

  //         onSelectNext()
  //         //swal('Account Updated', 'Your account has been saved successfully', 'success')

  //         // setTimeout(() => {
  //         //     return (typeof global.window !== 'undefined') ? window.location.replace('/' + LANGUAGE + '/settings') : null;
  //         // }, 1000)

  //     }
  //     catch (e) {

  //         setSaving(false);
  //     }


  // }

  // const onSelectImage = async (image) => {

  //     if (image.id.length > 2) {
  //         setUserPicture(image.src);
  //         setUserBased(image.id);
  //     }
  //     else {
  //         setUserPicture(image.src);
  //         setUserBased(image.src);
  //     }
  // }

  // const onSelectImagePhotos = (image) => {
  //     setPhotos((prev) => [...prev, image.src]);
  // }

  // const onDeleteImage = (image) => {
  //     setPhotos((prev) => prev.filter((item) => item !== image))
  // }

  // const onSelectBack = () => {
  //     setFlowStep(0);
  // }

  // const onSelectNext = () => {

  //     setFlowStep(2);

  //     // const Pets = USER.pets || [];
  //     // if(Pets.length == 0){
  //     //     setFlowStep(2);
  //     // }
  //     // else{
  //     //     setFlowStep(3);
  //     // }

  // }

  return (
    <Item
      className={cn(styles.card, className)}
      title="Profile information"
      classTitle="title-green"
    >
      <div className={styles.profile}>
        <div className={styles.avatar}>
          <img src={userPicture} alt="Avatar" />
          <button className={styles.remove}>
            <Icon name="close" />
          </button>
        </div>
        <div className={styles.file}>
          <input type="file" />
          <button className={cn("button", styles.button)} type="button">
            <Icon name="add" size="24" />
            <span>Upload new picture</span>
          </button>
        </div>
        <button className={cn("button-stroke", styles.button)}>Remove</button>
      </div>
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label="Display name"
          name="display-name"
          type="text"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
          value={NAME}
          onChange={(e)=> setName(e.target.value)}
        />
        <TextInput
          className={styles.field}
          label="Email"
          name="email"
          type="email"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
          value={EMAIL}
          onChange={(e)=> setEmail(e.target.value)}
        />
        <TextInput
          className={styles.field}
          label="Location"
          name="location"
          type="text"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          required
        />
        <Editor
          state={ABOUT}
          onChange={setAbout}
          classEditor={styles.editor}
          label="Bio"
          tooltip="Description"
        />
      </div>
    </Item>
  );
};

export default ProfileInformation;
