import cn from "classnames";
import styles from "./ProductFiles.module.sass";
import Card from "../../../components/Card";
import File from "../../../components/File";
import TextColor from "../../General/PickerColor";

const ProductFiles = ({ className, brandLogo, setUploadLogo, primaryColor, secondaryColor, onChangeColor }) => {
    return (
        <Card className={cn(styles.card, className)}
        // title="Brand Logo"
        // classTitle="title-blue"
        >
            <div className={``}>
                <File
                    className={styles.field}
                    title="Click or drop image"
                    label="Brand Logo"
                    tooltip="Maximum size 1024x1024. Max 1023kb"
                    onClick={() => setUploadLogo(true)}
                    file={brandLogo}
                />

                {primaryColor && (
                    <TextColor
                        className={`${styles.field} mb-8`}
                        label="Primary color"
                        name="title"
                        type="text"
                        placeholder="Headline, background and button"
                        tooltip="Select the color that will be used for the headline text, background and button of the creatives"
                        required
                        onChange={(color) => onChangeColor(color, 'primary_color')}
                        value={primaryColor}
                    />
                )}

                {secondaryColor && (
                    <TextColor
                        className={`${styles.field} mb-8`}
                        label="Secondary color"
                        name="title"
                        type="text"
                        placeholder="Subtitle or Secondary text"
                        tooltip="Select the color that will be used for the subtitle text on the creatives"
                        required
                        onChange={(color) => onChangeColor(color, 'secondary_color')}
                        value={secondaryColor}
                    />
                )}

            </div>
        </Card>
    );
};

export default ProductFiles;
