import React, { useEffect } from "react";
import styles from "./Signout.module.sass";
import { use100vh } from "react-div-100vh";
import { useNavigate } from "react-router-dom";

const SignOut = (props) => {

  const { onStartUser, LANGUAGE } = props;

  const heightWindow = use100vh();
  const navigate = useNavigate();

  useEffect(() => {

    setTimeout(() => {

      localStorage.removeItem('jwt_token');

      onStartUser('reset');

      const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
      if (isNativeApp) {
        const webData = {
          type: 'LOGOUT',
          data: null
        }
        window.ReactNativeWebView.postMessage(JSON.stringify(webData));
      }

      navigate(`/${LANGUAGE}`)


    }, 1000)

  }, [onStartUser, LANGUAGE, navigate])

  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>

      <div className={styles.wrapper}>
        <div className='bocads-circle'>
          <img src="/images/logo-dark.png" alt="Bocads" className="w-[100px] h-[100px] animate-bounce" />
        </div>
        <div className="text-lg">Loading...</div>
      </div>
     
    </div>
  );
};

export default SignOut;
