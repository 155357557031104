import React, {useState} from "react";
import cn from "classnames";
import styles from "./Entry.module.sass";
import TextInput from "../../../components/TextInput";
import Image from "../../../components/Image";

import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import swal from "sweetalert";

const Entry = (props) => {

  const { TRANSLATE, history, onStartUser, type, onFinishSignup, LANGUAGE, OriginFrom } = props;

  const [EMAIL, setEmail] = useState('');
  const [PASSWORD, setPassword] = useState('');
  const [CONFIRMPASSWORD, setConfirmPassword] = useState('');
  const [FULLNAME, setFullname] = useState('');

  const [isLoading, setLoading] = useState(false);

  const onKeyUp = (event) => {

      event.preventDefault();


      if (event.keyCode === 13) {

          event.target.blur();
          onSignup();

      }
  }

  const onSignup = () => {

      const FORM = {
          fullname: FULLNAME.trim(),
          email: EMAIL.trim(),
          password: PASSWORD.trim(),
          confirmpassword: CONFIRMPASSWORD.trim(),
          lang: TRANSLATE.lang,
          type: OriginFrom
      }

      if (!isValidForm(FORM, TRANSLATE)) {
          return
      }


      setLoading(true);

      axios.post('/users/signup', FORM).then(response => {

          setLoading(false);

          localStorage.setItem('jwt_token', response.data.jwt_token);

          onStartUser();
          history.push('/' + LANGUAGE + '/settings');
        
          const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
          if (isNativeApp) {
              const webData = {
                  type: type || 'SIGNUP',
                  data: response.data.jwt_token
              }
              window.ReactNativeWebView.postMessage(JSON.stringify(webData));
          }

      })
          .catch(error => {

              setLoading(false);

              if (!error || !error.response) {
                  return swal('Signup', TRANSLATE.error_invalid_credentials, 'warning')
              }

              var error_data = error.response.data;
              var error_message = error_data.error;

              console.log("ERROR ===> ", error_message);

              if (error_message === 'missing_password') {
                  return swal(TRANSLATE.wd_signup, TRANSLATE.error_missing_password, 'warning')
              }
              else if (error_message === 'missing_email') {
                  return swal(TRANSLATE.wd_signup, TRANSLATE.error_invalid_email, 'warning')
              }
              else if (error_message === 'user_already_exists') {
                  return swal(TRANSLATE.wd_signup, TRANSLATE.error_already_exists, 'warning')
              }

              return swal(TRANSLATE.wd_signup, TRANSLATE.error_invalid_credentials, 'warning')

          });
  }

  const onLoginGoogle = async (params) => {

      if (!params) {
          return swal(TRANSLATE.wd_login, TRANSLATE.error_invalid_email)
      }

      setLoading(true);

      if (params.access_token) {
          const Response = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo?access_token=" + params.access_token);
          const ResponseData = Response ? Response.data : {};
          params = {
              ...params,
              ...ResponseData
          }
      }

      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      var FORM = {
          lang: LANGUAGE,
          oauth: params,
          timezone: userTimezone

      }

      axios.post('/users/login/google', FORM).then(response => {


          setLoading(false);

          localStorage.setItem('jwt_token', response.data.jwt_token);

          const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
          if (isNativeApp) {
              const webData = {
                  type: 'LOGIN',
                  data: response.data.jwt_token
              }
              window.ReactNativeWebView.postMessage(JSON.stringify(webData));
          }

          onStartUser();


          history.push('/' + LANGUAGE + '/settings');

      })
          .catch(error => {

              setLoading(false);

              if (!error || !error.response) {
                  return swal('Login', TRANSLATE.error_invalid_credentials)
              }

              var error_data = error.response.data;
              var error_message = error_data.error;

              if (error_message === 'missing_password') {
                  return swal('Login', TRANSLATE.error_missing_password)
              }
              else if (error_message === 'missing_email') {
                  return swal('Login', TRANSLATE.error_invalid_email)
              }

              return swal('Login', TRANSLATE.error_invalid_credentials)
          });
  }

  return (
    <div className={styles.entry}>
      <div className={styles.head}>
        <div className={styles.info}>Sign up with Open account</div>
        <div className={styles.btns}>
          <GoogleLoginComponent {...props} onLoginGoogle={onLoginGoogle} />
          <button className={cn("button-stroke", styles.button)}>
            <Image
              className={styles.pic}
              src="/images/content/apple-dark.svg"
              srcDark="/images/content/apple-light.svg"
              alt="Apple"
            />
            Apple ID
          </button>
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.info}>Or continue with email address</div>
        <TextInput
          className={styles.field}
          name="email"
          type="email"
          placeholder="Your email"
          required
          icon="mail"
        />
        <button className={cn("button", styles.button)} onClick={onSignup}>
          Continue
        </button>
        <div className={styles.note}>
          This site is protected by reCAPTCHA and the Google Privacy Policy.
        </div>
      </div>
    </div>
  );
};

const isValidEmail = (email) => {

  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);

}

const isValidForm = (form, TRANSLATE) => {

  var fullname_string = form.fullname.trim();
  var email_string = form.email.trim();
  var password_string = form.password.trim();
  var confirmpassword_string = form.confirmpassword.trim();

  if (fullname_string.length < 6) {

      var error_title = TRANSLATE.wd_first_last;
      var error_message = TRANSLATE.error_invalid_fullname;

      swal(error_title, error_message, 'warning')

      return false;
  }


  if (!(isValidEmail(email_string))) {

      var error_title = TRANSLATE.wd_email;
      var error_message = TRANSLATE.error_invalid_email;

      swal(error_title, error_message, 'warning')

      return false;

  }

  if (password_string.length < 6) {

      var error_title = TRANSLATE.wd_password;
      var error_message = TRANSLATE.error_invalid_password;

      swal(error_title, error_message, 'warning')

      return false;

  }


  if (password_string !== confirmpassword_string) {

      var error_title = TRANSLATE.wd_confirm_password;
      var error_message = TRANSLATE.error_invalid_confirmation;

      swal(error_title, error_message, 'warning')

      return false;
  }

  return true;


}



const GoogleLoginComponent = (props) => {

  const { GoogleClass, TRANSLATE, onLoginGoogle } = props;

  const [DATA, setData] = useState();

  const isLocalhost = window.location.href.includes('localhost:3000');

  const login = useGoogleLogin({
      flow: 'auth-code',
      ux_mode: 'redirect',
      prompt: 'select_account',
      redirect_uri: isLocalhost ? 'http://localhost:3000/en/login' : 'https://www.bocads.com/en/login',
      onSuccess: credentialResponse => {
          console.log(JSON.stringify(credentialResponse));
          setData(credentialResponse);
          onLoginGoogle(credentialResponse);
          return
      },
      onError: error => {
          console.log('Login Failed');
          setData(error)
      },
  });

  return (
    <button className={cn("button-stroke", styles.button)} onClick={() => login()}>
      <img src="/images/content/google.svg" alt="Google" />
      Google
    </button>
  );
}

export default Entry;
