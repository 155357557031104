import React, { useEffect, useRef, useState } from "react";

import { postApiPrivate } from "../../api";

import swal from "sweetalert";

import ReactFileReader from 'react-file-reader';

import { ImageCompressor, getImageSize } from "compressor-img";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import SRC_CLOSE from '../../assets/img/close.svg';
import SRC_LOADER from '../../assets/img/loader.png';

import SRC_BACK from '../../assets/img/chevron-right.svg';

import SRC_HORIZONTAL from '../../assets/img/flip-horizontal.svg';
import SRC_VERTICAL from '../../assets/img/flip-vertical.svg';
import SRC_LEFT from '../../assets/img/rotate-left.svg';
import SRC_RIGHT from '../../assets/img/rotate-right.svg';
import SRC_NOCROP from '../../assets/img/no-crop.svg';
import SRC_DOWNLOAD from '../../assets/img/download.svg';
import SRC_RESET from '../../assets/img/reset.svg';
import SRC_DELETE from '../../assets/img/trash.svg';

import SRC_MOVETOFOLDER from '../../assets/img/move-to-folder.svg';
import SRC_REMOVEFROMFOLDER from '../../assets/img/remove-from-folder.svg';
import SRC_SHARE from '../../assets/img/share-big.svg';
import SRC_FOLDERADD from '../../assets/img/folder-add.svg';
import SRC_VECTOR from '../../assets/img/mobile-bar/vector.svg';

const PickerImage = (props) => {

    const currentButton = useRef();

    const { onClose, onSelectImage, TYPE, TRANSLATE, isCustomer } = props;

    const [isCropping, setCropping] = useState(false);
    const [isUploading, setUploading] = useState(false);
    const [isSearching, setSearching] = useState(false);
    const [isLoading, setLoading] = useState(true);

    const [CurrentImage, setCurrentImage] = useState(null);
    const [COMPRESSED, setCompressed] = useState(null);
    const [LIBRARY, setLibrary] = useState([]);

    const [cropper, setCropper] = useState();

    const [SearchInput, setSearchInput] = useState('');
    const [SearchResults, setSearchResults] = useState([]);

    useEffect(() => {

        onFetchLibrary();

    }, [])


    const onFetchLibrary = async () => {

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        const FORM = {
            p: 0,
            s: 'updatedAt',
            d: 'desc',
            l: 200
        };

        setLoading(true);

        const DATA_RESPONSE = await postApiPrivate('/users/library', FORM, JWT);

        if (DATA_RESPONSE.error) {
            console.log("ERROR FROM API LOADING PRODUCTS", DATA_RESPONSE.error);

            setLoading(false);

            return
        }

        const ResponsePhotos = DATA_RESPONSE.data || [];

        const DataPhotos = localStorage.getItem('photos')
        const ArrayPhotos = DataPhotos ? JSON.parse(DataPhotos) : [];
        const FinalPhotos = [...ResponsePhotos, ...ArrayPhotos,]

        setLibrary(FinalPhotos)

        setLoading(false);

        if (ResponsePhotos.length == 0) {
            currentButton.current.click()
        }
    }

    const onKeyUpSearch = (event) => {

        event.preventDefault();


        if (event.keyCode === 13) {

            event.target.blur();

            let query = event.target.value || ''

            if (!query || query.length < 3) {
                swal('At least 3 characters', 'You need to insert at least 3 characters to search', 'warning')
                return
            }

            onSearchData(event.target.value);

        }
    }

    const onSearchData = async (query) => {

        const DataPhotos = localStorage.getItem('photos')
        const ArrayPhotos = DataPhotos ? JSON.parse(DataPhotos) : [];

        setSearching(true);
        setSearchInput(query);

        if (!query || query.length < 3) {
            setSearchResults([]);
            return
        }

        let FORM = {
            query,
            type: 'unsplash'
        }

        //Fetch
        try {

            setLoading(true);

            const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

            const DATA_RESPONSE = await postApiPrivate('/users/library/search', FORM, JWT);

            if (DATA_RESPONSE.error) {
                console.log("ERROR FROM API FETCHING ", DATA_RESPONSE.error);
                setLoading(false);
                return
            }

            console.log("DATA_RESPONSE ====> ", DATA_RESPONSE);

            const filtredResults = DATA_RESPONSE.data || [];

            if (filtredResults.length > 0) {
                setSearchResults(filtredResults);
                localStorage.setItem('photos', JSON.stringify([...filtredResults, ...ArrayPhotos]))
            }

            setLoading(false);

        }
        catch (e) {

            console.log("CREATING ===> ", e);

            setSearchResults([]);
            setLoading(false);
        }


    }

    const onUploadImage = async (Compressed) => {

        let CurrentCompressed = COMPRESSED;

        if (Compressed) {
            CurrentCompressed = Compressed
        }

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

        setLoading(true);

        try {

            var FORM = {
                name: TYPE?.toUpperCase(),
                ...CurrentCompressed,
                type: TYPE
            };

            console.log("SEARCH ===> ", FORM);

            const DATA_RESPONSE = await postApiPrivate('/users/library/upload', FORM, JWT);

            if (DATA_RESPONSE.error) {
                setLoading(false);
                swal('Error', 'Cannot Update This Image', 'error');

                console.log("ERROR FROM API LOADING STORE", DATA_RESPONSE.error);
                return
            }

            console.log("SEARCH ===> ", DATA_RESPONSE);

            setLoading(false);
            // swal('Photo Uploaded', 'This photo has been uploaded successfully !!!', 'success');

            setCropping(false);
            onFetchLibrary();

        }
        catch (e) {

            setLoading(false);
            swal('Error', 'Cannot Upload This Photo', 'error');

        }
    }

    const onHandleUpload = (files) => {

        console.log("Files ==> ", files);
        const OriginalFile = files.fileList[0];

        let reader = new FileReader();
        reader.onloadend = () => {
            const newImage = reader.result;
            setCurrentImage(newImage)
            setCropping(true);
        };

        reader.readAsDataURL(OriginalFile);
    }


    let libraryFiltred = LIBRARY || [];

    if (isSearching) {
        libraryFiltred = SearchResults || []
    }

    const sharedProps = {
        ...props,
        CurrentImage,
        setCurrentImage,
        cropper,
        setCropper,
        setCropping,
        COMPRESSED,
        setCompressed,
        onUploadImage,
        isLoading,
        setLoading
    }
    return (
        <>
            {/* <FolderMove /> */}
            {/* <PhotoOptions /> */}
            {/* <NewFolder  /> */}

            <div className='w-full h-full fixed z-[99999] top-0 left-0 bg-transparent flex items-end justify-center'>
                <div className="absolute w-full h-full top-0 left-0 z-10 bg-black/[.5]" onClick={() => isCropping ? setCropping(false) : onClose()} />

                {isCropping ? (
                    <>
                        {isLoading ? (
                            <div className='w-full bg-white overflow-auto p-4 rounded-tl-2xl rounded-tr-2xl drawer-in drop-shadow-2xl flex flex-col' style={{ maxHeight: '80%', zIndex: 9999 }}>
                                <div className="overflow-auto h-full pt-8">
                                    <div className='w-full h-[200px] flex flex-col items-center justify-center'>
                                        <img className='animate-spin w-[24px] h-[24px]' src={SRC_LOADER} />
                                        <div className="pt-1">Uploading your images...</div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <NewCropping {...sharedProps} onClose={() => setCropping(false)} />
                        )}
                    </>

                ) : (
                    <div className='w-full bg-white overflow-auto p-4 rounded-tl-2xl rounded-tr-2xl drawer-in drop-shadow-2xl flex flex-col' style={{ maxHeight: '80%', zIndex: 9999 }}>

                        {isLoading ? (
                            <div className="overflow-auto h-full pt-8">
                                <div className='w-full h-[200px] flex flex-col items-center justify-center'>
                                    <img className='animate-spin w-[24px] h-[24px]' src={SRC_LOADER} alt="" />
                                    <div className="pt-1">Loading...</div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="overflow-auto pb-0 flex flex-wrap">

                                    {libraryFiltred.length == 0 ? (
                                        <div className='w-full h-[200px] flex items-center justify-center'>
                                            <div>0 pictures found</div>
                                        </div>
                                    ) : (
                                        <>
                                            {libraryFiltred.map((item, index) => {

                                                return (
                                                    <div className="w-1/2 relative">
                                                        {/* <div className='w-6 h-6 rounded-lg bg-white bg-opacity-30 border border-white absolute top-2 right-2 z-50' /> */}
                                                        <img className="w-full h-full border object-cover cursor-pointer" src={item.src} key={index} onClick={() => { onSelectImage(item); onClose() }} alt="" />
                                                    </div>
                                                )
                                            })}
                                        </>
                                    )}

                                </div>



                            </>
                        )}

                        <div className='w-full mt-4'>
                            <ReactFileReader fileTypes={[".jpg", ".jpeg", ".png", ".gif"]} base64={true} multipleFiles={false} handleFiles={onHandleUpload} >
                                <button className='w-full bg-zinc-800 rounded-xl text-white text-sm text-center py-4' ref={currentButton}>
                                    {TRANSLATE.btn_upload_picture}
                                </button>
                            </ReactFileReader>
                        </div>

                    </div>
                )}

            </div>


        </>
    )

}

const NewCropping = (props) => {

    const aspectRatios = [
        [1, 1],    // Square (1:1)
        [4, 5],    // Vertical 4:5
        [9, 16],   // Vertical 9:16 (Instagram Stories)
        [2, 3],    // Vertical 2:3
        [3, 4],    // Vertical 3:4
        [16, 9],   // Horizontal 16:9
        [21, 9],   // Ultrawide 21:9
        [1, 2],    // Vertical 1:2 (Pinterest)
        [1, 3],    // Vertical 1:3
        [3, 2],    // Horizontal 3:2
        [2, 1],    // Horizontal 2:1
        [4, 3],    // Horizontal 4:3
        [5, 4],    // Horizontal 5:4
        [4, 7],    // Vertical 4:7
        [7, 4],    // Horizontal 7:4
    ];

    const { onClose, CurrentImage, setCurrentImage, setFiltering, setCropper, cropper, setCompressed, setCropping, onUploadImage, setLoading } = props;

    const [selectedAspectRatio, setSelectedAspectRatio] = useState([1, 1]);

    const [scale, setScale] = useState(1);
    const [isCropperLoading, setCropperLoading] = useState(true);
    const [scaleX, setScaleX] = useState(1);
    const [scaleY, setScaleY] = useState(1);

    const cropperRef = useRef(null);

    const onRotate = (direction) => {
        //alert("direct == "+direction)
        let angle = 0;
        let angleConfig = {
            left: -30,
            right: 30,
        };
        angle = angleConfig[direction] ?? 0;
        cropperRef.current.cropper.rotate(angle);
    };

    const onFlip = (type) => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        if (type === "h") {
            cropper.scaleX(scaleX === 1 ? -1 : 1);
            setScaleX(scaleX === 1 ? -1 : 1);
        } else {
            cropper.scaleY(scaleY === 1 ? -1 : 1);
            setScaleY(scaleY === 1 ? -1 : 1);
        }
    };

    const onAspectRatio = (newAspectRatio) => {
        setSelectedAspectRatio(newAspectRatio);
        cropper.setAspectRatio(newAspectRatio[0] / newAspectRatio[1]);
    }

    const onScale = e => {
        const scaleValue = parseFloat(e.target.value);
        setScale(scaleValue);
        cropperRef.current.cropper.scale(scaleValue);
    };

    const onReset = () => {
        const cropper = cropperRef.current.cropper;
        cropper.reset();
        setScale(1);
    };

    const onDownload = () => {
        const data = cropperRef.current.cropper.getCroppedCanvas();
        data.toBlob(blob => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "React Image Editor.jpg";
            a.click();
            URL.revokeObjectURL(url);
        });
    };

    // const onImageUpload = e => {
    //   const file = e.target.files?.[0];
    //   if (!file) return;
    //   const reader = new FileReader();

    //   reader.onload = e => {
    //     setImageSrc(e.target.result);
    //     cropperRef.current.cropper.reset();
    //   };

    //   reader.readAsDataURL(file);
    // };

    // const onCropImage = () => {

    //     setLoading(true)

    //     if (typeof cropper !== "undefined") {

    //         //const newImage = cropper.getCroppedCanvas().toDataURL();
    //         const newImage = cropper.getCroppedCanvas({ imageSmoothingEnabled: true, imageSmoothingQuality: 'high' }).toDataURL('image/png');

    //         const newSize = getImageSize(newImage);

    //         let imageCompressor = new ImageCompressor({
    //             onSuccess: response => {

    //                 const compressedImg = response.compressed;

    //                 const originalSize = getImageSize(response.original);
    //                 const compressedSize = getImageSize(response.compressed);

    //                 if (originalSize > 3000) {

    //                 }
    //                 else if (originalSize <= 3000 && originalSize > 250) {

    //                 }
    //                 else if (originalSize <= 250) {

    //                 }

    //                 console.log("COMPRESSED ===> ", compressedImg);
    //                 console.log("ORIGINAL SIZE ===> ", originalSize);
    //                 console.log("COMPRESSED SIZE ===> ", compressedSize);
    //                 setCompressed({
    //                     src: compressedImg,
    //                     uploaded: false,
    //                     size: originalSize,
    //                     compressed: compressedSize
    //                 })

    //                 setCurrentImage(compressedImg);
    //                 onUploadImage({
    //                     src: compressedImg,
    //                     uploaded: false,
    //                     size: originalSize,
    //                     compressed: compressedSize
    //                 })
    //             },
    //             //scale: 100,
    //             quality: 75,
    //             originalImage: newImage
    //         });

    //     }

    // }

    const onCropImage = () => {
        setLoading(true);

        if (typeof cropper !== "undefined") {
            const canvas = cropper.getCroppedCanvas({ imageSmoothingEnabled: true, imageSmoothingQuality: 'high' });

            canvas.toBlob(blob => {
                const file = new File([blob], 'image', { type: blob.type });

                if (file.type === 'image/jpeg') {
                    // If it's a JPEG image, compress it
                    const reader = new FileReader();

                    reader.onload = event => {
                        // Compression logic here for JPEG images
                        const compressedImage = compressJPEG(event.target.result);

                        const originalSize = blob.size;
                        const compressedSize = getImageSize(compressedImage);

                        // Handle different size conditions
                        if (originalSize > 3000) {
                            // handle image larger than 3000 bytes
                        } else if (originalSize <= 3000 && originalSize > 250) {
                            // handle image smaller than 3000 bytes and larger than 250 bytes
                        } else if (originalSize <= 250) {
                            // handle image smaller than 250 bytes
                        }

                        setCompressed({
                            src: compressedImage,
                            uploaded: false,
                            size: originalSize,
                            compressed: compressedSize
                        });

                        setCurrentImage(compressedImage);
                        onUploadImage({
                            src: compressedImage,
                            uploaded: false,
                            size: originalSize,
                            compressed: compressedSize
                        });
                    };

                    reader.readAsDataURL(file);
                } else {
                    // For PNG or GIF, keep the original image without compression
                    const newImage = canvas.toDataURL(file.type);

                    const originalSize = blob.size;

                    setCompressed({
                        src: newImage,
                        uploaded: false,
                        size: originalSize,
                        compressed: originalSize // uncompressed, so the same size
                    });

                    setCurrentImage(newImage);
                    onUploadImage({
                        src: newImage,
                        uploaded: false,
                        size: originalSize,
                        compressed: originalSize
                    });
                }
            });
        }
    };

    // Function to compress JPEG images (you can replace this with your JPEG compression logic)
    const compressJPEG = async (imageData) => {
        // Decode base64 image
        const img = new Image();
        img.src = imageData;
      
        // Create canvas for full-size image
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, img.width, img.height);
      
        // Convert to full-size base64 image
        const fullImage = await new Promise((resolve) => {
          canvas.toBlob((blob) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          }, 'image/jpeg', 0.7); // adjust the quality here
        });
      
        // Create canvas for thumbnail
        const maxThumbSize = 200; // Adjust the max thumbnail size
        let thumbWidth = img.width;
        let thumbHeight = img.height;
        if (thumbWidth > maxThumbSize || thumbHeight > maxThumbSize) {
          if (thumbWidth > thumbHeight) {
            thumbHeight *= maxThumbSize / thumbWidth;
            thumbWidth = maxThumbSize;
          } else {
            thumbWidth *= maxThumbSize / thumbHeight;
            thumbHeight = maxThumbSize;
          }
        }
      
        const thumbCanvas = document.createElement('canvas');
        thumbCanvas.width = thumbWidth;
        thumbCanvas.height = thumbHeight;
        const thumbCtx = thumbCanvas.getContext('2d');
        thumbCtx.drawImage(img, 0, 0, thumbWidth, thumbHeight);
      
        // Convert to thumbnail base64 image
        const thumbnail = await new Promise((resolve) => {
          thumbCanvas.toBlob((blob) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          }, 'image/jpeg', 0.5); // adjust the quality for the thumbnail
        });
      
        const fullImageSize = getImageSize(fullImage);
        if (fullImageSize > 3000000) {
          alert('The uploaded file exceeds 3MB.');
        }
      
        return { thumbnail, fullImage };
      };
      

    const onDontCrop = () => {
        // setFiltering(true);
        // setCropping(false);
    }


    return (
        <div className='w-full bg-white overflow-auto p-4 rounded-tl-2xl rounded-tr-2xl drawer-in drop-shadow-2xl flex flex-col' style={{ maxHeight: '80%', zIndex: 9999 }}>
            <div className="h-full flex flex-col justify-start">
                <div className='w-full h-1/2 flex-shrink-0'>
                    {isCropperLoading && (
                        <div className='w-full flex items-center justify-center'>
                            <div className='bocads-circle'>
                                <div className='bocads-paw-small left' />
                                <div className='bocads-paw-small right' />
                                <div className='bocads-paw-big left' />
                                <div className='bocads-paw-big right' />
                            </div>
                        </div>
                    )}
                    <Cropper
                        style={{ height: "100%", width: "100%" }}
                        initialAspectRatio={selectedAspectRatio[0] / selectedAspectRatio[1]}
                        aspectRatio={selectedAspectRatio[0] / selectedAspectRatio[1]}
                        src={CurrentImage}
                        //viewMode={1}
                        cropBoxResizable={false}
                        dragMode={'move'}
                        background={true}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                        ready={() => {
                            setCropperLoading(false);
                        }}
                        guides={true}
                        ref={cropperRef}
                    />
                </div>

                <div className='w-full h-1/2 flex-shrink-0 overflow-auto bg-white relative z-20 pb-10'>

                    <div className='w-full flex-shrink-0 bg-white border-b border-zinc-200 relative z-20 scrollbar-hide flex flex-nowrap overflow-auto gap-1 p-4'>

                        <div className='flex flex-col items-center flex-shrink-0 w-[70px]' onClick={() => onRotate("left")}>
                            <img className="w-6 h-6" src={SRC_RIGHT} />
                            <div className='text-[10px] mt-1'>Rotate L</div>
                        </div>
                        <div className='flex flex-col items-center flex-shrink-0 w-[70px]' onClick={() => onRotate("right")}>
                            <img className="w-6 h-6" src={SRC_LEFT} />
                            <div className='text-[10px] mt-1'>Rotate R</div>
                        </div>
                        <div className='flex flex-col items-center flex-shrink-0 w-[70px]' onClick={() => onFlip("h")}>
                            <img className="w-6 h-6" src={SRC_HORIZONTAL} />
                            <div className='text-[10px] mt-1'>Flip H</div>
                        </div>
                        <div className='flex flex-col items-center flex-shrink-0 w-[70px]' onClick={() => onFlip("v")}>
                            <img className="w-6 h-6" src={SRC_VERTICAL} />
                            <div className='text-[10px] mt-1'>Flip V</div>
                        </div>
                        <div className='flex flex-col items-center flex-shrink-0 w-[70px]' onClick={() => onReset()}>
                            <img className="w-6 h-6" src={SRC_RESET} />
                            <div className='text-[10px] mt-1'>Reset</div>
                        </div>
                        <div className='flex flex-col items-center flex-shrink-0 w-[70px]' onClick={() => onDownload()}>
                            <img className="w-6 h-6" src={SRC_DOWNLOAD} />
                            <div className='text-[10px] mt-1'>Download</div>
                        </div>

                        <div className='flex flex-col items-center flex-shrink-0 w-[70px]' onClick={() => onDontCrop()}>
                            <img className="w-6 h-6" src={SRC_NOCROP} />
                            <div className='text-[10px] mt-1'>No Crop</div>
                        </div>

                    </div>
                    <div className='w-full p-4 pb-0 font-semibold'>Ratio</div>
                    <div className='w-full flex-shrink-0 bg-white relative z-20 text-sm flex gap-x-3 overflow-auto scrollbar-hide p-4'>

                        {aspectRatios.map((ratio, index) => {

                            const isAspect = ratio[0] == selectedAspectRatio[0] && ratio[1] == selectedAspectRatio[1];

                            return (
                                <div className={'px-2 py-1 border border-zinc-200 rounded-full flex-shrink-0 ' + ((isAspect) ? 'bg-bocads-100 text-white' : 'text-black')} onClick={() => onAspectRatio(ratio)} key={index}>
                                    <div className='text-xs m-1'>{ratio[0]} : {ratio[1]}</div>
                                </div>
                            )
                        })}

                    </div>
                    <div className='w-full flex-shrink-0 bg-white relative z-20  p-4'>

                        <label className='w-full py-4 pb-0 font-semibold' htmlFor="scale">Scale</label>
                        <div className='w-full flex flex-col'>
                            <input
                                type="range"
                                min="0.2"
                                max="2"
                                step="0.2"
                                value={scale}
                                aria-label="scale"
                                id="scale"
                                onChange={onScale}
                                className='range accent-bocads-100'
                            />



                        </div>
                    </div>

                </div>

            </div>

            <div className='w-full mt-4' onClick={() => onCropImage()}>
                <button className='w-full bg-bocads-100 rounded-xl text-white text-sm text-center py-4'>
                    Confirm Upload
                </button>
            </div>
        </div>

    )
}

const NewFolder = () => {

    return (
        <div className='w-full h-full fixed z-[99999] top-0 left-0 bg-black bg-opacity-30 flex items-end justify-center'>
            <div className='w-full bg-white overflow-auto p-4 rounded-tl-2xl rounded-tr-2xl drawer-in drop-shadow-2xl flex flex-col'>
                <div className="text-lg font-semibold mb-3">Create new folder</div>
                <div className="input-group !mb-0">
                    <input type="text" placeholder="Folder name" />
                </div>
                <div className='w-full mt-4'>
                    <button className='w-full bg-zinc-800 rounded-xl text-white text-sm text-center py-4'>
                        Save
                    </button>
                </div>
            </div>
        </div>
    )
}
const PhotoOptions = () => {
    {/***THIS WILL BE OPEN ON LONG PRESS PHOTO* */ }
    return (
        <div className='w-full h-full fixed z-[99999] top-0 left-0 bg-black bg-opacity-30 flex items-end justify-center'>
            <div className='w-full bg-white overflow-auto p-4 rounded-tl-2xl rounded-tr-2xl drawer-in drop-shadow-2xl flex flex-col'>
                <div className="flex items-center py-4 w-full">
                    <img className="flex-shrink-0" src={SRC_MOVETOFOLDER} />
                    <div className="text-lg ml-2">Move to folder</div>
                </div>
                <hr />
                <div className="flex items-center py-4 w-full">
                    <img className="flex-shrink-0" src={SRC_REMOVEFROMFOLDER} />
                    <div className="text-lg ml-2">Remove from folder</div>
                </div>
                <hr />
                <div className="flex items-center py-4 w-full">
                    <img className="flex-shrink-0" src={SRC_SHARE} />
                    <div className="text-lg ml-2">Share</div>
                </div>
                <hr />
                <div className="flex items-center py-4 w-full">
                    <img className="flex-shrink-0" src={SRC_DELETE} />
                    <div className="text-lg ml-2">Delete</div>
                </div>

            </div>
        </div>
    )
}
const FolderMove = () => {
    {/***THIS WILL BE OPEN ON WHEN USER WANTS TO MOVE PHOTO TO FOLDER *+*/ }
    return (
        <div className='w-full h-full fixed z-[99999] top-0 left-0 bg-black bg-opacity-30 flex items-end justify-center'>
            <div className='w-full bg-white overflow-auto p-4 rounded-tl-2xl rounded-tr-2xl drawer-in drop-shadow-2xl flex flex-col'>
                <div className="flex items-center py-4 w-full">
                    <img className="flex-shrink-0" src={SRC_FOLDERADD} />
                    <div className="text-lg ml-2">Avatars</div>
                    <div className='bg-bocads-100 rounded-full flex items-center justify-center w-[30px] h-[30px] ml-auto'>
                        <img src={SRC_VECTOR} />
                    </div>
                </div>
                <hr />
                <div className="flex items-center py-4 w-full">
                    <img className="flex-shrink-0" src={SRC_FOLDERADD} />
                    <div className="text-lg ml-2">Manu</div>
                    <div className='bg-bocads-100 grayscale opacity-30 rounded-full flex items-center justify-center w-[30px] h-[30px] ml-auto'>
                        <img src={SRC_VECTOR} />
                    </div>
                </div>
                <hr />
                <div className="flex items-center py-4 w-full">
                    <img className="flex-shrink-0" src={SRC_FOLDERADD} />
                    <div className="text-lg ml-2">Charlie</div>
                    <div className='bg-bocads-100 grayscale opacity-30 rounded-full flex items-center justify-center w-[30px] h-[30px] ml-auto'>
                        <img src={SRC_VECTOR} />
                    </div>
                </div>
                <hr />
                <div className="flex items-center py-4 w-full">
                    <img className="flex-shrink-0" src={SRC_FOLDERADD} />
                    <div className="text-lg ml-2">Anthony</div>
                    <div className='bg-bocads-100 grayscale opacity-30 rounded-full flex items-center justify-center w-[30px] h-[30px] ml-auto'>
                        <img src={SRC_VECTOR} />
                    </div>
                </div>
                <hr />
                <div className="flex items-center py-4 w-full">
                    <img className="flex-shrink-0" src={SRC_FOLDERADD} />
                    <div className="text-lg ml-2">Charlie</div>
                    <div className='bg-bocads-100 grayscale opacity-30 rounded-full flex items-center justify-center w-[30px] h-[30px] ml-auto'>
                        <img src={SRC_VECTOR} />
                    </div>
                </div>

                <div className='w-full mt-4'>
                    <button className='w-full bg-zinc-800 rounded-xl text-white text-sm text-center py-4'>
                        Move to folder
                    </button>
                </div>

            </div>
        </div>
    )
}

export default PickerImage