import "./styles/app.sass";

import React, { useEffect, useState } from 'react';

import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { postApiPrivate } from './api';

import swal from 'sweetalert';


import LANG_IT from './translations/it';
import LANG_EN from './translations/en';
import LANG_FR from './translations/fr';
import LANG_ES from './translations/es';

import Page from "./components/Page";

import Home from "./screens/Home";
import Dashboard from "./screens/Dashboard";
import Campaigns from './screens/Campaigns';
import NewCampaign from './screens/NewCampaign';

import ProductsDashboard from "./screens/ProductsDashboard";
import NewProduct from "./screens/NewProduct";
import Drafts from "./screens/Drafts";
import Released from "./screens/Released";
import Comments from "./screens/Comments";
import Scheduled from "./screens/Scheduled";
import Customers from "./screens/Customers";
import CustomerList from "./screens/CustomerList";
import Promote from "./screens/Promote";
import Notification from "./screens/Notification";
import Settings from "./screens/Settings";
import UpgradeToPro from "./screens/UpgradeToPro";
import MessageCenter from "./screens/MessageCenter";
import ExploreCreators from "./screens/ExploreCreators";
import AffiliateCenter from "./screens/AffiliateCenter";

import Earning from "./screens/Earning";
import Refunds from "./screens/Refunds";
import Payouts from "./screens/Payouts";
import Statements from "./screens/Statements";
import Shop from "./screens/Shop";
import PageList from "./screens/PageList";
import NewPost from "./screens/Promote/RecentPost/NewPost";

//App
import SignUp from "./screens/SignUp";
import SignIn from "./screens/SignIn";
import SignRecovery from "./screens/SignRecovery";
import SignOut from "./screens/SignOut";

import BrandsList from './app/BrandsList'
import BrandsAdd from './app/BrandsAdd'
import BrandsView from './app/BrandsView'

import ProjectsList from './app/ProjectsList'
import ProjectsAdd from './app/ProjectsAdd'

// import './assets/styles.css'

const isProd = true;

if (isProd) {
  console.log = () => { };
}


const App = (props) => {

  const [USER, setUser] = useState(null);
  const [LANGUAGE, setLanguage] = useState('en');

  const [MC, setMC] = useState(0);
  const [NC, setNC] = useState(0);

  const [isAuthorized, setAuthorized] = useState(true);
  const [isModalUser, setModalUser] = useState(false);

  const [isModalSearch, setModalSearch] = useState(false);
  const [isModalCart, setModalCart] = useState(false);
  const [isModalLocalisation, setModalLocalisation] = useState(false);
  const [isModalGuide, setModalGuide] = useState(false);
  const [isModalCity, setModalCity] = useState(false);
  const [isModalPosts, setModalPosts] = useState(false);
  const [isModalAvatar, setModalAvatar] = useState(false);

  useEffect(() => {
    onStartUser();
    //console.log("Here ===> ");
  }, []);

  useEffect(() => {
    localStorage.setItem('language', LANGUAGE);
  }, [LANGUAGE]);


  const onStartUser = async (isReset) => {

    if (isReset) {
      setAuthorized(false);
      onUpdateUser(null);
      return
    }

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    if (JWT) {
      setAuthorized(true)
      onFetchUser(JWT);
    }
    else{
      setAuthorized(false);
    }

  }

  const onFetchUser = async (jwt, payload) => {

    if (payload) {
      onUpdateUser(payload);
      return
    }

    if (!jwt) {
      console.log("ERROR NO JWT FOUND ---> On Fetch User");

      alert('Check your internet connection and try again. 3');

      return
    }

    try {

      const DATA_RESPONSE = await postApiPrivate('/users/profile', {}, jwt);

      if (DATA_RESPONSE.error) {
        console.log("ERROR FROM API USER INFO", DATA_RESPONSE.error);

        swal('Warning', 'You have been disconnected', 'warning')

        if(isProd){
          localStorage.removeItem('jwt_token');
        }
        
        onStartUser();

        return

      }

      const DATA_USER = DATA_RESPONSE || {};
      const DATA_MESSAGES = DATA_USER.mc || 0;
      const DATA_NOTIFICATIONS = DATA_USER.nc || 0;

      setMC(DATA_MESSAGES);
      setNC(DATA_NOTIFICATIONS);

      console.log("CHECKING USER PROFILE === ", DATA_USER);

      onUpdateUser(DATA_USER);

      //alert('ALERT ===> '+JSON.stringify(DATA_USER))

    }
    catch (e) {

      console.log("FETCH USER ERROR ===> ", e);

      // if(match?.url){
      //   window.location.replace('/' + LANGUAGE + '/login?next='+match?.url)
      // }
      // else{
      //   window.location.replace('/' + LANGUAGE + '/login')
      // }



      //alert('Account Disconnected ===> '+JSON.stringify(e, null, 2));
      return //alert('Check your internet connection and try again. 5');
    }

  }

  const onUpdateUser = (user) => {
    setUser(user);
  }

  const onUpdateLanguage = (language) => {
    setLanguage(language)
  }

  const onFetchCounts = (type, value) => {
    if (type === 'mc') {
      setMC(value);
    }
    else if (type === 'nc') {
      setNC(value);
    }
  }


  let TRANSLATE = onTranslate(LANGUAGE);


  const sharedProps = {
    ...props,
    USER,
    LANGUAGE,
    TRANSLATE,
    MC,
    NC,

    onFetchUser,
    onStartUser,
    onUpdateUser,
    onFetchCounts,

    onUpdateLanguage,

    isModalCart,
    isModalGuide,
    isModalLocalisation,
    isModalSearch,
    isModalUser,
    isModalCity,
    isModalPosts,
    isModalAvatar,

    setModalCart,
    setModalGuide,
    setModalLocalisation,
    setModalSearch,
    setModalUser,
    setModalCity,
    setModalPosts,
    setModalAvatar

  }

  return (

    <Routes>

      {/*** Public - Home ***/}
      <Route path="/">

        <Route index element={<Home {...sharedProps} />} />
        <Route path="en" element={<Home {...sharedProps} />} />
        <Route path="fr" element={<Home {...sharedProps} />} />
        <Route path="it" element={<Home {...sharedProps} />} />
        <Route path="es" element={<Home {...sharedProps} />} />

        <Route path="/:lang/login" element={<SignIn {...sharedProps} />} />
        <Route path="/:lang/signup" element={<SignUp {...sharedProps} />} />
        <Route path="/:lang/recovery" element={<SignRecovery {...sharedProps} />} />
        <Route path="/:lang/logout" element={<SignOut {...sharedProps} />} />

        <Route path="/:lang/dashboard" element={isAuthorized ? <DashboardContainer {...sharedProps} /> : <Navigate to={`/${LANGUAGE}/login`} />}>
          <Route index element={<Dashboard />} />
          <Route path="profile" element={<ProductsDashboard />} />
        </Route>

        <Route path="/:lang/brands" element={isAuthorized ? <DashboardContainer {...sharedProps} /> : <Navigate to={`/${LANGUAGE}/login`} />}>
          <Route index element={<BrandsList title="Brands List" {...sharedProps} />} />
          <Route path="add" element={<BrandsAdd title="New Brand"  {...sharedProps} />} />
          <Route path="view/:id" element={<BrandsView title="View Brand"  {...sharedProps} />} />
        </Route>
        <Route path="/:lang/projects" element={isAuthorized ? <DashboardContainer {...sharedProps} /> : <Navigate to={`/${LANGUAGE}/login`} />}>
          <Route index element={<ProjectsList title="Projects List" {...sharedProps} />} />
          <Route path="creatives" element={<ProjectsList title="Creatives List"  {...sharedProps} />} />
          <Route path="text" element={<ProjectsList title="Copy List"  {...sharedProps} />} />
          <Route path="strategy" element={<ProjectsList title="Strategy List"  {...sharedProps} />} />
          
          <Route path="add" element={<ProjectsAdd title="New Project"  {...sharedProps} />} />
          <Route path="edit" element={<ProjectsAdd title="Edit Project"  {...sharedProps} />} />
          <Route path="preview" element={<ProjectsAdd title="Preview Project"  {...sharedProps} />} />
        </Route>
        
        <Route path="/:lang/campaigns" element={isAuthorized ? <DashboardContainer {...sharedProps} /> : <Navigate to={`/${LANGUAGE}/login`} />}>
          <Route path="active" element={<Campaigns title="Active Campaigns" />} />
          <Route path="paused" element={<Campaigns title="Paused Campaigns" />} />
          <Route path="drafts" element={<Drafts title="Drafts Campaigns" />} />
          <Route path="scheduled" element={<Scheduled title="Scheduled Campaigns" />} />
          <Route path="shared" element={<Campaigns title="Shared Campaigns" />} />
          <Route path="add" element={<NewCampaign title="New campaigns" />} />
        </Route>

        <Route path="/:lang/builder" element={isAuthorized ? <DashboardContainer {...sharedProps} /> : <Navigate to={`/${LANGUAGE}/login`} />}>
          <Route path="image" element={<Promote title="Promote with Image" />} />
          <Route path="video" element={<Promote title="Promote with Video" />} />
          <Route path="carousel" element={<Promote title="Promote with Carousel" />} />
          <Route path="shared" element={<Promote title="Shared Creatives" />} />
          <Route path="add" element={<NewPost title="New post" />} />
        </Route>

        <Route path="/:lang/analytics" element={isAuthorized ? <DashboardContainer {...sharedProps} /> : <Navigate to={`/${LANGUAGE}/login`} />}>
          <Route index element={<Customers title="Analytics" />} />
          <Route path="paused" element={<Campaigns title="Paused Campaigns" />} />
          <Route path="drafts" element={<Campaigns title="Drafts Campaigns" />} />
          <Route path="scheduled" element={<Campaigns title="Scheduled Campaigns" />} />
          <Route path="shared" element={<Campaigns title="Shared Campaigns" />} />
          <Route path="add" element={<NewCampaign title="New campaigns" />} />
        </Route>

        <Route path="/:lang/schedules" element={isAuthorized ? <DashboardContainer {...sharedProps} /> : <Navigate to={`/${LANGUAGE}/login`} />}>
          <Route index element={<AffiliateCenter title="Active Campaigns" />} />
          <Route path="paused" element={<Campaigns title="Paused Campaigns" />} />
          <Route path="drafts" element={<Campaigns title="Drafts Campaigns" />} />
          <Route path="scheduled" element={<Campaigns title="Scheduled Campaigns" />} />
          <Route path="shared" element={<Campaigns title="Shared Campaigns" />} />
          <Route path="add" element={<NewCampaign title="New campaigns" />} />
        </Route>

        <Route path="/:lang/team" element={isAuthorized ? <DashboardContainer {...sharedProps} /> : <Navigate to={`/${LANGUAGE}/login`} />}>
          <Route index element={<ExploreCreators title="Active Campaigns" />} />
          <Route path="paused" element={<Campaigns title="Paused Campaigns" />} />
          <Route path="drafts" element={<Campaigns title="Drafts Campaigns" />} />
          <Route path="scheduled" element={<Campaigns title="Scheduled Campaigns" />} />
          <Route path="shared" element={<Campaigns title="Shared Campaigns" />} />
          <Route path="add" element={<NewCampaign title="New campaigns" />} />
        </Route>

        <Route path="/:lang/inbox" element={isAuthorized ? <DashboardContainer {...sharedProps} /> : <Navigate to={`/${LANGUAGE}/login`} />}>
          <Route index element={<MessageCenter title="Active Campaigns" />} />
          <Route path="paused" element={<Campaigns title="Paused Campaigns" />} />
          <Route path="drafts" element={<Campaigns title="Drafts Campaigns" />} />
          <Route path="scheduled" element={<Campaigns title="Scheduled Campaigns" />} />
          <Route path="shared" element={<Campaigns title="Shared Campaigns" />} />
          <Route path="add" element={<NewCampaign title="New campaigns" />} />
        </Route>

        <Route path="/:lang/notifications" element={isAuthorized ? <DashboardContainer {...sharedProps} /> : <Navigate to={`/${LANGUAGE}/login`} />}>
          <Route index element={<Notification title="Active Campaigns" />} />
          <Route path="paused" element={<Campaigns title="Paused Campaigns" />} />
          <Route path="drafts" element={<Campaigns title="Drafts Campaigns" />} />
          <Route path="scheduled" element={<Campaigns title="Scheduled Campaigns" />} />
          <Route path="shared" element={<Campaigns title="Shared Campaigns" />} />
          <Route path="add" element={<NewCampaign title="New campaigns" />} />
        </Route>

        <Route path="/:lang/settings" element={isAuthorized ? <DashboardContainer {...sharedProps} /> : <Navigate to={`/${LANGUAGE}/login`} />}>
          <Route index element={<Settings title="Settings" {...sharedProps} />} />
          <Route path="paused" element={<Campaigns title="Paused Campaigns" />} />
          <Route path="drafts" element={<Campaigns title="Drafts Campaigns" />} />
          <Route path="scheduled" element={<Campaigns title="Scheduled Campaigns" />} />
          <Route path="shared" element={<Campaigns title="Shared Campaigns" />} />
          <Route path="add" element={<NewCampaign title="New campaigns" />} />
        </Route>

        {/* <Route exact path="/:lang/login" render={(props) => <PublicContainer {...sharedProps} {...props} />} />
          <Route exact path="/:lang/signup" render={(props) => <PublicContainer {...sharedProps} {...props} />} />
          <Route exact path="/:lang/recovery" render={(props) => <PublicContainer {...sharedProps} {...props} />} />
          <Route exact path="/:lang/logout" render={(props) => <PublicContainer {...sharedProps} {...props} />} />

          <Route exact path="/:lang/flow" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />

          <Route exact path="/:lang/dashboard" render={(props) => <DashboardContainer {...sharedProps} {...props} />} />

          <Route exact path="/:lang/settings" render={(props) => <DashboardContainer {...sharedProps} {...props} />} /> */}

      </Route>

      {/*        
        <Route component={() => { return (<h1>Error</h1>) }} /> */}

    </Routes>


    // <Routes>
    //   <Route index path="/" element={<Home {...sharedProps} />} />
    //   <Route path="/en" element={<Home {...sharedProps} />} />
    //   <Route path="/it" element={<Home {...sharedProps} />} />
    //   <Route path="/fr" element={<Home {...sharedProps} />} />
    //   <Route path="/es" element={<Home {...sharedProps} />} />
    //   <Route path="/:lang/login" element={<SignIn {...sharedProps} />} />
    //   <Route path="/:lang/signup" element={<SignUp {...sharedProps} />} />
    //   <Route path="pagelist" element={<PageList {...sharedProps} />} />

    //   <Route
    //     path="/:lang/dashboard"
    //     element={
    //       JWT ? (
    //         <Page title="Dashboard" {...sharedProps}>
    //           <Dashboard />
    //         </Page>
    //       ) : (
    //         <Navigate to={`/${LANGUAGE}/login`} />
    //       )
    //     }
    //   />

    //   {/** Campaigns **/}
    //   <Route
    //     path="/:lang/campaigns/paid"
    //     element={
    //       JWT ? (
    //         <Page title="Paid Campaigns" {...sharedProps}>
    //           <ProductsDashboard />
    //         </Page>
    //       ) : (
    //         <Navigate to={`/${LANGUAGE}/login`} />
    //       )
    //     }
    //   />
    //   <Route
    //     path="/:lang/campaigns/organic"
    //     element={
    //       JWT ? (
    //         <Page title="Organic Campaigns" {...sharedProps}>
    //           <ProductsDashboard />
    //         </Page>
    //       ) : (
    //         <Navigate to={`/${LANGUAGE}/login`} />
    //       )
    //     }
    //   />
    //   <Route
    //     path="/:lang/campaigns/add"
    //     element={
    //       JWT ? (
    //         <Page title="New campaign" {...sharedProps}>
    //           <NewProduct />
    //         </Page>
    //       ) : (
    //         <Navigate to={`/${LANGUAGE}/login`} />
    //       )
    //     }
    //   />


    //   {/** Builder **/}
    //   <Route
    //     path="/:lang/builder/dashboard"
    //     element={
    //       <Page title="Drafts">
    //         <Drafts />
    //       </Page>
    //     }
    //   />
    //   <Route
    //     path="creatives/drafts"
    //     element={
    //       <Page title="Drafts">
    //         <Drafts />
    //       </Page>
    //     }
    //   />
    //   <Route
    //     path="creatives/released"
    //     element={
    //       <Page title="Released">
    //         <Released />
    //       </Page>
    //     }
    //   />
    //   <Route
    //     path="creatives/comments"
    //     element={
    //       <Page title="Comments">
    //         <Comments />
    //       </Page>
    //     }
    //   />
    //   <Route
    //     path="creatives/scheduled"
    //     element={
    //       <Page title="Scheduled">
    //         <Scheduled />
    //       </Page>
    //     }
    //   />

    //   {/** Others **/}
    //   <Route
    //     path="/:lang/schedules"
    //     element={
    //       <Page title="Schedules">
    //         <ProductsDashboard />
    //       </Page>
    //     }
    //   />
    //   <Route
    //     path="/:lang/analytics"
    //     element={
    //       <Page title="Analytics">
    //         <Promote />
    //       </Page>
    //     }
    //   />
    //   <Route
    //     path="projects/add"
    //     element={
    //       <Page title="New project">
    //         <NewProduct />
    //       </Page>
    //     }
    //   />

    //   {/** Projects **/}
    //   <Route
    //     path="projects/me"
    //     element={
    //       <Page title="My Projects">
    //         <ProductsDashboard />
    //       </Page>
    //     }
    //   />
    //   <Route
    //     path="projects/shared"
    //     element={
    //       <Page title="Shared with me">
    //         <ProductsDashboard />
    //       </Page>
    //     }
    //   />
    //   <Route
    //     path="projects/add"
    //     element={
    //       <Page title="New project">
    //         <NewProduct />
    //       </Page>
    //     }
    //   />

    //   {/** Products **/}
    //   <Route
    //     path="products/dashboard"
    //     element={
    //       <Page title="Drafts">
    //         <Drafts />
    //       </Page>
    //     }
    //   />
    //   <Route
    //     path="products/drafts"
    //     element={
    //       <Page title="Drafts">
    //         <Drafts />
    //       </Page>
    //     }
    //   />
    //   <Route
    //     path="products/released"
    //     element={
    //       <Page title="Released">
    //         <Released />
    //       </Page>
    //     }
    //   />
    //   <Route
    //     path="products/comments"
    //     element={
    //       <Page title="Comments">
    //         <Comments />
    //       </Page>
    //     }
    //   />
    //   <Route
    //     path="products/scheduled"
    //     element={
    //       <Page title="Scheduled">
    //         <Scheduled />
    //       </Page>
    //     }
    //   />

    //   {/** Customers **/}
    //   <Route
    //     path="customers/overview"
    //     element={
    //       <Page title="Customers">
    //         <Customers />
    //       </Page>
    //     }
    //   />
    //   <Route
    //     path="customers/customer-list"
    //     element={
    //       <Page title="Customer list">
    //         <CustomerList />
    //       </Page>
    //     }
    //   />

    //   {/** Shop **/}
    //   <Route
    //     path="shop"
    //     element={
    //       <Page wide>
    //         <Shop />
    //       </Page>
    //     }
    //   />
    //   <Route
    //     path="income/earning"
    //     element={
    //       <Page title="Earning">
    //         <Earning />
    //       </Page>
    //     }
    //   />
    //   <Route
    //     path="income/refunds"
    //     element={
    //       <Page title="Refunds">
    //         <Refunds />
    //       </Page>
    //     }
    //   />
    //   <Route
    //     path="income/payouts"
    //     element={
    //       <Page title="Payouts">
    //         <Payouts />
    //       </Page>
    //     }
    //   />
    //   <Route
    //     path="income/statements"
    //     element={
    //       <Page title="Statements">
    //         <Statements />
    //       </Page>
    //     }
    //   />
    //   <Route
    //     path="promote"
    //     element={
    //       <Page title="Promote">
    //         <Promote />
    //       </Page>
    //     }
    //   />
    //   <Route
    //     path="notification"
    //     element={
    //       <Page title="Notification">
    //         <Notification />
    //       </Page>
    //     }
    //   />
    //   <Route
    //     path="/:lang/settings"
    //     element={
    //       <Page title="Settings">
    //         <Settings />
    //       </Page>
    //     }
    //   />
    //   <Route
    //     path="upgrade-to-pro"
    //     element={
    //       <Page title="Upgrade to Pro">
    //         <UpgradeToPro />
    //       </Page>
    //     }
    //   />
    //   <Route
    //     path="message-center"
    //     element={
    //       <Page title="Message center">
    //         <MessageCenter />
    //       </Page>
    //     }
    //   />
    //   <Route
    //     path="explore-creators"
    //     element={
    //       <Page title="Explore creators">
    //         <ExploreCreators />
    //       </Page>
    //     }
    //   />
    //   <Route
    //     path="affiliate-center"
    //     element={
    //       <Page title="Affiliate center">
    //         <AffiliateCenter />
    //       </Page>
    //     }
    //   />
    // </Routes>

  )
}

const DashboardContainer = (props) => {

  const { USER } = props;

  useEffect(() => {
    console.log("Here ===> ", props);
  }, []);

  if(!USER){
    return (
      <Page {...props}>
        <div className="w-full h-full flex flex-col items-start justify-start pt-[60px]">
          <div className='w-full h-[50vh] flex flex-col items-center justify-center'>
            <div className='bocads-circle'>
              <img src="/images/logo-dark.png" alt="Bocads" className="w-[100px] h-[100px] animate-bounce" />
            </div>
            <div className="text-lg">Loading...</div>
          </div>
        </div>
      </Page>
    )
  }
  


  return (
    <Page {...props}>
      <Outlet />
    </Page>
  );
}


const onTranslate = (language) => {

  if (language === 'it') {
    return LANG_IT;
  }
  else if (language === 'en') {
    return LANG_EN;
  }
  else if (language === 'fr') {
    return LANG_FR;
  }
  else if (language === 'es') {
    return LANG_ES;
  }
  else {
    return LANG_EN
  }

}

export default App