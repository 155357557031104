import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Shop.module.sass";
import Card from "../../components/Card";
import BrandItem from "./BrandItem";

import Icon from "../../components/Icon";
import { Link } from "react-router-dom";
import { postApiPrivate } from "../../api";

const BrandsList = (props) => {

  const {LANGUAGE} = props;

  const [isLoading, setLoading] = useState(false);
  const [Brands, setBrands] = useState([]);

  useEffect(()=> {
    onFetchBrands()
  }, [])

  const onFetchBrands = async() => {

    const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

    try{
      let DATA_RESPONSE = await postApiPrivate('/brands/all', {}, JWT);
   
      if(!DATA_RESPONSE){
        setLoading(false);
        setBrands([]);
        return 
      }

      setLoading(false);
      setBrands(DATA_RESPONSE.data || []);
    }
    catch(e){

    }
  }

  const sharedProps = {
    ...props
  }

  return (
    <>
      <div className={styles.shop}>

        <div className="w-full flex items-center justify-between pt-10 px-4 pb-6">
          <h1 className="text-4xl">Brands</h1>
          <Link className={cn("button", styles.button)} to={`/${LANGUAGE}/brands/add`}>
            <Icon name="add" size="24" />
            <span>New Brand</span>
          </Link>
        </div>
        
        <Card className={styles.card}>
         
          <div className={styles.wrap}>
           
            {isLoading ? (
              <div className={styles.wrapper}>
                <div className='bocads-circle'>
                  <img src="/images/logo-dark.png" alt="Bocads" className="w-[100px] h-[100px] animate-bounce" />
                </div>
                <div className="text-lg">Loading...</div>
              </div>
            ) : (
              <>
                  <div className={styles.followers}>
                    {Brands.map((x, index) => (
                      <BrandItem
                        {...sharedProps}
                        className={styles.follower}
                        item={x}
                        key={index}
                        
                      />
                    ))}
                  </div>
                  <div className={styles.foot}>
                    <button
                      className={cn("button-stroke button-small", styles.button)}
                    >
                      Load more
                    </button>
                  </div>
              </>
            )}
       
          </div>
        </Card>
      </div>
    </>
  );
};

export default BrandsList;
