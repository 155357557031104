import React, { useState } from "react";
import cn from "classnames";
import styles from "./Page.module.sass";
import Sidebar from "../Sidebar";
import Header from "../Header";

const Page = (props) => {

    let { wide, children } = props;

    const [visible, setVisible] = useState(false);

    return (
        <>
            <div className={styles.page}>
                <Sidebar
                    {...props}
                    className={cn(styles.sidebar, {
                        [styles.visible]: visible,
                    })}
                    onClose={() => setVisible(false)}
                />
                <Header {...props} onOpen={() => setVisible(true)} />
                <div className={styles.inner}>
                    <div
                        className={cn(styles.container, {
                            [styles.wide]: wide,
                        })}
                    >
                        {/* {title && (
                            <div className={cn("h3", styles.title)}>
                                {title}
                            </div>
                        )} */}
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Page;
