import React, { useEffect, useState } from "react";
import styles from "./BrandsAdd.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Modal from "../../components/Modal";
import Schedule from "../../components/Schedule";
import NameAndDescription from "./NameAndDescription";
import ImagesAndCTA from "./ImagesAndCTA";
import Price from "./Price";
import CategoryAndAttibutes from "./CategoryAndAttibutes";
import ProductFiles from "./ProductFiles";
import Discussion from "./Discussion";
import Preview from "./Preview";
import Panel from "./Panel";
import Icon from "../../components/Icon";
import classNames from "classnames";

import swal from "sweetalert";
import PickerImage from "../General/PickerImage";

const BrandsAdd = (props) => {
    const [visiblePreview, setVisiblePreview] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);

    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());

    //Brand Creation
    const [brandName, setBrandName] = useState('');
    const [brandDescription, setBrandDescription] = useState('');
    const [brandCategory, setBrandCategory] = useState('');
    const [brandLogo, setBrandLogo] = useState(null);
    const [brandIcon, setBrandIcon] = useState(null);
    const [primaryColor, setPrimaryColor] = useState('');
    const [secondaryColor, setSecondaryColor] = useState('');

    const [isUploadLogo, setUploadLogo] = useState(false);


    // Function to handle form submission
    const onSelectImage = (logo) => {
        setBrandLogo(logo);
    };


    const sharedProps = {
        ...props,
        brandName,
        brandCategory,
        brandDescription,
        brandLogo,
        brandIcon,
        primaryColor,
        secondaryColor,

        setBrandName,
        setBrandDescription,
        setBrandLogo,
        setBrandCategory,
        setBrandIcon,
        setPrimaryColor,
        setSecondaryColor,

        onSelectImage,
        isUploadLogo,
        setUploadLogo
    }

    return (
        <>
            <div className="w-full flex items-center justify-between pt-10 px-4 pb-6">
                <h1 className="text-4xl">Create Brand</h1>
                {/* <div className={classNames("button", styles.button)}>
                    <Icon name="add" size="24" />
                    <span>New Brand</span>
                </div> */}
            </div>
            <div className={styles.row}>
                <div className={styles.col}>
                    <NameAndDescription {...sharedProps} className={styles.card} />
                </div>
                <div className={styles.col}>
                    <ProductFiles {...sharedProps} className={styles.card} />
                </div>
            </div>
            <Panel
                {...sharedProps}
                setVisiblePreview={setVisiblePreview}
                setVisibleSchedule={setVisibleModal}
            />
            <TooltipGlodal />
            <Modal
                visible={visibleModal}
                onClose={() => setVisibleModal(false)}
            >
                <Schedule
                    startDate={startDate}
                    setStartDate={setStartDate}
                    startTime={startTime}
                    setStartTime={setStartTime}
                />
            </Modal>

            {isUploadLogo && (
                 <PickerImage {...props}
                    onClose={() => setUploadLogo(false)}
                    onSelectImage={onSelectImage}
                    TYPE={"logo"}
                />
            )}
        </>
    );
};

export default BrandsAdd;
