export const products = [
  {
    id: 0,
    product: "Bento Matte 3D Illustration",
    link: "bocads.com/product/product-link",
    image: "/posts/Instagram Post Pattaya 1.jpg",
    image2x: "/posts/Instagram Post Pattaya 1.jpg",
    price: 98,
    date: "Apr 3, 2021 at 3:55 PM",
    ratingValue: 4.8,
    ratingCounter: 87,
  },
  {
    id: 1,
    product: "Node - Crypto iOS UI design kit",
    link: "bocads.com/product/product-link",
    image: "/posts/Instagram Post Pattaya 2.jpg",
    image2x: "/posts/Instagram Post Pattaya 2.jpg",
    price: 123,
    date: "Apr 6, 2021 at 6:55 PM",
    ratingValue: 4.9,
    ratingCounter: 123,
  },
  {
    id: 2,
    product: "TaskEz: Productivity App iOS UI Kit",
    link: "bocads.com/product/product-link",
    image: "/posts/Instagram Post Pattaya 3.jpg",
    image2x: "/posts/Instagram Post Pattaya 3.jpg",
    price: 0,
    date: "Apr 10, 2021 at 10:55 PM",
  },
  {
    id: 3,
    product: "Fleet - Travel shopping UI design kit",
    link: "bocads.com/product/product-link",
    image: "/posts/Instagram Post Pattaya 4.jpg",
    image2x: "/posts/Instagram Post Pattaya 4.jpg",
    price: 43,
    date: "Apr 12, 2021 at 12:55 PM",
    ratingValue: 4.6,
    ratingCounter: 12,
  },
  {
    id: 4,
    product: "Academe 3D Education Icons",
    link: "bocads.com/product/product-link",
    image: "/posts/Instagram Post Pattaya 5.jpg",
    image2x: "/posts/Instagram Post Pattaya 5.jpg",
    price: 8,
    date: "Apr 19, 2021 at 19:55 PM",
    ratingValue: 3.2,
    ratingCounter: 159,
  },
  {
    id: 5,
    product: "Fleet - Travel shopping UI design kit",
    link: "bocads.com/product/product-link",
    image: "/posts/Instagram Post Pattaya 6.jpg",
    image2x: "/posts/Instagram Post Pattaya 6.jpg",
    price: 198,
    date: "Apr 23, 2021 at 23:55 PM",
    ratingValue: 4.4,
    ratingCounter: 22,
  },
  {
    id: 6,
    product: "Fleet - Travel shopping UI design kit",
    link: "bocads.com/product/product-link",
    image: "/posts/Instagram Post Pattaya 7.jpg",
    image2x: "/posts/Instagram Post Pattaya 7.jpg",
    price: 198,
    date: "Apr 23, 2021 at 23:55 PM",
    ratingValue: 4.4,
    ratingCounter: 22,
  },
  {
    id: 7,
    product: "Fleet - Travel shopping UI design kit",
    link: "bocads.com/product/product-link",
    image: "/posts/Instagram Post Pattaya 8.jpg",
    image2x: "/posts/Instagram Post Pattaya 8.jpg",
    price: 198,
    date: "Apr 23, 2021 at 23:55 PM",
    ratingValue: 4.4,
    ratingCounter: 22,
  },
  {
    id: 8,
    product: "Fleet - Travel shopping UI design kit",
    link: "bocads.com/product/product-link",
    image: "/posts/Instagram Post Pattaya 9.jpg",
    image2x: "/posts/Instagram Post Pattaya 9.jpg",
    price: 198,
    date: "Apr 23, 2021 at 23:55 PM",
    ratingValue: 4.4,
    ratingCounter: 22,
  },
  {
    id: 9,
    product: "Fleet - Travel shopping UI design kit",
    link: "bocads.com/product/product-link",
    image: "/posts/Instagram Post Pattaya 10.jpg",
    image2x: "/posts/Instagram Post Pattaya 10.jpg",
    price: 198,
    date: "Apr 23, 2021 at 23:55 PM",
    ratingValue: 4.4,
    ratingCounter: 22,
  },
  {
    id: 10,
    product: "Fleet - Travel shopping UI design kit",
    link: "bocads.com/product/product-link",
    image: "/posts/Instagram Post Pattaya 11.jpg",
    image2x: "/posts/Instagram Post Pattaya 11.jpg",
    price: 198,
    date: "Apr 23, 2021 at 23:55 PM",
    ratingValue: 4.4,
    ratingCounter: 22,
  },
  {
    id: 11,
    product: "Fleet - Travel shopping UI design kit",
    link: "bocads.com/product/product-link",
    image: "/posts/Instagram Post Pattaya 12.jpg",
    image2x: "/posts/Instagram Post Pattaya 12.jpg",
    price: 198,
    date: "Apr 23, 2021 at 23:55 PM",
    ratingValue: 4.4,
    ratingCounter: 22,
  },
  {
    id: 12,
    product: "Fleet - Travel shopping UI design kit",
    link: "bocads.com/product/product-link",
    image: "/posts/Instagram Post Pattaya 13.jpg",
    image2x: "/posts/Instagram Post Pattaya 13.jpg",
    price: 198,
    date: "Apr 23, 2021 at 23:55 PM",
    ratingValue: 4.4,
    ratingCounter: 22,
  },
  {
    id: 12,
    product: "Fleet - Travel shopping UI design kit",
    link: "bocads.com/product/product-link",
    image: "/posts/Instagram Post Pattaya 14.jpg",
    image2x: "/posts/Instagram Post Pattaya 14.jpg",
    price: 198,
    date: "Apr 23, 2021 at 23:55 PM",
    ratingValue: 4.4,
    ratingCounter: 22,
  },
  {
    id: 14,
    product: "Fleet - Travel shopping UI design kit",
    link: "bocads.com/product/product-link",
    image: "/posts/Instagram Post Pattaya 15.jpg",
    image2x: "/posts/Instagram Post Pattaya 15.jpg",
    price: 198,
    date: "Apr 23, 2021 at 23:55 PM",
    ratingValue: 4.4,
    ratingCounter: 22,
  },
  {
    id: 15,
    product: "Fleet - Travel shopping UI design kit",
    link: "bocads.com/product/product-link",
    image: "/posts/Instagram Post Pattaya 16.jpg",
    image2x: "/posts/Instagram Post Pattaya 16.jpg",
    price: 198,
    date: "Apr 23, 2021 at 23:55 PM",
    ratingValue: 4.4,
    ratingCounter: 22,
  },
  {
    id: 16,
    product: "Fleet - Travel shopping UI design kit",
    link: "bocads.com/product/product-link",
    image: "/posts/Instagram Post Pattaya 17.jpg",
    image2x: "/posts/Instagram Post Pattaya 17.jpg",
    price: 198,
    date: "Apr 23, 2021 at 23:55 PM",
    ratingValue: 4.4,
    ratingCounter: 22,
  },
  {
    id: 17,
    product: "Fleet - Travel shopping UI design kit",
    link: "bocads.com/product/product-link",
    image: "/posts/Instagram Post Pattaya 18.jpg",
    image2x: "/posts/Instagram Post Pattaya 18.jpg",
    price: 198,
    date: "Apr 23, 2021 at 23:55 PM",
    ratingValue: 4.4,
    ratingCounter: 22,
  },
  {
    id: 18,
    product: "Fleet - Travel shopping UI design kit",
    link: "bocads.com/product/product-link",
    image: "/posts/Instagram Post Pattaya 19.jpg",
    image2x: "/posts/Instagram Post Pattaya 19.jpg",
    price: 198,
    date: "Apr 23, 2021 at 23:55 PM",
    ratingValue: 4.4,
    ratingCounter: 22,
  },
  {
    id: 19,
    product: "Fleet - Travel shopping UI design kit",
    link: "bocads.com/product/product-link",
    image: "/posts/Instagram Post Pattaya 20.jpg",
    image2x: "/posts/Instagram Post Pattaya 20.jpg",
    price: 198,
    date: "Apr 23, 2021 at 23:55 PM",
    ratingValue: 4.4,
    ratingCounter: 22,
  },
  {
    id: 20,
    product: "Fleet - Travel shopping UI design kit",
    link: "bocads.com/product/product-link",
    image: "/posts/Instagram Post Pattaya 21.jpg",
    image2x: "/posts/Instagram Post Pattaya 21.jpg",
    price: 198,
    date: "Apr 23, 2021 at 23:55 PM",
    ratingValue: 4.4,
    ratingCounter: 22,
  },
];
