import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./SignIn.module.sass";
import { use100vh } from "react-div-100vh";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TextInput from "../../components/TextInput";
import Image from "../../components/Image";

import { GetRouteSearchParams } from "../../helpers/GeneralHelper";

import axios from 'axios';

import { useGoogleLogin } from '@react-oauth/google';

import swal from 'sweetalert';
import { API_ROOT } from "../../api";


const SignIn = (props) => {

  const heightWindow = use100vh();

  const { TRANSLATE, LANGUAGE, history, onStartUser } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const [EMAIL, setEmail] = useState('');
  const [PASSWORD, setPassword] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [isGoogling, setGoogling] = useState(false);

  useEffect(() => {

    const routeParams = GetRouteSearchParams(location.search);
    const routeCode = routeParams.code || '';

    if (routeCode) {
      //alert('The Code is ===> '+routeCode);
      if (!isGoogling) {
        setGoogling(true);
        onLoginGoogleFlow(routeCode);
      }
      else {
        setGoogling(false);
      }

    }

    console.log("HERE ===> ")

  }, [])

  const onKeyUp = (event) => {

    event.preventDefault();


    if (event.keyCode === 13) {

      event.target.blur();
      onLogin();

    }
  }

  const onLogin = () => {

    const routeParams = GetRouteSearchParams(location.search);
    const routeNext = routeParams.next || '';

    if (!isValidEmail(EMAIL)) {
      return swal(TRANSLATE.wd_login, TRANSLATE.error_invalid_email, 'warning')
    }

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    var form = {
      email: EMAIL.trim(),
      password: PASSWORD.trim(),
      lang: LANGUAGE,
      timezone: userTimezone

    }

    setLoading(true);

    axios.post(`${API_ROOT}/users/login`, form).then(response => {

      setLoading(false);

      localStorage.setItem('jwt_token', response.data.jwt_token);

      const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
      if (isNativeApp) {
        const webData = {
          type: 'LOGIN',
          data: response.data.jwt_token
        }
        window.ReactNativeWebView.postMessage(JSON.stringify(webData));
      }

      // return swal('You\'re logged successfully')

      onStartUser();

      if (routeNext) {
        navigate(routeNext);
        return
      }

      navigate(`/${LANGUAGE}/settings`);

    })
      .catch(error => {

        setLoading(false);

        navigate(`/${LANGUAGE}/login`);

        if (!error || !error.response) {
          return swal('Login', TRANSLATE.error_invalid_credentials, 'warning');
        }

        var error_data = error.response.data;
        var error_message = error_data.error;

        if (error_message === 'missing_password') {
          return swal('Login', TRANSLATE.error_missing_password, 'warning');
        }
        else if (error_message === 'missing_email') {
          return swal('Login', TRANSLATE.error_invalid_email, 'warning');
        }

        return swal('Login', TRANSLATE.error_invalid_credentials, 'warning');
      });
  }

  const onLoginGoogle = async (params) => {

    const routeParams = GetRouteSearchParams(location.search);
    const routeNext = routeParams.next || '';

    if (!params) {
      return swal(TRANSLATE.wd_login, TRANSLATE.error_invalid_email)
    }

    setLoading(true);

    if (params.access_token) {
      const Response = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo?access_token=" + params.access_token);
      const ResponseData = Response ? Response.data : {};
      params = {
        ...params,
        ...ResponseData
      }
    }

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    var FORM = {
      lang: LANGUAGE,
      oauth: params,
      timezone: userTimezone

    }

    axios.post(`${API_ROOT}/users/login/google`, FORM).then(response => {


      setLoading(false);

      localStorage.setItem('jwt_token', response.data.jwt_token);

      const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
      if (isNativeApp) {
        const webData = {
          type: 'LOGIN',
          data: response.data.jwt_token
        }
        window.ReactNativeWebView.postMessage(JSON.stringify(webData));
      }

      onStartUser();

      if (routeNext) {
        navigate(routeNext);
        return
      }

      navigate(`/${LANGUAGE}/settings`);

    })
      .catch(error => {

        setLoading(false);

        //  navigate(`/${LANGUAGE}/login`);

        if (!error || !error.response) {
          return swal('Login', TRANSLATE.error_invalid_credentials, 'warning');
        }

        var error_data = error.response.data;
        var error_message = error_data.error;

        if (error_message === 'missing_password') {
          return swal('Login', TRANSLATE.error_missing_password, 'warning');
        }
        else if (error_message === 'missing_email') {
          return swal('Login', TRANSLATE.error_invalid_email, 'warning');
        }

        return swal('Login', TRANSLATE.error_invalid_credentials, 'warning');
      });
  }

  const onLoginGoogleFlow = async (authCode) => {

    const isLocalhost = window.location.href.includes('localhost:3000');

    try {

      setLoading(true);

      const clientId = '207376755549-5ve7lerk1hcd5aq6dalnr0mmagcas46j.apps.googleusercontent.com';
      const clientSecret = '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';
      const redirectUri = isLocalhost ? 'http://localhost:3000/en/login' : 'https://www.bocads.com/en/login';

      const tokenEndpoint = 'https://oauth2.googleapis.com/token';
      const userDataEndpoint = 'https://www.googleapis.com/oauth2/v3/userinfo';

      const data = new URLSearchParams();
      data.append('code', authCode);
      data.append('client_id', clientId);
      data.append('client_secret', clientSecret);
      data.append('redirect_uri', redirectUri);
      data.append('grant_type', 'authorization_code');

      // Step 1: Exchange auth code for access token and refresh token
      const tokenResponse = await axios.post(tokenEndpoint, data,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );

      //return swal('success', JSON.stringify(tokenResponse, null, 2), 'success');


      const tokenData = tokenResponse.data;


      // Step 2: Fetch user data using access token
      const userDataResponse = await fetch(userDataEndpoint, {
        headers: {
          Authorization: `Bearer ${tokenData.access_token}`
        }
      });

      const userData = await userDataResponse.json();

      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;


      var FORM = {
        lang: LANGUAGE,
        oauth: {
          ...userData,
          accessToken: tokenData.access_token,
          refreshToken: tokenData.refresh_token,
          picture: userData.picture ? userData.picture.replace('s96', 's512') : ''

        },
        timezone: userTimezone

      }

      //return swal('success', JSON.stringify(FORM, null, 2), 'success');

      const response = await axios.post(`${API_ROOT}/users/login/google`, FORM);

      if (!response) {
        return
      }

      setLoading(false);

      localStorage.setItem('jwt_token', response.data.jwt_token);

      const isNativeApp = (typeof global.window !== 'undefined') ? window.isNativeApp : null;
      if (isNativeApp) {
        const webData = {
          type: 'LOGIN',
          data: response.data.jwt_token
        }
        window.ReactNativeWebView.postMessage(JSON.stringify(webData));
      }

      onStartUser();

      navigate(`/${LANGUAGE}/settings`);


    }
    catch (error) {
      setLoading(false);
      // navigate(`/${LANGUAGE}/login`);
      if (!error || !error.response) {
        return swal('Login', TRANSLATE.error_invalid_credentials, 'warning');
      }

      var error_data = error.response.data;
      var error_message = error_data.error;

      if (error_message === 'missing_password') {
        return swal('Login', TRANSLATE.error_missing_password, 'warning');
      }
      else if (error_message === 'missing_email') {
        return swal('Login', TRANSLATE.error_invalid_email, 'warning');
      }

      return swal('Login', TRANSLATE.error_invalid_credentials, 'warning');

    }

  }

  const routeParams = GetRouteSearchParams(location.search);
  const isCode = routeParams.code || '';

  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      {isCode ? (
        <div className={styles.wrapper}>
          <div className='bocads-circle'>
            <img src="/images/logo-dark.png" alt="Bocads" className="w-[100px] h-[100px] animate-bounce" />
          </div>
          <div className="text-lg">Loading...</div>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <Link className={styles.logo} to={`/${LANGUAGE}`} style={{ width: 120 }}>
            <Image
              className={styles.pic}
              src="/images/logo-dark-full.png"
              srcDark="/images/logo-light-full.png"
              alt="Bocads"
            />
          </Link>
          <div className={cn("h2", styles.title)}>Sign in</div>
          <div className={styles.head}>
            <div className={styles.subtitle}>Sign up with Open account</div>
            <div className={styles.btns}>
              <GoogleLoginComponent {...props} onLoginGoogle={onLoginGoogle} />

              <button className={cn("button-stroke", styles.button)}>
                <Image
                  className={styles.pic}
                  src="/images/content/apple-dark.svg"
                  srcDark="/images/content/apple-light.svg"
                  alt="Apple"
                />
                Apple ID
              </button>
            </div>
          </div>
          <div className={styles.body}>
            <div className={styles.subtitle}>Or continue with email address</div>
            <TextInput
              className={styles.field}
              name="email"
              type="email"
              placeholder="Your email"
              required
              icon="mail"
            />
            <TextInput
              className={styles.field}
              name="password"
              type="password"
              placeholder="Password"
              required
              icon="lock"
            />
            <button className={cn("button", styles.button)} onClick={() => onLogin()}>
              Sign in
            </button>
            <div className={styles.note}>
              This site is protected by reCAPTCHA and the Google Privacy Policy.
            </div>
            <div className={styles.info}>
              Don’t have an account?{" "}
              <Link className={styles.link} to={`/${LANGUAGE}/signup`}>
                Sign up
              </Link>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

const isValidEmail = (email) => {

  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);

}


const GoogleLoginComponent = (props) => {

  const { onLoginGoogle } = props;

  const isLocalhost3000 = window.location.href.includes('localhost:3000');
  const isLocalhost4000 = window.location.href.includes('localhost:4000');

  let redirect_uri = 'https://www.bocads.com/en/login';

  if (isLocalhost3000) {
    redirect_uri = 'http://localhost:3000/en/login';
  }

  if (isLocalhost4000) {
    redirect_uri = 'http://localhost:4000/en/login';
  }


  const login = useGoogleLogin({
    flow: 'auth-code',
    ux_mode: 'redirect',
    prompt: 'select_account',
    redirect_uri: redirect_uri,
    onSuccess: credentialResponse => {
      console.log(JSON.stringify(credentialResponse));
      onLoginGoogle(credentialResponse);
      return
    },
    onError: error => {
      console.log('Login Failed');
    },
  });

  return (
    <button className={cn("button-stroke", styles.button)} onClick={() => login()}>
      <img src="/images/content/google.svg" alt="Google" />
      Google
    </button>
  );
}


export default SignIn;
