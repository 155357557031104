import React, { useState } from 'react';
import { ChromePicker } from 'react-color';

import cn from "classnames";
import styles from "../../components/TextInput/TextInput.module.sass";
import Icon from "../../components/Icon";
import Tooltip from "../../components/Tooltip";

const TextColor = ({
    className,
    classLabel,
    classInput,
    label,
    icon,
    copy,
    currency,
    tooltip,
    place,
    onChange,
    value,
    ...props
  }) => {

    const [showColorPicker, setShowColorPicker] = useState(false);

    return (
      <div
        className={cn(
          styles.field,
          { [styles.fieldIcon]: icon },
          { [styles.fieldCopy]: copy },
          { [styles.fieldCurrency]: currency },
          className
        )}
      >
        {label && (
          <div className={cn(classLabel, styles.label)}>
            {label}{" "}
            {tooltip && (
              <Tooltip
                className={styles.tooltip}
                title={tooltip}
                icon="info"
                place={place ? place : "right"}
              />
            )}
          </div>
        )}
        <div className={styles.wrap}>
            <input className={cn(classInput, styles.input)} {...props} 
                    onFocus={() => setShowColorPicker(prev=> !prev)}
                    onBlur={() => setShowColorPicker(false)}
                    value={value}
                    onChange={(e)=> onChange({hex: e.target.value})}
            
            />
            {icon && (
                <div className={styles.icon}>
                <Icon name={icon} size="24" />{" "}
                </div>
            )}
            <button className={styles.copy} onClick={()=> setShowColorPicker(prev=> !prev)}>
                <div className="mt-0 w-6 h-6 rounded"
                    style={{ backgroundColor: value }}
                ></div>
            </button>
        </div>
        {showColorPicker && (
            <div className="absolute z-10">
            <ChromePicker color={value} onChange={onChange} />
            </div>
        )}
      </div>
    );
  };





export default TextColor