import axios from 'axios';

const API_SIMPLE = ''; //LIVE

//const API_SIMPLE = 'http://192.168.1.71:4000'//303

//const API_SIMPLE = 'http://192.168.100.111:7000'//TUK TULUM

//const API_SIMPLE = 'http://192.168.1.43:7000' //KARIM PARIS

//const API_SIMPLE = 'http://10.183.3.14:7000' //KUWAIT AIRPORT

//const API_SIMPLE = 'https://babonbo.herokuapp.com';

//const API_SIMPLE = 'http://192.168.178.20:7000'; //HOME
//const API_SIMPLE = 'http://192.168.1.204:7000'; //BAR PASTICERIA
//const API_SIMPLE = 'http://192.168.100.2:7000' //TIJUANA;
//const API_SIMPLE = 'http://172.19.248.186:20:7000' //LOCAL;
//const API_SIMPLE = 'http://172.16.27.125:7000' //ANTALYA;
//const API_SIMPLE = 'http://192.168.178.21:7000' //MONTEGRAPPA

//const API_SIMPLE = 'http://192.168.86.25:7000'; //TULUM NFT HOUSE

//const API_SIMPLE = 'http://172.20.10.2:7000'; //HOTSPOT

//const API_SIMPLE = 'http://192.168.1.109:7000'; //ZAHRA / MARRAKESH

//const API_SIMPLE = 'http://192.168.1.153:7000' //BELLA MARIA / CASABLANCA

//const API_SIMPLE = 'http://192.168.1.22:7000' //MANSORIA 

//const API_SIMPLE = 'http://192.168.1.109:7000' //GATOS CASABLANCA

//const API_SIMPLE = 'http://192.168.11.189:7000'// FIBRA MARRAKESH

//const API_SIMPLE = 'http://192.168.1.176:7000'// LARENA CASABLANCA

//const API_SIMPLE = 'http://172.16.75.14:7000'// KOPERATIF HOUSE / ISTANBUL 

//const API_SIMPLE = 'http://192.168.1.4:7000' // MAJO HOUSE 

//const API_SIMPLE = 'http://192.168.1.193:7000'; //GALTA TOWER

//const API_SIMPLE = 'http://192.168.2.143:7000'; //AWAY SUITES TAKSIM

//const API_SIMPLE = 'http://192.168.1.147:7000'; //MEDICASIMPLE

//const API_SIMPLE = 'http://192.168.0.111:7000'; //JANTI COFFEE

//const API_SIMPLE = 'http://192.168.0.15:7000'; //EREN'S HOUSE

//const API_SIMPLE = 'http://192.168.1.127:7000'; //DUYGU'S HOUSE

//const API_SIMPLE = 'http://10.35.35.112:7000'; //AURA TERAS

//const API_SIMPLE = 'http://192.168.43.70:7000'; //BABONBO TURKISH HS 

//const API_SIMPLE = 'http://172.20.10.7:7000'; //AHMED HS IZMIR

//const API_SIMPLE = 'http://172.20.10.9:7000'; //EREN HS

//const API_SIMPLE = 'http://192.168.1.132:7000'; //AURA BLOCK C

//const API_SIMPLE = 'http://192.168.0.181:7000'; //AILED House

//const API_SIMPLE = 'http://192.168.1.67:7000'; //CESAR HOUSE

//const API_SIMPLE = 'http://192.168.0.181:7000'; //DLA

//const API_SIMPLE = 'http://192.168.15.238:7000'; //Brew Mission Beach

//const API_SIMPLE = 'http://192.168.1.64:7000'; //Alan House

//const API_SIMPLE = 'http://192.168.0.13:7000'; //Amy's House

//const API_SIMPLE = 'http://192.168.1.71:7000'; //Hugo's House
//const API_SIMPLE = 'http://192.168.1.65:7000'; //Café Mo TJ

//const API_SIMPLE = 'http://192.168.1.100:7000'; //BEBEK

//const API_SIMPLE = 'http://192.168.0.18:7000'; //EREN HOUSE

//const API_SIMPLE = 'http://192.168.1.43:7000'; //AIR RESIDENCE MANILA

//const API_SIMPLE = 'http://192.168.1.12:7000'; //MINISUITES THOMAS POBLACION

//const API_SIMPLE = 'http://192.168.0.100:7000'; //HOTEL MORNING THOMAS BORACAY

//const API_SIMPLE = 'http://192.168.133.12:7000'; //PARADISE GARDEN BORACAY

//const API_SIMPLE = 'http://192.168.1.7:7000'; //VILLA OZ PANGALO

//const API_SIMPLE = 'http://20.20.16.238:7000'; //AQUA BORACAY

//const API_SIMPLE = 'http://192.168.0.108:7000'; //VILLA ISRAEL EL NIDO

//const API_SIMPLE = 'http://192.168.1.37:7000'; //BUMPACKCAMP EL NIDO


const API_ROOT = API_SIMPLE + '/api/v1';

const getApiPublic = async (API_URL) => {

    var API_REQUEST = API_ROOT + API_URL;

    try {

        var DATA = await axios.get(API_REQUEST);
        var DATA_RESPONSE = DATA.data;

        console.log("API GET PUBLIC SUCCESS ===> ", API_REQUEST);

        return DATA_RESPONSE;
    } catch (e) {

        console.log("API GET PUBLIC ERROR ===> ", API_REQUEST);
        return { error: e };
    }
};

const getApiPrivate = async (API_URL, JWT) => {

    var API_REQUEST = API_ROOT + API_URL;

    try {

        var DATA = await axios.get(API_REQUEST, { headers: { authorization: JWT } });
        var DATA_RESPONSE = DATA.data;

        console.log("API GET PRIVATE SUCCESS ===> ", API_REQUEST);

        return DATA_RESPONSE;
    } catch (e) {

        console.log("API GET PRIVATE ERROR ===> ", API_REQUEST);
        return { error: e };
    }
};

const postApiPublic = async (API_URL, FORM) => {

    var API_REQUEST = API_ROOT + API_URL;

    try {

        var DATA = await axios.post(API_REQUEST, FORM);
        var DATA_RESPONSE = DATA.data;

        console.log("API POST PUBLIC SUCESS ===> ", API_REQUEST);

        return DATA_RESPONSE;
    } catch (e) {

        console.log("API POST PUBLIC ERROR ===> ", API_REQUEST);
        return { error: e };
    }
};

const postApiPrivate = async (API_URL, FORM, JWT) => {

    var API_REQUEST = API_ROOT + API_URL;

    try {

        var DATA = await axios.post(API_REQUEST, FORM, { headers: { authorization: JWT } });
        var DATA_RESPONSE = DATA.data;

        console.log("API POST PRIVATE DATA ===> ", API_REQUEST);

        return DATA_RESPONSE;
    } catch (e) {

        console.log("API POST PRIVATE ERROR ===> ", API_REQUEST);
        return { error: e };
    }
};

export { getApiPublic, getApiPrivate, postApiPublic, postApiPrivate, API_ROOT, API_SIMPLE };