import React from "react";
import styles from "./BrandItem.module.sass";
import cn from "classnames";
import { Link } from "react-router-dom";

const BrandItem = ({ className, item, LANGUAGE }) => {
 
  return (
    <div className={cn(styles.follower, className)}>
      <div className={styles.details}>
        <div className={styles.avatar}>
          <img src={item.icon} alt="Avatar" className="rounded-xl" />
        </div>
        <div className={styles.wrap}>
          <div className={styles.man}>{item.name}</div>
          <div className={styles.list}>
            <div className={styles.counter}>
              <span>{parseInt(item?.projects || 0)}</span> projects
            </div>
            <div className={styles.counter}>
              <span>{parseInt(item?.teammates || 0)}</span> teammates
            </div>
          </div>
          <div className={styles.btns}>
            <Link to={`/${LANGUAGE}/brands/view/${item.id}`} className={cn("button-stroke", styles.button)}>
              Open
            </Link>

            {/* <Link
              className={cn("button", styles.button)}
              to="/message-center"
            >
              Invite 
            </Link> */}
           
          </div>
        </div>
      </div>
      <div className={styles.gallery}>
        {['','','',''].map((x, index) => (
          <div className={styles.preview} key={index}>
            <img src={`/posts/Instagram Post Pattaya ${index+1}.jpg`} alt="Product" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrandItem;
