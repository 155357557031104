import React, { useEffect, useState } from "react";
import styles from "./Sidebar.module.sass";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
import Theme from "../Theme";
import Dropdown from "./Dropdown";
import Help from "./Help";
import Image from "../Image";

const navigation = [
    {
        title: "Dashboard",
        icon: "desktop",
        url: "/dashboard",
    },
    {
        title: "Brands",
        icon: "multiselect",
        url: "/brands",
    },
    {
        title: "Projects",
        slug: "projects",
        icon: "design",
        add: true,
        dropdown: [
            {
                title: "Ad Creatives",
                url: "/projects/creatives",
                icon: "activity",
            },
            {
                title: "Ad Copy",
                url: "/projects/text",
                icon: "activity",
            },
            {
                title: "Ad Strategy",
                url: "/projects/strategy",
                counter: "2",
                colorCounter: "#FFBC99",
            }
        ],
    },
    {
        title: "Integrations",
        icon: "link",
        url: "/integrations",
    },
    {
        title: "Analytics",
        icon: "leaderboard",
        url: "/analytics",
    },

    // {
    //     title: "Campaigns",
    //     slug: "campaigns",
    //     icon: "activity",
    //     add: true,
    //     dropdown: [
    //         {
    //             title: "Active",
    //             url: "/campaigns/active",
    //             icon: "activity",
    //         },
    //         {
    //             title: "Paused",
    //             url: "/campaigns/paused",
    //             icon: "activity",
    //         },
    //         {
    //             title: "Drafts",
    //             url: "/campaigns/drafts",
    //             counter: "2",
    //             colorCounter: "#FFBC99",
    //         },
    //         {
    //             title: "Scheduled",
    //             url: "/campaigns/scheduled",
    //             counter: "8",
    //             colorCounter: "#B5E4CA",
    //         },
    //         {
    //             title: "Shared with me",
    //             url: "/campaigns/shared",
    //             counter: "2",
    //             colorCounter: "#fd6270",
    //         }
    //     ],
    // },
    // {
    //     title: "Builder",
    //     slug: "builder",
    //     icon: "design",
    //     dropdown: [
    //         {
    //             title: "Facebook Ads",
    //             url: "/builder/image",
    //             counter: "2",
    //             colorCounter: "#FFBC99",
    //         },
    //         {
    //             title: "Google Ads",
    //             url: "/builder/video",
    //         },
    //         {
    //             title: "Instagram Story",
    //             url: "/builder/scheduled",
    //             counter: "8",
    //             colorCounter: "#B5E4CA",
    //         }
    //     ],
    // },
    // {
    //     title: "Schedules",
    //     icon: "schedule",
    //     url: "/schedules",
    // },
    // {
    //     title: "Analytics",
    //     icon: "leaderboard",
    //     url: "/analytics",
    // },
    // {
    //     title: "Team",
    //     icon: "grid",
    //     url: "/team",
    // },
    {
        title: "Settings",
        icon: "setting",
        url: "/settings",
    },
    
   
    // {
    //     title: "Products",
    //     slug: "products",
    //     icon: "diamond",
    //     add: true,
    //     dropdown: [
    //         {
    //             title: "Dashboard",
    //             url: "/products/dashboard",
    //         },
    //         {
    //             title: "Drafts",
    //             url: "/products/drafts",
    //             counter: "2",
    //             colorCounter: "#FFBC99",
    //         },
    //         {
    //             title: "Released",
    //             url: "/products/released",
    //         },
    //         {
    //             title: "Comments",
    //             url: "/products/comments",
    //         },
    //         {
    //             title: "Scheduled",
    //             url: "/products/scheduled",
    //             counter: "8",
    //             colorCounter: "#B5E4CA",
    //         },
    //     ],
    // },
    // {
    //     title: "Customers",
    //     slug: "customers",
    //     icon: "profile-circle",
    //     dropdown: [
    //         {
    //             title: "Overview",
    //             url: "/customers/overview",
    //         },
    //         {
    //             title: "Customer list",
    //             url: "/customers/customer-list",
    //         },
    //     ],
    // },
    // {
    //     title: "Income",
    //     slug: "income",
    //     icon: "pie-chart",
    //     dropdown: [
    //         {
    //             title: "Earning",
    //             url: "/income/earning",
    //         },
    //         {
    //             title: "Refunds",
    //             url: "/income/refunds",
    //         },
    //         {
    //             title: "Payouts",
    //             url: "/income/payouts",
    //         },
    //         {
    //             title: "Statements",
    //             url: "/income/statements",
    //         },
    //     ],
    // },
    
];

const Sidebar = ({ className, onClose, LANGUAGE }) => {
    
    const [visibleHelp, setVisibleHelp] = useState(false);
    const [visible, setVisible] = useState(false);

    const [isSettings, setSettings] = useState(false);

    const { pathname } = useLocation();

    useEffect(()=> {
        if(pathname.includes('/settings')){
            setSettings(true);
        }
        else{
            setSettings(false);
        }
       // alert("PathName ===> "+pathname);
    }, [pathname])

    return (
        <>
            <div
                className={cn(styles.sidebar, className, {
                    [styles.active]: visible,
                })}
            >
                <button className={styles.close} onClick={onClose}>
                    <Icon name="close" size="24" />
                </button>
                <Link className={`w-[170px] mb-[48px] xl:block md:hidden block`} to="/" onClick={onClose}>
                    <Image
                        className={styles.pic}
                        src="/images/logo-dark-full.png"
                        srcDark="/images/logo-light-full.png"
                        alt="Bocads"
                        
                    />
                </Link>
                <Link className={`w-[48px] mb-[48px] xl:hidden md:block hidden`} to="/" onClick={onClose}>
                    <Image
                        className={styles.pic}
                        src="/images/logo-dark.png"
                        srcDark="/images/logo-light.png"
                        alt="Bocads"
                        
                    />
                </Link>
                <div className={styles.menu}>
                    {navigation.map((x, index) =>
                        x.url ? (
                            <NavLink
                                className={cn(styles.item, {
                                    [styles.active]: pathname.includes(x.url),
                                })}
                                to={`/${LANGUAGE}${x.url}`}
                                key={index}
                                onClick={onClose}
                            >
                                <Icon name={x.icon} size="24" />
                                {x.title}
                            </NavLink>
                        ) : (
                            <Dropdown
                                className={styles.dropdown}
                                visibleSidebar={visible}
                                setValue={setVisible}
                                key={index}
                                item={x}
                                onClose={onClose}
                                LANGUAGE={LANGUAGE}
                            />
                        )
                    )}
                </div>
                <button
                    className={styles.toggle}
                    onClick={() => setVisible(!visible)}
                >
                    <Icon name="arrow-right" size="24" />
                    <Icon name="close" size="24" />
                </button>
                <div className={styles.foot}>
                    <button
                        className={styles.link}
                        onClick={() => setVisibleHelp(true)}
                    >
                        <Icon name="help" size="24" />
                        Help & getting started
                        <div className={styles.counter}>8</div>
                    </button>
                    {isSettings && (
                    <Theme className={styles.theme} visibleSidebar={visible} />
                    )}
                </div>
            </div>
            <Help
                visible={visibleHelp}
                setVisible={setVisibleHelp}
                onClose={onClose}
            />
            
            <div
                className={cn(styles.overlay, { [styles.active]: visible })}
                onClick={() => setVisible(false)}
            ></div>
           
            
        </>
    );
};

export default Sidebar;
