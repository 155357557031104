import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";

const CLIENT_ID = '207376755549-5ve7lerk1hcd5aq6dalnr0mmagcas46j.apps.googleusercontent.com';

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <BrowserRouter>
            <GoogleOAuthProvider clientId={CLIENT_ID}>
                <App />
            </GoogleOAuthProvider>
        </BrowserRouter>
    </React.StrictMode>
);
