import momentTZ from 'moment-timezone';
import tzlookup from 'tz-lookup';

const getDistanceBetweenCoordinates = (lat1, lon1, lat2, lon2) => {
    var R = 6371; // km
    var dLat = getRadiant(lat2 - lat1);
    var dLon = getRadiant(lon2 - lon1);

    lat1 = getRadiant(lat1);
    lat2 = getRadiant(lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
};

const getRadiant = Value => {
    return Value * Math.PI / 180;
};

const onFormatUrl = (url) => {

    if (!url) {
        return url;
    }
    return url.toString()               // Convert to string
        .normalize('NFD')               // Change diacritics
        .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
        .replace(/\s+/g, '-')            // Change whitespace to dashes
        .toLowerCase()                  // Change to lowercase
        .replace(/&/g, '-and-')          // Replace ampersand
        .replace(/[^a-z0-9\-]/g, '')     // Remove anything that is not a letter, number or dash
        .replace(/-+/g, '-')             // Remove duplicate dashes
        .replace(/^-*/, '')              // Remove starting dashes
        .replace(/-*$/, '');             // Remove trailing dashes
}

const onBrowswerDeviceData = (navigator, window) => {
    var module = {
        options: [],
        header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera],
        dataos: [
            { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
            { name: 'Windows', value: 'Win', version: 'NT' },
            { name: 'iPhone', value: 'iPhone', version: 'OS' },
            { name: 'iPad', value: 'iPad', version: 'OS' },
            { name: 'Kindle', value: 'Silk', version: 'Silk' },
            { name: 'Android', value: 'Android', version: 'Android' },
            { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
            { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
            { name: 'Macintosh', value: 'Mac', version: 'OS X' },
            { name: 'Linux', value: 'Linux', version: 'rv' },
            { name: 'Palm', value: 'Palm', version: 'PalmOS' }
        ],
        databrowser: [
            { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
            { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
            { name: 'Safari', value: 'Safari', version: 'Version' },
            { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
            { name: 'Opera', value: 'Opera', version: 'Opera' },
            { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
            { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
        ],
        init: function () {
            var agent = this.header.join(' '),
                os = this.matchItem(agent, this.dataos),
                browser = this.matchItem(agent, this.databrowser);

            return { os: os, browser: browser };
        },
        matchItem: function (string, data) {
            var i = 0,
                j = 0,
                html = '',
                regex,
                regexv,
                match,
                matches,
                version;

            for (i = 0; i < data.length; i += 1) {
                regex = new RegExp(data[i].value, 'i');
                match = regex.test(string);
                if (match) {
                    regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
                    matches = string.match(regexv);
                    version = '';
                    if (matches) { if (matches[1]) { matches = matches[1]; } }
                    if (matches) {
                        matches = matches.split(/[._]+/);
                        for (j = 0; j < matches.length; j += 1) {
                            if (j === 0) {
                                version += matches[j] + '.';
                            } else {
                                version += matches[j];
                            }
                        }
                    } else {
                        version = '0';
                    }
                    return {
                        name: data[i].name,
                        version: parseFloat(version)
                    };
                }
            }
            return { name: 'unknown', version: 0 };
        }
    };

    var e = module.init();

    return {
        os: e.os.name,
        browser: e.browser.name
    };
}

const onGetCurrencySymbol = (currency, type) => {

    if (type === 'full') {

        if (currency === 'usd') {
            return '$ - USD - 🇺🇸'
        }
        else if (currency === 'gbp') {
            return '£ - GBP - 🇬🇧'
        }
        else {
            return '€ - EUR - 🇪🇺'
        }
    }
    else {
        if (currency === 'usd') {
            return '$'
        }
        else if (currency === 'gbp') {
            return '£'
        }
        else {
            return '€'
        }
    }

}

const onFormatImgUrl = (url) => {

    if (!url) {
        return ''
    }

    if (url.includes('mybabonbo.s3.amazonaws.com/')) {
        let newUrl = url.replace('mybabonbo.s3.amazonaws.com/', 'mybabonbo.s3.eu-central-1.amazonaws.com/')
        return newUrl
    }

    return url

}


const GetRouteSearchParams = (query) => {
    const routeParams = getQueryStringParams(query) || {};
    return routeParams
}

const getQueryStringParams = (query) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                let [key, value] = param.split('=');
                params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                return params;
            }, {}
            )
        : {}
};


const onFormatPrices = ({ price_day, price_day_final, duration, category, quantity }) => {

    let PriceTotalFee = parseFloat((price_day_final + (price_day_final * 15 / 100)).toFixed(2));
    let PriceDayFee = parseFloat((price_day + (price_day * 15 / 100)).toFixed(2));

    let PriceNoDiscount = parseFloat((PriceDayFee * duration).toFixed(2));
    let PriceDiscount = parseFloat((PriceNoDiscount - PriceTotalFee).toFixed(2));


    if (category === 'consumable') {
        PriceTotalFee = parseFloat(((price_day + (price_day * 15 / 100)) * (quantity || 1)).toFixed(2));
        PriceDayFee = parseFloat((price_day + (price_day * 15 / 100)).toFixed(2));
        PriceNoDiscount = parseFloat((PriceDayFee * (quantity || 1)).toFixed(2));
        PriceDiscount = 0;
    }

    return {
        Duration: duration,
        PriceTotalFee,
        PriceDayFee,
        PriceNoDiscount,
        PriceDiscount
    }
}

//Calculate Prices
const onCalculateDailyPrice = (PRODUCT, DURATION) => {

    var { price_day, price_month, price_week } = PRODUCT;

    if (DURATION < 7) {
        return price_day
    }
    else if (DURATION >= 7 && DURATION < 30) {
        var PRICE_WEEK_DAILY = price_week / 7;
        return PRICE_WEEK_DAILY
    }
    else if (DURATION >= 30) {
        var PRICE_MONTH_DAILY = price_month / 30;
        return PRICE_MONTH_DAILY
    }

}


const numberWithCommas = (x, CURRENCY) => {
    if (isNaN(x)) return x;

    return (x).toLocaleString('en-US', {
        style: 'currency',
        currency: CURRENCY,
    });

}

const onFetchLanguage = (lang) => {

    if (lang === 'en') {
        return 'ENG'
    }
    else if (lang === 'it') {
        return 'IT'
    }
    else if (lang === 'fr') {
        return 'FR'
    }
    else if (lang === 'es') {
        return 'ES'
    }
    else if (lang === 'de') {
        return 'DE'
    }
    else if (lang === 'ar') {
        return 'AR'
    }
    else if (lang === 'ja') {
        return 'JA'
    }
    else if (lang === 'zh') {
        return 'ZH'
    }
}

const onFetchCurrency = (curr) => {

    if (curr === 'eur') {
        return '€'
    }
    else if (curr === 'usd') {
        return '$'
    }
    else if (curr === 'gbp') {
        return '£'
    }
}

const onFetchTime = (time, lang) => {

    if (!time) {
        return ''
    }

    if (!lang) {
        lang = 'en'
    }

    return ''
}


function convertQueryStringToObject(queryString, url) {
    const urlParams = new URLSearchParams(queryString);
    const queryParams = {};

    for (const [key, value] of urlParams.entries()) {
        queryParams[key] = value;
    }

    // Extract "milan" and "IT" from the URL
    queryParams.city = url.pathname.split("/")[3];
    queryParams.country = url.pathname.split("/")[4];

    return queryParams;
}

function convertURLToObject(urlString) {
    if (!urlString) {
        return {}
    }

    if (!urlString.includes('http')) {
        urlString = 'https://www.babonbo.com' + urlString;
    }

    const url = new URL(urlString);
    const queryString = url.search.slice(1); // Remove the leading "?" character

    return convertQueryStringToObject(queryString, url);
}



function getTimezoneFromCoordinates(latitude, longitude) {
    // Use tz-lookup to get the timezone based on coordinates
    const timezone = tzlookup(latitude, longitude);

    return timezone
}

// Function to calculate last-minute fee
const onBrainLastMinute = (checkin, checkout, latitude, longitude) => {

    const storeTimezone = getTimezoneFromCoordinates(latitude, longitude);

    // Define the threshold for last-minute booking (in hours)
    const lastMinuteThresholdHours = 24 * 60 * 60;

    // Convert the check-in and check-out times to the store's timezone (Europe/Rome)
    const checkinStoreTime = momentTZ.tz(checkin, storeTimezone).startOf('day');
    const checkoutStoreTime = momentTZ.tz(checkout, storeTimezone).startOf('day');

    // Convert the current time to the customer's timezone (Europe/Amsterdam)
    const currentTime = momentTZ.tz();

    const isValid = currentTime.isSameOrBefore(checkinStoreTime);

    // Calculate the duration between check-in and check-out in hours
    const hoursRemaining = checkoutStoreTime.diff(currentTime, 'hours');

    // Calculate the remaining minutes to reach checkinStoreTime
    const secondsRemaining = checkinStoreTime.diff(currentTime, 'seconds');

    const secondsRemainingThreshold = secondsRemaining - lastMinuteThresholdHours;

    function formatSeconds(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    }

    // Check if the booking is considered last-minute
    if (secondsRemaining <= lastMinuteThresholdHours) {
        return {
            storeTimezone,
            isValid,
            countdownLastminute: formatSeconds(secondsRemainingThreshold),
            countdownCheckin: formatSeconds(secondsRemaining),
            secondsRemainingLastminute: secondsRemainingThreshold,
            secondsRemainingCheckin: secondsRemaining,
            lastMinuteThresholdHours,
            rentalCheckin: checkinStoreTime,
            rentalCheckout: checkoutStoreTime,
            currentTime,
            message: 'Last-minute fee is applicable.',
            isLastminute: true,
        };
    } else {
        return {
            storeTimezone,
            isValid,
            countdownLastminute: formatSeconds(secondsRemainingThreshold),
            countdownCheckin: formatSeconds(secondsRemaining),
            secondsRemainingLastminute: secondsRemainingThreshold,
            secondsRemainingCheckin: secondsRemaining,
            lastMinuteThresholdHours,
            rentalCheckin: checkinStoreTime,
            rentalCheckout: checkoutStoreTime,
            currentTime,
            message: 'Last-minute fee is not applicable.',
            isLastminute: false
        };
    }
}

const onCalculateLastMinute = (ORDER) => {

    const Store = ORDER?.temp || {};
    const Geocode = Store?.geocode;

    if (Geocode) {
        const Latitude = parseFloat(Geocode.latitude);
        const Longitude = parseFloat(Geocode.longitude);
        const Checkin = ORDER?.checkin;
        const Checkout = ORDER?.checkout;

        const LastMinute = onBrainLastMinute(Checkin, Checkout, Latitude, Longitude);

        // swal('Order', JSON.stringify({
        //     Checkin,
        //     Checkout,
        //     LastMinute
        // }, null, 2), 'success');

        return LastMinute.isLastminute;

    }
}

const onMaskPhoneNumber = (inputString) => {
    // Define a regular expression to match phone numbers with various formats
  const phoneRegex = /(\d{3}[-.\s]?\d{3}[-.\s]?\d{4}|\(\d{3}\)[-.\s]?\d{3}[-.\s]?\d{4}|\d{10})/g;

  // Replace phone numbers with hashtags
  const stringWithHashtags = inputString.replace(phoneRegex, function(match) {
    // Check if the matched text is exactly 10 digits (to prevent partial matches)
    if (/^\d{10}$/.test(match)) {
      return '##########';
    }

    // Handle various formats of phone numbers
    const sanitizedNumber = match.replace(/[^\d]/g, ''); // Remove non-digit characters
    return '##########'.slice(0, sanitizedNumber.length); // Use hashtags with the same length as the digits
  });

  return stringWithHashtags;
}

export {
    GetRouteSearchParams,
    onGetCurrencySymbol,
    onBrowswerDeviceData,
    onFormatUrl,
    onFormatImgUrl,
    getDistanceBetweenCoordinates,
    getRadiant,
    onFormatPrices,
    onCalculateDailyPrice,
    numberWithCommas,
    onFetchCurrency,
    onFetchLanguage,
    onFetchTime,
    convertURLToObject,
    convertQueryStringToObject,
    onCalculateLastMinute,
    onMaskPhoneNumber
};

